import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw"
import { useGlobalState } from 'shared/state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  EyeIcon,
  ArrowCircleUpIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  LinkIcon
} from '@heroicons/react/solid'
import AudioPlayer from 'shared/AudioPlayer'
import AudioPlayerPreview from 'shared/AudioPlayerPreview'
import PublicEntryComments from 'pages/Contests/PublicEntryComments'
import RatingForm from 'pages/Entries/RatingForm'

const PublicEntryRow = ({ entry, ratings }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [rating, setRating] = useState(ratings.find(r => r.entryId === entry.id))
  const [score, setScore] = useState(rating?.rating || 0)
  const [showComments, setShowComments] = useState(false)
  const { putpostRequest } = useQuery()


  const handleChange = (e) => {
    const formData = { assigned_user_id: e.target.value }
    putpostRequest(`/api/v1/entries/${entry.id}`, 'PATCH', { entry: formData }, (err, jsonData) => {
      if (err) { // 422 code
        return
      }
    })
  }
  return <>
    <li className="bg-gray-50 dark:bg-gray-700 px-4 py-6 shadow sm:p-6 sm:rounded-lg">
      <div>
        <div className="sm:flex sm:justify-between sm:items-center">
          <div className="flex-shrink-0">
            <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
          </div>
          <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
            <div className='flex flex-col truncate'>
              <span className="flex space-x-2">
                <span aria-expanded="false">
                  <div><span className="font-medium" href={`/users/${entry.userId}`}>{entry.userName}</span></div>
                </span>
                <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
                { entry.weightedRating > 0 && <div className='flex items-center'>
                  <span className='text-xs text-gray-500'> {entry.weightedRating}</span>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                  </svg>
                </div> }
              </span>
              <a className="font-medium text-neonblue text-sm" href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>{entry.name}</a>
              <div className='flex justify-start items-center space-x-3'>
                <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName}</span>
              </div>
            </div>
          </div>
        </div>

          <div className='mt-2 flex justify-center items-center w-full'>
            { entry.kind === 'audio' && <div className='w-full p-1'>
              <AudioPlayerPreview mediaUrl={entry.remoteUrl} />
            </div> }
            { entry.kind === 'pdf' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
              <iframe className='w-full' src={entry.remoteUrl} />
            </div> }
            { entry.kind === 'text' && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><ReactMarkdown children={entry.textBody} rehypePlugins={[rehypeRaw]} /></div> }

            { entry.kind === 'image' && <div className='w-full hover:opacity-80 cursor-pointer' onClick={openModal}>
              <div className='w-full'>
                <img className='w-full' src={entry.remoteUrl} alt={entry.name} />
              </div>
            </div> }
            { entry.kind === 'video' && <div className='flex flex-col w-full justify-center'>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <iframe src={`https://iframe.videodelivery.net/${entry.remoteUrl}?poster=https://videodelivery.net/${entry.remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
              </div>
            </div> }
          </div>

        <div className='flex flex-col sm:flex-row justify-between items-center space-x-3 mt-2 text-gray-800'>
          <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200' onClick={openModal}>
            <EyeIcon className='w-6 h-6' />
            <span>View Full Details</span>
          </div>
          <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200' onClick={() => setShowComments(!showComments)}>
            <ChatAltIcon className='w-6 h-6' />
            <span>{entry.commentsCount || 0} comments</span>
          </div>
          <a className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer text-gray-800 hover:bg-gray-200' target="_blank" href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>
            <LinkIcon className='w-6 h-6' />
            <span>Direct Link</span>
          </a>
        </div>
        <div className='flex flex-col justify-center'>
          <RatingForm entry={entry} initialRating={rating} setParentScore={setScore}/>
          { score > 0 && <div className='flex items-center justify-center'>
            <span className="text-xs">You rated it</span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-4 text-yellow-500" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
            </svg>
            <span className='text-xs text-gray-500'>{score}</span>
          </div>}
        </div>
        { showComments && <div className='mt-2'><PublicEntryComments entryId={entry.id} /></div> }
      </div>
      <Modal full isOpen={isOpen} closeModal={closeModal} >
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> {entry.name} </h3>
        <div className='max-w-5xl mx-auto'>
          <div className="sm:flex sm:justify-between sm:items-center">
            <div className="flex-shrink-0">
              <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
            </div>
            <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
              <div className='flex flex-col truncate'>
                <span className="flex space-x-2">
                  <span aria-expanded="false">
                    <div><span className="font-medium" href={`/users/${entry.userId}`}>{entry.userName}</span></div>
                  </span>
                  <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
                </span>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">{entry.name}</span>
                <div className='flex justify-start items-center space-x-3'>
                  <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName} - <span className='capitalize'>{entry.kind} entry</span></span>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-center mt-1'>
            { entry.kind === 'audio' && <div className='w-full p-1'>
              <AudioPlayer mediaUrl={entry.remoteUrl} />
            </div> }
            { entry.kind === 'pdf' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
              <iframe className='w-full' src={entry.remoteUrl} />
            </div> }
            { entry.kind === 'text' && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><ReactMarkdown children={entry.textBody} rehypePlugins={[rehypeRaw]} /></div> }

            { entry.kind === 'image' && <div className='w-full'>
              <img className='w-full' src={entry.remoteUrl} alt={entry.name} />
            </div> }
            { entry.kind === 'video' && <div className='flex flex-col w-full justify-center'>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <iframe src={`https://iframe.videodelivery.net/${entry.remoteUrl}?poster=https://videodelivery.net/${entry.remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
              </div>
            </div> }
          </div>
          <div className='mt-6 space-y-2'>
            <div className="p-1 sm:p-10">
              <h3 className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-gray-200">Rating</h3>
              <RatingForm entry={entry} initialRating={rating} setParentScore={setScore}/>
            </div>

            <PublicEntryComments entryId={entry.id} />
          </div>
        </div>
      </Modal>

    </li>
  </>
}

export default PublicEntryRow

PublicEntryRow.propTypes = {
  entry: PropTypes.object.isRequired,
  ratings: PropTypes.array.isRequired
}
