import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import { Toast } from 'shared/Toast'
import { randomToastSuccess } from 'shared/Toast'
import { Modal, useModal } from 'shared/Modal'
import Loading from 'shared/Loading'
import ImageUploadForm from 'shared/ImageUploadForm'

import Card from 'shared/Card'

const UserImage = ({ user }) => {
  const [url, setUrl] = useState(user.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/users/${user.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Avatar Image</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          This will be public.
        </p>
      </div>
      <Card title='Avatar Image'>
        <div className="md:col-span-2">
          <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
            { !uploaded && <span>
                <img className='h-40 w-40 rounded-sm' src={url} onClick={openModal} alt={user.displayName} />
              </span>
              }
            { uploaded && <div className='relative h-40 w-40 rounded-sm flex items-center justify-center dark:bg-gray-700'>
              <img className='h-40 w-40 rounded-sm' src={url} onClick={openModal} alt={user.displayName} />
              <div className='absolute h-40 w-40 top-8'>
                <Loading noMessage noLoadingMessage />
              </div>
            </div> }
          </div>
        </div>
      </Card>
    </div>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            objectType='User'
            aspect='square'
            objectId={user.id}
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const RemindMeButton = ({currentUserId, contestId}) => {
  const [saved, setSaved] = useState(false)
  const { getRequest, putpostRequest } = useQuery()

  useEffect(() => {
    getRequest(`/api/v1/contests/${contestId}/follow`, {}, (err, jsonData) => {
      if (err) { return }
      setSaved(jsonData.isFollowing)
    })
  },[currentUserId])

  const toggleSave = () => {

    putpostRequest(`/api/v1/contests/${contestId}/follow`, 'POST', {}, (err, jsonData) => {
      if (err) { return }
      setSaved(jsonData.isFollowing)
    })
  }

  return <>
    { saved && <button onClick={toggleSave} className="w-full flex items-center justify-center px-4 py-3 text-green-500 bg-white dark:bg-gray-900 font-medium rounded-md shadow-sm border border-gray-700 hover:text-white hover:bg-neonpurple">Saved!</button> }
    { !saved && <button onClick={toggleSave} className="w-full flex items-center justify-center px-4 py-3 text-gray-700 bg-white dark:bg-gray-900 font-medium rounded-md shadow-sm border border-gray-700 hover:text-white hover:bg-neonpurple">Remind me</button> }
  </>
}

export default RemindMeButton
