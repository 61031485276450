import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useGlobalState } from 'shared/state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  EyeIcon,
  ArrowCircleUpIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  LinkIcon
} from '@heroicons/react/solid'

const Star = ({full, hovering, onClick}) => {

  if (full) {
    return <li onMouseOver={hovering} onClick={onClick}>
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-10 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
      </svg>
    </li>
  }

  return <li onMouseOver={hovering} onClick={onClick}>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-10 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
}

const RatingForm = ({entry, initialRating, setParentScore}) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [rating, setRating] = useState(initialRating)
  const [error, setError] = useState(null)
  const [showExplainer, setShowExplainer] = useState(false)
  const [score, setScore] = useState(rating?.rating || 0)
  const [isHovering, setIsHovering] = useState(0)
  const { putpostRequest } = useQuery()

  useEffect(() => {
    if (score === rating?.rating || score === 0) { return }

    if (!currentUser?.canRate) {
      window.location.href = '/signup'
      return
    }

    setParentScore(score)

    let url = '/api/v1/ratings/'
    let action = 'POST'

    if (rating?.id) {
      url = `/api/v1/ratings/${rating.id}`
      action = 'PATCH'
    }

    const formData = {
      rating: score,
      entry_id: entry.id
    }

    putpostRequest(url, action, { rating: formData }, (err, jsonData) => {
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setRating({ ...jsonData.rating })
      setToast(randomToastSuccess())
    })
  },[score])

  const hovering = (index) => { setIsHovering(index) }

  return <div className='mt-6'>
    <ul className="flex justify-center cursor-pointer" onMouseOut={() => hovering(0)}>
      <Star full={(isHovering >= 1 || (isHovering === 0 && score >= 1))} hovering={() => hovering(1)} onClick={() => setScore(1)}/>
      <Star full={(isHovering >= 2 || (isHovering === 0 && score >= 2))} hovering={() => hovering(2)} onClick={() => setScore(2)}/>
      <Star full={(isHovering >= 3 || (isHovering === 0 && score >= 3))} hovering={() => hovering(3)} onClick={() => setScore(3)}/>
      <Star full={(isHovering >= 4 || (isHovering === 0 && score >= 4))} hovering={() => hovering(4)} onClick={() => setScore(4)}/>
      <Star full={(isHovering >= 5 || (isHovering === 0 && score >= 5))} hovering={() => hovering(5)} onClick={() => setScore(5)}/>
      {!showExplainer && <QuestionMarkCircleIcon onClick={() => setShowExplainer(true)} className="h-4 w-4 text-gray-400" /> }
    </ul>
    { error && <span className='text-red-500'>{error}</span> }
    {showExplainer && <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-2">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-xs text-yellow-700">Ratings are anonymous and not public, not even the contest owner sees your rating. The contest owner sees a <b>weighted average</b> of all ratings. To see how the contest owner intends to use ratings, please see the General tab. To learn more about ratings, see the <a href='/faqs'>FAQs</a>. </p>
        </div>
      </div>
    </div> }
  </div>
}

export default RatingForm
