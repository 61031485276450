import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import { Modal, useModal } from 'shared/Modal'
import FroalaWrapper from 'shared/FroalaWrapper'
//import { randomToastSuccess } from 'shared/Toast'
import { useGlobalState } from 'shared/state'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'

const TheForm = ({ grade, contestId, setGrade, closeModal, appendGrade }) => {
  //const [, setToast] = useGlobalState('toast')
  //const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus, reset } = useForm({
   defaultValues: {
      name: grade.name
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/grades/'
    let action = 'POST'
    const formData = {
      ...data,
      contest_id: contestId
    }

    if (grade.id) {
      url = `/api/v1/grades/${grade.id}`
      action = 'PATCH'
    }

    putpostRequest(url, action, { grade: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      //setToast(randomToastSuccess())
      if (action === 'POST') {
        appendGrade(jsonData.grade)
        reset({ ...getValues()})
      } else {
        setGrade({ ...jsonData.grade })
      }
      closeModal()
    })
  })

  const validEntry = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const cancel = () => { closeModal() }

  return <>
    <form className='bg-gray-100 dark:bg-gray-700 p-2'>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-3">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name</label>
          <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Best Use of Meme in a Shakespearean Play" />
        </div>
        <div className="col-span-4" />
        <div className="col-span-4 sm:col-start-4 sm:col-span-1 flex justify-end">
          { grade?.id !== 'undefined' && <DefaultButton onClick={cancel} text='Cancel' /> }
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text={<div className='flex flex-col'>
              Save
            </div>} />
          </div>
        </div>
      </div>
    </form>

    { errors.base && <div className='mt-2 text-sm text-red-600'>{errors.base[0]}</div> }
  </>
}

const GradeForm = ({ initialGrade, contestId, appendGrade }) => {
  const { putpostRequest } = useQuery()
  const [grade, setGrade] = useState(initialGrade)
  const [deleted, setDeleted] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()

  const { id, name } = grade

  const softDelete = () => {
    putpostRequest(`/api/v1/grades/${grade.id}/change_status`, 'post', {}, (err, jsonData) => {
      setDeleted(true)
      if (err) { // 422 code
        return
      }
    })
  }

  if (deleted) { return null }

  return <>
    { grade?.id && <>
      <div className='bg-white dark:bg-gray-700 py-2 px-1 rounded-sm flex justify-between items-center'>
        <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
          <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
            <div className="min-w-0 flex-auto">
              <div className='truncate'>
                <span className="dark:text-gray-300 text-gray-500 mr-5">{name}</span>
              </div>
            </div>
          </div>
        </div>
        { !deleteConfirm && <> 
          <div className='flex'>
            <span className="flex-shrink-0 flex items-start space-x-4">
              <button onClick={openModal} type="button" className="text-neonblue hover:text-neonblue-alt">
                Edit
              </button>
            </span>
            <span className="ml-1 sm:ml-4 flex-shrink-0 flex items-start space-x-4">
              <button onClick={() => setDeleteConfirm(true)} type="button" className="text-red-400 hover:text-neonblue-alt">
                Delete
              </button>
            </span>
          </div>
        </> }
        { deleteConfirm && <> 
          <div className='flex flex-col sm:flex-row ml-2 sm:ml-0'>
            Really delete this? This cannot be undone: 
            <span className="flex-shrink-0 flex items-start space-x-4">
              <button onClick={softDelete} type="button" className="text-red-400 hover:text-neonblue-alt">
                Yes
              </button>
              <button onClick={() => setDeleteConfirm(false)} type="button" className="text-neonblue hover:text-neonblue-alt">
                No
              </button>
            </span>
          </div>
        </> }
      </div>
    </> }
    { !isOpen && typeof(grade?.id) === 'undefined' && <DefaultButton onClick={openModal} text='New Grade' /> }
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <TheForm
        grade={grade}
        setGrade={setGrade}
        appendGrade={appendGrade}
        contestId={contestId}
        closeModal={closeModal}
      />
    </Modal>
  </>
}

export default GradeForm

TheForm.propTypes = {
  setGrade: PropTypes.func,
}

GradeForm.propTypes = {
  initialGrade: PropTypes.object.isRequired,
  contestId: PropTypes.number.isRequired
}
