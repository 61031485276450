import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import PdfUploadForm from 'shared/PdfUploadForm'
import { randomToastSuccess } from 'shared/Toast'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'

const EntryPdf = ({ contest, entry }) => {
  const entryId = entry.id
  const [editing, setEditing] = useState(!entry.remoteUrl)
  const [remoteUrl, setRemoteURL] = useState(entry.remoteUrl)
  const { getRequest } = useQuery()

  const updateFilePathInfo = ({ pdfoUrl, pdfFileName }) => {
    setRemoteURL(null)
  }

  const fetchFilePathInfo = () => {
    getRequest(`/api/v1/entries/${entryId}`, {}, (err, jsonData) => {
      if (err) { return }
      setRemoteURL(jsonData.entry.remoteUrl)
    })
  }

  useEffect(() => {
    if (remoteUrl) {
      setEditing(false)
    }
  }, [remoteUrl])

  return <div className='mt-6'>
    <Card title="PDF Entry">
      <div className="flex justify-center">
        { editing && <div className='flex flex-col w-full justify-center mx-auto'>
          <div className='p-1 mx-auto'>
            <PdfUploadForm
              kind='Entry'
              objectId={entryId}
              onProcessingStarted={updateFilePathInfo}
              onSuccess={fetchFilePathInfo}
            />
          </div>
          <div className='w-m p-1 mx-auto'>
            { remoteUrl && <DefaultButton className='mt-5' onClick={() => setEditing(false)} text="Preview" /> }
          </div>
        </div> }
        { !editing && <div className='flex flex-col w-full justify-center'>
          <div className='w-full p-1'>
            <iframe className='w-full h-96' src={remoteUrl} />
          </div>
          <div className='w-m p-1 mx-auto'>
            <DefaultButton className='mt-5' onClick={() => setEditing(true)} text="Upload a different file instead" />
          </div>
        </div> }
      </div>
    </Card>
  </div>
}


export default EntryPdf
