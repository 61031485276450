import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import { Modal, useModal } from 'shared/Modal'
import FroalaWrapper from 'shared/FroalaWrapper'
//import { randomToastSuccess } from 'shared/Toast'
import { useGlobalState } from 'shared/state'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'

const TheForm = ({ cat, contestId, setCat, closeModal, appendCat }) => {
  //const [, setToast] = useGlobalState('toast')
  //const [currentUser] = useGlobalState('currentUser')
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus, reset } = useForm({
   defaultValues: {
      name: cat.name,
      entry_fee: cat.entryFee || 0,
      video_entry: cat.videoEntry || '',
      audio_entry: cat.audioEntry || '',
      pdf_entry: cat.pdfEntry || '',
      image_entry: cat.imageEntry || '',
      text_entry: cat.textEntry || '',
      description: cat.description,
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    register('description')
    register('image_entry')
    register('video_entry')
    register('audio_entry')
    register('text_entry')
    register('pdf_entry')
    return () => {
      unregister('description')
      unregister('image_entry')
      unregister('video_entry')
      unregister('audio_entry')
      unregister('pdf_entry')
      unregister('text_entry')
    }
  }, [register])

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/contest_categories/'
    let action = 'POST'
    const formData = {
      ...data,
      contest_id: contestId
    }

    if (cat.id) {
      url = `/api/v1/contest_categories/${cat.id}`
      action = 'PATCH'
    }

    putpostRequest(url, action, { contest_category: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      //setToast(randomToastSuccess())
      if (action === 'POST') {
        appendCat(jsonData.cat)
        reset({ ...getValues(), description: ' ' }) // resetting this without this hack kills froala somehow
      } else {
        setCat({ ...jsonData.cat })
      }
      closeModal()
    })
  })

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const validEntry = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const cancel = () => { closeModal() }

  const description = getValues().description
  const imageEntry = getValues().image_entry
  const audioEntry = getValues().audio_entry
  const videoEntry = getValues().video_entry
  const pdfEntry = getValues().pdf_entry
  const textEntry = getValues().text_entry

  return <>
    <form className='bg-gray-100 dark:bg-gray-700 p-2'>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-3">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name</label>
          <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Best Use of Meme in a Shakespearean Play" />
        </div>
        <div className="col-span-4 sm:col-span-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex  justify-between mb-2">Valid Entry Types</label>


          <div className='flex flex-col sm:flex-row space-y-5 sm:space-y-0 justify-between items-center'>
          { imageEntry === '' && <span onClick={() => validEntry('image_entry', 'image')} className="rounded-lg inline-flex p-3 bg-gray-50 text-gray-700 cursor-pointer ring-4 ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
            </svg>
            <span className='ml-2'>Image entries</span>
          </span> }
          { imageEntry === 'image' && <span onClick={() => validEntry('image_entry', '')} className="rounded-lg inline-flex p-3 bg-neonpink-alt text-neonpink ring-4 cursor-pointer ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
            </svg>
            <span className='ml-2'>Image entries</span>
          </span> }
          { audioEntry === '' && <span onClick={() => validEntry('audio_entry', 'audio')} className="rounded-lg inline-flex p-3 bg-gray-50 text-gray-700 cursor-pointer ring-4 ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
            </svg>
            <span className='ml-2'>Audio entries</span>
          </span> }
          { audioEntry === 'audio' && <span onClick={() => validEntry('audio_entry', '')} className="rounded-lg inline-flex p-3 bg-neonpurple-alt text-neonpurple ring-4 cursor-pointer ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
            </svg>
            <span className='ml-2'>Audio entries</span>
          </span> }
          { videoEntry === '' && <span onClick={() => validEntry('video_entry', 'video')} className="rounded-lg inline-flex p-3 bg-gray-50 text-gray-700 cursor-pointer ring-4 ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
            </svg>
            <span className='ml-2'>Video entries</span>
          </span> }
          { videoEntry === 'video' && <span onClick={() => validEntry('video_entry', '')} className="rounded-lg inline-flex p-3 bg-sky-200 text-neonblue ring-4 cursor-pointer ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
            </svg>
            <span className='ml-2'>Video entries</span>
          </span> }
          { textEntry === '' && <span onClick={() => validEntry('text_entry', 'text')} className="rounded-lg inline-flex p-3 bg-gray-50 text-gray-700 cursor-pointer ring-4 ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
            <span className='ml-2'>Text entries</span>
          </span> }
          { textEntry === 'text' && <span onClick={() => validEntry('text_entry', '')} className="rounded-lg inline-flex p-3 bg-indigo-200 text-indigo-600 ring-4 cursor-pointer ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
            <span className='ml-2'>Text entries</span>
          </span> }
          { pdfEntry === '' && <span onClick={() => validEntry('pdf_entry', 'pdf')} className="rounded-lg inline-flex p-3 bg-gray-50 text-gray-700 cursor-pointer ring-4 ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <span className='ml-2'>PDF entries</span>
          </span> }
          { pdfEntry === 'pdf' && <span onClick={() => validEntry('pdf_entry', '')} className="rounded-lg inline-flex p-3 bg-teal-200 text-teal-500 ring-4 cursor-pointer ring-white">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <span className='ml-2'>PDF entries</span>
          </span> }
          </div>


        </div>
        <div className="col-span-4 sm:col-span-3">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Entry Fee in USD</label>
          <input type="number" className={errors.entry_fee ? 'errors' : ''} {...register('entry_fee', { required: true }) } placeholder="0" />
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Leave $0 for free entry. Minimum fee is $5 because of credit card processing fees</p>
          { errors.entry_fee && <div className='mt-2 text-sm text-red-600'>{errors.entry_fee[0] || 'This field is required'}</div> }
        </div>
        <div className="col-span-4"> </div>

        <div className="col-span-4 sm:col-span-4">
          <div className='mt-4'>
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Description / Category Rules
            </label>
            <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> What are the rules for entry? </p>
            <div className="mt-1 shadow-sm w-full min-w-full">
              <FroalaWrapper
                model={description}
                keyPress={keyPress}
                updateModel={(val) => handleUpdateFroala('description', val)}
              />
            </div>
          </div>
        </div>

        <div className="col-span-4" />
        <div className="col-span-4 sm:col-start-4 sm:col-span-1 flex justify-end">
          { cat?.id !== 'undefined' && <DefaultButton onClick={cancel} text='Cancel' /> }
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text={<div className='flex flex-col'>
              Save
            </div>} />
          </div>
        </div>
      </div>
    </form>

    { errors.base && <div className='mt-2 text-sm text-red-600'>{errors.base[0]}</div> }
  </>
}

const ContestCategoryForm = ({ initialCat, contestId, appendCat }) => {
  const { putpostRequest } = useQuery()
  const [editing, setEditing] = useState(initialCat?.id !== 'undefined')
  const [cat, setCat] = useState(initialCat)
  const [deleted, setDeleted] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()

  const { id, kind, name, entryFee } = cat

  const softDelete = () => {
    putpostRequest(`/api/v1/contest_categories/${cat.id}/change_status`, 'post', {}, (err, jsonData) => {
      setDeleted(true)
      if (err) { // 422 code
        return
      }
    })
  }

  if (deleted) { return null }

  return <>
    { cat?.id && <>
      <div className='bg-white dark:bg-gray-700 py-2 px-1 rounded-sm flex justify-between items-center'>
        <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
          <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
            <div className="min-w-0 flex-auto">
              <div className='truncate'>
                <span className="dark:text-gray-300 text-gray-500 mr-5">{name}</span>
                <div className="text-xs text-gray-500 dark:text-gray-300 flex flex-col">
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Entry types: {kind} </span>
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-200">Entry fee: ${entryFee} USD </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        { !deleteConfirm && <> 
          <div className='flex'>
            <span className="flex-shrink-0 flex items-start space-x-4">
              <button onClick={openModal} type="button" className="text-neonblue hover:text-neonblue-alt">
                Edit
              </button>
            </span>
            <span className="ml-1 sm:ml-4 flex-shrink-0 flex items-start space-x-4">
              <button onClick={() => setDeleteConfirm(true)} type="button" className="text-red-400 hover:text-neonblue-alt">
                Delete
              </button>
            </span>
          </div>
        </> }
        { deleteConfirm && <> 
          <div className='flex flex-col sm:flex-row ml-2 sm:ml-0'>
            Really delete this? This cannot be undone: 
            <span className="flex-shrink-0 flex items-start space-x-4">
              <button onClick={softDelete} type="button" className="text-red-400 hover:text-neonblue-alt">
                Yes
              </button>
              <button onClick={() => setDeleteConfirm(false)} type="button" className="text-neonblue hover:text-neonblue-alt">
                No
              </button>
            </span>
          </div>
        </> }
      </div>
    </> }
    { !isOpen && typeof(cat?.id) === 'undefined' && <DefaultButton onClick={openModal} text='New Category' /> }
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <TheForm
        cat={cat}
        setCat={setCat}
        appendCat={appendCat}
        contestId={contestId}
        closeModal={closeModal}
      />
    </Modal>
  </>
}

export default ContestCategoryForm

TheForm.propTypes = {
  setCredit: PropTypes.func,
}

ContestCategoryForm.propTypes = {
  initialCat: PropTypes.object.isRequired,
  contestId: PropTypes.number.isRequired
}
