import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useGlobalState } from 'shared/state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import PublicEntryComments from 'pages/Contests/PublicEntryComments'
import LabelAutocomplete from 'shared/LabelAutocomplete'
import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  EyeIcon,
  ArrowCircleUpIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
  LinkIcon
} from '@heroicons/react/solid'
import AudioPlayer from 'shared/AudioPlayer'

const statusPillStyle = {
  unreviewed: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-800 capitalize',
  dismissed: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize',
  chosen: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize',
  undecided: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 capitalize'
}

const StatusPill = ({ status }) => {
  return <span className={statusPillStyle[status]}>{status} </span>
}

const ChosenEntryRow = ({ initialEntry }) => {
  const [entry, setEntry] = useState(initialEntry)
  const [, setToast] = useGlobalState('toast')
  const [labels, setLabels] = useState(initialEntry.labels)
  const [sent, setSent] = useState(false)
  const { putpostRequest } = useQuery()

  const resendRoundInvite = () => {
    putpostRequest(`/api/v1/entries/${entry.id}/resend_round_invite`, 'POST', {}, (err, jsonData) => {
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setSent(true)
      setToast(randomToastSuccess())
    })
  }

  return <>
    <li className="bg-gray-100 dark:bg-gray-700 px-4 py-6 shadow sm:p-6 sm:rounded-lg">
      <div>
        <div className="sm:flex sm:justify-between sm:items-center">
          <div className="flex-shrink-0">
            <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
          </div>
          <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
            <div className='flex flex-col truncate'>
              <span className="flex space-x-2">
                <time className='uppercase text-xs dark:text-gray-300 text-gray-500 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
                <StatusPill status={entry.reviewStatus} />

                { entry.weightedRating > 0 && <div className='flex items-center'>
                  <span className='text-xs text-gray-500'> {entry.weightedRating}</span>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                  </svg>
                </div> }
              </span>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-200">{entry.name}</span>
              <div className='flex justify-start items-center space-x-3'>
                <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName} - <span className='capitalize'>{entry.kind} entry</span></span>
              </div>
              <div className="min-w-0 mt-1 flex-1 flex sm:items-center  flex-wrap">
                {labels.map((label, idx) => (
                  <span key={`laform${label.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-neonblue dark:bg-neonblue text-xs font-medium rounded-full flex capitalize">{label.name}</span>
                ))}
              </div>
            </div>
          </div>
          <div className='w-30'>
            { sent && <div className='text-green-800'>Successfully sent</div> }
            { !sent && <div className='cursor-pointer text-neonblue' onClick={resendRoundInvite}>Resend invite to next round</div> }
          </div>
        </div>
      </div>
    </li>
  </>
}

export default ChosenEntryRow

ChosenEntryRow.propTypes = {
  initialEntry: PropTypes.object.isRequired
}
