import React from 'react'
import ReactDOM from 'react-dom'

import DashboardPublicEntries from './pages/Dashboard/PublicEntries'
import ContestEditForm from './pages/Contests/EditForm'
import ContestUpdateForm from './pages/Contests/ContestUpdateForm'
import ContestComments from './pages/Contests/Comments'
import ContestEntries from './pages/Contests/Entries'
import MyEntries from './pages/Entries/MyEntries'
import ContestUnsentRounds from './pages/Contests/UnsentRounds'
import PublicContestEntries from './pages/Contests/PublicEntries'
import PublicEntry from './pages/Contests/PublicEntry'
import PublicComments from './pages/Contests/PublicComments'
import JudgeSettings from './pages/JudgeSettings'
import ContestRoundFormIndex from './pages/Contests/ContestRoundFormIndex'
import ContestJudgeText from './pages/Contests/JudgeText'
import ContestRewardsText from './pages/Contests/RewardsText'
import ContestSponsorText from './pages/Contests/SponsorText'
import EntryUploadForm from './pages/Entries/EntryUpload'
import EntryFeeCheckout from './pages/Entries/EntryFeeCheckout'
import RemindMeButton from './pages/Contests/RemindMeButton'
import Settings from './pages/Settings'
import SponsorSettings from './pages/Sponsor'
import SubscriptionIndex from './pages/Subscriptions/Index'
import UsersPublicEntries from './pages/Users/PublicEntries'

const attachReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

const removeReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

document.addEventListener('turbo:load', () => {
  attachReact('dashboard-public-entries', function (node) {
    ReactDOM.render(
      <DashboardPublicEntries {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-edit', function (node) {
    ReactDOM.render(
      <ContestEditForm {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-comments', function (node) {
    ReactDOM.render(
      <ContestComments {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-entries', function (node) {
    ReactDOM.render(
      <ContestEntries {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('my-entries', function (node) {
    ReactDOM.render(
      <MyEntries {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('public-contest-entry', function (node) {
    ReactDOM.render(
      <PublicEntry {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-rounds', function (node) {
    ReactDOM.render(
      <ContestRoundFormIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-judge-text', function (node) {
    ReactDOM.render(
      <ContestJudgeText {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-rewards-text', function (node) {
    ReactDOM.render(
      <ContestRewardsText {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-sponsor-text', function (node) {
    ReactDOM.render(
      <ContestSponsorText {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-unsent-rounds', function (node) {
    ReactDOM.render(
      <ContestUnsentRounds {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('contest-update-form', function (node) {
    ReactDOM.render(
      <ContestUpdateForm {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('entry-upload', function (node) {
    ReactDOM.render(
      <EntryUploadForm {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('entry-fee', function (node) {
    ReactDOM.render(
      <EntryFeeCheckout {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('judge-settings', function (node) {
    ReactDOM.render(
      <JudgeSettings {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('public-contest-comments', function (node) {
    ReactDOM.render(
      <PublicComments {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('public-contest-entries', function (node) {
    ReactDOM.render(
      <PublicContestEntries {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('remind-me-button', function (node) {
    ReactDOM.render(
      <RemindMeButton {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('sponsor-settings', function (node) {
    ReactDOM.render(
      <SponsorSettings {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('subscriptions', function (node) {
    ReactDOM.render(
      <SubscriptionIndex {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('public-user-entries', function (node) {
    ReactDOM.render(
      <UsersPublicEntries {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
  attachReact('user-settings', function (node) {
    ReactDOM.render(
      <Settings {...JSON.parse(node.getAttribute('data'))} />, node
    )
  })
})

document.addEventListener('turbo:before-cache', () => {
  removeReact('contest-edit', function (node) { ReactDOM.unmountComponentAtNode(node) })
  removeReact('contest-entries', function (node) { ReactDOM.unmountComponentAtNode(node) })
  removeReact('contest-rounds', function (node) { ReactDOM.unmountComponentAtNode(node) })
  removeReact('entry-upload', function (node) { ReactDOM.unmountComponentAtNode(node) })
  removeReact('entry-fee', function (node) { ReactDOM.unmountComponentAtNode(node) })
  removeReact('user-settings', function (node) { ReactDOM.unmountComponentAtNode(node) })
  removeReact('subscriptions', function (node) { ReactDOM.unmountComponentAtNode(node) })
})
