import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import { Toast } from 'shared/Toast'
import { randomToastSuccess } from 'shared/Toast'
import { Modal, useModal } from 'shared/Modal'
import Loading from 'shared/Loading'

import Card from 'shared/Card'
import { PrimaryButton } from 'shared/Buttons'
import FroalaWrapper from 'shared/FroalaWrapper'
//import OmniAuthSettings from 'pages/Settings/OmniAuthSettings'

const SponsorText = ({contest}) => {
  const [, setToast] = useGlobalState('toast')
  const [timezones, setTimezones] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      sponsor_text: contest.sponsorText
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('sponsor_text')
    return () => {
      unregister('sponsor_text')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/contests/${contest.id}`, 'PATCH', { contest: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setToast(randomToastSuccess())
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const sponsorText = getValues().sponsor_text

  return (
    <div className='my-2'>
      <Card footer={
        <div className='flex justify-end space-x-2'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />

        </div>
        }>
        <form>
          <div className="col-span-4 sm:col-span-4">
            <div className='mt-4'>
              <div class='font-medium leading-6 text-neonblue dark:text-rose-500 mt-1'> Sponsor Info </div>
              <div class='mt-1 text-xs text-gray-600 dark:text-gray-300'> Adding sponsors here will <b>not</b> send them an invite to edit their sponsor information. </div>
              <div className="mt-1 shadow-sm w-full min-w-full">
                <FroalaWrapper
                  model={sponsorText}
                  keyPress={keyPress}
                  updateModel={(val) => handleUpdateFroala('sponsor_text', val)}
                />
              </div>
            </div>
          </div>
        </form>
      </Card>
      <Toast />
    </div>
  )
}

export default SponsorText
