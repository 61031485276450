import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import { Toast } from 'shared/Toast'
import Loading from 'shared/Loading'
import { EyeIcon, XIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import EntryRow from 'pages/Contests/EntryRow'
import Tabs from 'pages/Contests/EntryTabs'
import LabelAutocomplete from 'shared/LabelAutocomplete'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No Entries found</span>
      </div>
    </span>
  )
}

const createQueryString = (data) => {
  let queryString = ''
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      queryString += `${key}=${data[key]}&`
    }
  })
  return queryString
}

const EntriesIndex = ({contestId, currentUser}) => {
  const { getRequest } = useQuery()
  const [, setCurrentUser] = useGlobalState('currentUser')
  const [page, setPage] = useState(1)
  const [filterLabels, setFilterLabels] = useState([])
  const [contains, setContains] = useState(null)
  const [order, setOrder] = useState('updated_at')
  const [cat, setCat] = useState('all')
  const [round, setRound] = useState('all')
  const [weightedGradeTotal, setWeightedGradeTotal] = useState(0)
  const [status, setStatus] = useState('all')
  const [assignedUserId, setAssignedUserId] = useState('all')
  const scrollRef = useRef(null)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        entries: [],
        rounds: [],
        cats: [],
        teamUsers: [],
        counts: {},
        meta: {},
        loading: true
      }
    )
  const { entries, counts, cats, rounds, meta, teamUsers, loading } = state

  useEffect(() => {
    setCurrentUser(currentUser)
  }, [])

  useDebouncedEffect(() => {
    setState({loading: true})
    fetchData(true)
  }, 200, [contains, order, assignedUserId, status, cat, round, filterLabels, weightedGradeTotal])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    const data = {
      contains: contains,
      order_by: order,
      contest_category_id: cat,
      filter_labels: filterLabels,
      contest_round_id: round,
      weighted_grade_total: weightedGradeTotal,
      assigned_user_id: assignedUserId,
      review_status: status,
      page: newPage ? 1 : page
    }
    console.log(data)
    getRequest(`/api/v1/contests/${contestId}/entries`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( {
        counts: jsonData.counts,
        entries: jsonData.entries,
        rounds: jsonData.rounds,
        cats: jsonData.cats,
        teamUsers: jsonData.teamUsers,
        meta: jsonData.meta,
        loading: false
      })

      //if (counts.length === 0) { setStatuses(jsonData.statuses) }
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') { setOrder(e.target.value) }
    if (e.target.name === 'contest_category_id') { setCat(e.target.value) }
    if (e.target.name === 'contest_round_id') { setRound(e.target.value) }
    if (e.target.name === 'assigned_user_id') { setAssignedUserId(e.target.value) }
    if (e.target.name === 'weighted_grade_total') { setWeightedGradeTotal(e.target.value) }
    if (e.target.name === 'contains') { setContains(e.target.value) }
  }

  const changeTab = (tab) => { setStatus(tab) }

  const addLabel = (label) => { setFilterLabels([...filterLabels, ...[label]]) }

  const removeFilterLabel = (labelName) => {
    const newLabels = filterLabels.filter(label => label !== labelName)
    setFilterLabels(newLabels)
  }

  const exportData = {
    contains: contains,
    order_by: order,
    contest_category_id: cat,
    filter_labels: filterLabels,
    contest_round_id: round,
    assigned_user_id: assignedUserId,
    review_status: status
  }

  const queryString = createQueryString(exportData)

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Entries</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Filter and search your entries until you find the winner.
        </p>

        <div className='relative' style={{padding:'56.25% 0 0 0'}}><iframe src="https://www.loom.com/embed/2eeb4273ae23412c85654b7b358c272f" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='absolute top-0 left-0 w-full h-full'  allowFullScreen title='Video Entry'></iframe></div>
      </div>
      <Card light title='Entries' header={ <a target="_blank" href={`/contests/${contestId}/export_to_csv?${queryString}`}>Export Current Filters to CSV</a> }>
        <>
          <div className='pb-5' ref={scrollRef}>
            <Tabs counts={counts} status={status} changeTab={changeTab} />
            <div className='flex flex-col sm:flex-row sm:space-x-2 mt-1'>
              <div className='flex flex-col w-full'>
                <label htmlFor="contains" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Filter by Entry Name </label>
                <input name='contains' type="text" onChange={handleChange}
                  className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Search by entry name ex: Goku"
                />
              </div>

              <div className='flex flex-col w-full'>
                <label htmlFor="label_autosuggest" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Filter by label </label>
                <LabelAutocomplete contestId={contestId} addLabel={addLabel} searchOnly={'yes'} placeholder='Filter Labels' />
              </div>

              <div className='flex flex-col w-full'>
                <label htmlFor="order" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Sort by </label>
                <select name='order' onChange={handleChange}
                  className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value="updated_at">Latest Activity</option>
                  <option value="created_at">Created At</option>
                  <option value="weighted_average">Rated Highest</option>
                </select>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor="order" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Grade Average Greater Than </label>
                <select name='weighted_grade_total' onChange={handleChange}
                  className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
            </div>

            <div className='flex flex-col sm:flex-row sm:space-x-2 mt-1'>
              <div className='flex flex-col w-full'>
                <label htmlFor="assigned_user_id" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Filter by Team </label>
                <select name='assigned_user_id' onChange={handleChange}
                  className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value="all">Assigned To Anyone</option>
                  { teamUsers.map((user) => (
                    <option key={`userentr${user.id}`} value={user.id}>{user.name}</option>
                  ))}
                </select>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor="contest_category_id" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Filter by Category </label>
                <select name='contest_category_id' onChange={handleChange}
                  className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value="all">All Categories</option>
                  { cats.map((cat) => (
                    <option key={`catentr${cat.id}`} value={cat.id}>{cat.name} ${cat.entryFee}</option>
                  ))}
                </select>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor="contest_round_id" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Filter by Round </label>
                <select name='contest_round_id' onChange={handleChange}
                  className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  <option value="all">All Rounds</option>
                  { rounds.map((round) => (
                    <option key={`roundentr${round.id}`} value={round.id}>{round.name} ({round.status})</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="min-w-0 mt-3 flex-1 flex sm:items-center  flex-wrap">
              {filterLabels.map((flabel, idx) => (
                <span key={`flaform${flabel}`} onClick={(flable) => removeFilterLabel(flabel)} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-neonblue dark:bg-neonblue text-xs font-medium rounded-full flex cursor-pointer capitalize">{flabel} <XIcon className='w-4 h-4' /></span>
              ))}
            </div>
            { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
          </div>
          { loading && <Loading /> }
          <ul className="space-y-3 mx-auto max-w-2xl">
            {entries.map((entry) => (
              <EntryRow key={`entry${entry.id}`} initialEntry={entry} teamUsers={teamUsers} />
            ))}
          </ul>
          { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
          { entries.length === 0 && !loading && <NoResults /> }
        </>
      </Card>
    </div>
    <Toast />
  </>
}

export default EntriesIndex
