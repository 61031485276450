import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitButton','loadingButton'];

  loading() {
    this.submitButtonTarget.classList.add('hidden')
    this.loadingButtonTarget.classList.remove('hidden')
  }
}
