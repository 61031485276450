import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import { Toast } from 'shared/Toast'
import { randomToastSuccess } from 'shared/Toast'
import { Modal, useModal } from 'shared/Modal'
import Loading from 'shared/Loading'
import ImageUploadForm from 'shared/ImageUploadForm'

import Card from 'shared/Card'
import { PrimaryButton } from 'shared/Buttons'
import MDEditor from '@uiw/react-md-editor'
//import OmniAuthSettings from 'pages/Settings/OmniAuthSettings'

const SettingsAbout = ({sponsor}) => {
  const [, setToast] = useGlobalState('toast')
  const [timezones, setTimezones] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const [about, setAbout] = useState(sponsor.about)
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      about: sponsor.about
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('about')
    return () => {
      unregister('about')
    }
  }, [register])

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/sponsors/${sponsor.id}`, 'PATCH', { sponsor: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setToast(randomToastSuccess())
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  useEffect(() => {
    setValue('about', about, { shouldDirty: true, shouldValidate: true })
  }, [about])

  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">About</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Information about the Sponsor goes here.
            </p>
          </div>
        </div>
        <Card footer={
          <div className='flex justify-end space-x-2'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />

          </div>
          }>
          <form>
            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  About the sponsor
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. You can use *common* **markdown** as well.</p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <MDEditor value={about} preview='edit' onChange={setAbout} />
                </div>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}


const SponsorImage = ({ sponsor }) => {
  const [url, setUrl] = useState(sponsor.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/sponsors/${sponsor.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Sponsor Image</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          This will be public. This is different from your avatar as this represents the Sponsor's company.
        </p>
      </div>
      <Card title='Sponsor Image'>
        <div className="md:col-span-2">
          <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
            { !uploaded && <span>
                <img className='h-40 w-40 rounded-sm' src={url} onClick={openModal} />
              </span>
              }
            { uploaded && <div className='relative h-40 w-40 rounded-sm flex items-center justify-center dark:bg-gray-700'>
              <img className='h-40 w-40 rounded-sm' src={url} onClick={openModal} />
              <div className='absolute h-40 w-40 top-8'>
                <Loading noMessage noLoadingMessage />
              </div>
            </div> }
          </div>
        </div>
      </Card>
    </div>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            objectType='Sponsor'
            objectId={sponsor.id}
            aspect='square'
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const SponsorSettings = ({sponsor}) => {
  return <>
    <SettingsAbout sponsor={sponsor.sponsor} />
    <SponsorImage sponsor={sponsor.sponsor} />
    <Toast />
  </>
}

export default SponsorSettings
