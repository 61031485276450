import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useGlobalState } from 'shared/state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  EyeIcon,
  ArrowCircleUpIcon,
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  LinkIcon
} from '@heroicons/react/solid'

const Star = ({full, hovering, onClick}) => {

  if (full) {
    return <li onMouseOver={hovering} onClick={onClick}>
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-10 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
      </svg>
    </li>
  }

  return <li onMouseOver={hovering} onClick={onClick}>
    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="star" className="w-10 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      <path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"></path>
    </svg>
  </li>
}

const GradeForm = ({entry, initialGrade}) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [grade, setGrade] = useState(initialGrade)
  const [error, setError] = useState(null)
  const [score, setScore] = useState(initialGrade?.score || 0)
  const [isHovering, setIsHovering] = useState(0)
  const { putpostRequest } = useQuery()

  useEffect(() => {
    if (score === initialGrade?.score || score === 0) { return }

    let url = '/api/v1/entry_grades/'
    let action = 'POST'

    if (grade?.entryGradeId) {
      url = `/api/v1/entry_grades/${grade?.entryGradeId}`
      action = 'PATCH'
    }

    const formData = {
      score: score,
      grade_id: initialGrade.id,
      entry_id: entry.id
    }

    putpostRequest(url, action, { entry_grade: formData }, (err, jsonData) => {
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setGrade({ ...jsonData.grade })
      setToast(randomToastSuccess())
    })
  },[score])

  const hovering = (index) => { setIsHovering(index) }

  return <div className='p-6 grid grid-cols-2'>
    <div className='col-span-1'>{initialGrade.name}</div>
    <ul className="col-span-1 flex justify-center cursor-pointer" onMouseOut={() => hovering(0)}>
      <Star full={(isHovering >= 1 || (isHovering === 0 && score >= 1))} hovering={() => hovering(1)} onClick={() => setScore(1)}/>
      <Star full={(isHovering >= 2 || (isHovering === 0 && score >= 2))} hovering={() => hovering(2)} onClick={() => setScore(2)}/>
      <Star full={(isHovering >= 3 || (isHovering === 0 && score >= 3))} hovering={() => hovering(3)} onClick={() => setScore(3)}/>
      <Star full={(isHovering >= 4 || (isHovering === 0 && score >= 4))} hovering={() => hovering(4)} onClick={() => setScore(4)}/>
      <Star full={(isHovering >= 5 || (isHovering === 0 && score >= 5))} hovering={() => hovering(5)} onClick={() => setScore(5)}/>
    </ul>
    { error && <span className='text-red-500'>{error}</span> }
  </div>
}

export default GradeForm
