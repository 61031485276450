import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['popover', 'mobilepopover', 'usermenu', 'usermenuMobile'];
  //static classes = [ 'selected' ];
  //static values = { status: String }

  //connect() { }
  //disconnect() { }
  //initialize() { }

  mobilepopoverToggle(e){
    if (this.mobilepopoverTarget.classList.contains('hidden')) {
      this.mobilepopoverTarget.classList.remove('hidden')
    } else {
      this.mobilepopoverTarget.classList.add('hidden')
    }
  }
  popoverToggle(e){
    if (this.popoverTarget.classList.contains('hidden')) {
      this.popoverTarget.classList.add('opacity-100', 'translate-y-0', 'ease-in', 'duration-200')
      this.popoverTarget.classList.remove('hidden', 'translate-y-1', 'ease-out', 'duration-150')
    } else {
      this.popoverTarget.classList.remove('opacity-100', 'translate-y-0', 'ease-in', 'duration-200')
      this.popoverTarget.classList.add('hidden', 'translate-y-1', 'ease-out', 'duration-150')
    }
  }
  usermenuToggle(e){
    if (this.usermenuTarget.classList.contains('hidden')) {
      this.usermenuTarget.classList.remove('hidden')
    } else {
      this.usermenuTarget.classList.add('hidden')
    }
  }
  usermenuToggleMobile(e){
    if (this.usermenuMobileTarget.classList.contains('hidden')) {
      this.usermenuMobileTarget.classList.remove('hidden')
    } else {
      this.usermenuMobileTarget.classList.add('hidden')
    }
  }
}
