import React from 'react'
import PropTypes from 'prop-types'
import { XIcon } from '@heroicons/react/outline'

const Check = () => {
  return <div className="flex-shrink-0">
    <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
    </svg>
  </div>
}

const NoIcon = () => {
  return <div className="flex-shrink-0"> <XIcon className="h-5 w-5 text-red-400" /> </div>
}

const BaseBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-gray-500 dark:text-gray-400 text-center">Free</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Partially custom profile</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Listed in search, but <i>not on other projects</i></span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot message non-followers</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot see who viewed your profile, or profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm"><b>2</b>-person roster</span>
      </li> }
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot invite non-followers to project</span>
      </li> }
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot have project team members</span>
      </li> }
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view project analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot see who listened</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Does not receive early paid notifications</span>
      </li>
      { false && <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view submission analytics</span>
      </li> }
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">No events included</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot access curated content library</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private community chat</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private job board</span>
      </li>
    </ul>
  </div>
}

const NeonBenefits = ({ comparison }) => {
  return <div className={comparison ? 'bg-gray-100 dark:bg-gray-700 p-2 rounded-md' : 'bg-gray-50 dark:bg-gray-750 p-2 rounded-md' }>
    <h3 className="font-medium tracking-wide uppercase text-cccpurple text-center">Apprentice</h3>
    <ul className="mt-2 space-y-2 text-gray-500 dark:text-gray-400">
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Everyone
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Fully custom profile</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccpurple'>Featured</b> in search, <i>not listed on other projects</i></span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccpurple'>10</b> messages to non-followers per month</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">See who viewed your profile and profile analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Projects
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm"><b className='text-cccpurple'>10</b>-person roster</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Invite <b className='text-cccpurple'>5</b> non-followers to a project</span>
      </li> }
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Co-manage a project with team members</span>
      </li> }
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Can view project analytics</span>
      </li>
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Submission
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View who listened to submissions</span>
      </li>
      <li className="flex space-x-3">
        <Check />
        <span className="text-sm">Receive early paid work notifications</span>
      </li>
      { false && <li className="flex space-x-3">
        <Check />
        <span className="text-sm">View submission analytics</span>
      </li> }
      <li className="tracking-wide text-xs uppercase">
        <div className="flex items-center py-3">
          <span className="flex-shrink-0 mr-3 text-cccpurple">
            Education
          </span>
          <div className="flex-1 border-t-2 border-gray-400 dark:border-gray-500"></div>
        </div>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">No events included</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot access curated content library</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private community chat</span>
      </li>
      <li className="flex space-x-3">
        <NoIcon />
        <span className="text-sm">Cannot view private job board</span>
      </li>
    </ul>
  </div>
}

const PlanTitle = ({ planId }) => {
  if (planId === 'neon') {
    return <span className='text-cccorange'>Neon</span>
  } else {
    return <span className='text-gray-500 dark:text-gray-300'>the free plan</span>
  }
}

const WhatsIncluded = ({ planId, comparisonPlanId }) => {
  return <>
    <div className="bg-white dark:bg-gray-800 rounded-md  border-cccorange dark:border-cccpurple-alt">
      <div className="flex items-center px-6 py-3">
        <h4 className="flex-shrink-0 mr-3 bg-white dark:bg-gray-800 text-sm tracking-wider font-semibold uppercase text-gray-800 dark:text-gray-200">
          Compare <PlanTitle planId={comparisonPlanId} /> with <PlanTitle planId={planId} />
        </h4>
        <div className="flex-1 border-t-2 border-gray-200"></div>
      </div>
      <section className="px-6 pb-6">
        <div className="space-y-2 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6">
          { comparisonPlanId === 'free' && <BaseBenefits comparison={true} /> }
          { comparisonPlanId === 'neon' && <NeonBenefits comparison={true} /> }
          { planId === 'free' && <BaseBenefits /> }
          { planId === 'neon' && <NeonBenefits /> }
        </div>
      </section>
      <a target="_blank" href="/pricing" className='text-clcpink px-6 pb-6 cursor-pointer flex justify-start'>
        <div>See the full plan details, including what's coming soon, here</div>
        <svg className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </a>
    </div>
  </>
}

export default WhatsIncluded

WhatsIncluded.propTypes = {
  planId: PropTypes.string.isRequired,
  comparisonPlanId: PropTypes.string.isRequired
}
NeonBenefits.propTypes = {
  comparison: PropTypes.bool
}
BaseBenefits.propTypes = {
  comparison: PropTypes.bool
}
