import { Controller } from "@hotwired/stimulus"

let contestUpdateId = null

function toggleModalOpen(target) {
  if (target.classList.contains('hidden')) {
    target.classList.remove('hidden')
  } else {
    target.classList.add('hidden')
  }
}

export default class extends Controller {
  static targets = ['confirmDeleteModal', 'confirmSendNowModal'];

  confirmSendNow(e){
    contestUpdateId = e.params.id
    toggleModalOpen(this.confirmSendNowModalTarget)
  }

  confirmDelete(e){
    contestUpdateId = e.params.id
    toggleModalOpen(this.confirmDeleteModalTarget)
  }

  resetSendNow() {
    contestUpdateId = null
    toggleModalOpen(this.confirmSendNowModalTarget)
  }

  resetDelete() {
    contestUpdateId = null
    toggleModalOpen(this.confirmDeleteModalTarget)
  }

  actuallySendNow() {
    window.location.href = `/contest_updates/${contestUpdateId}/send_now`
  }

  sendSample(e) {
    contestUpdateId = e.params.id
    window.location.href = `/contest_updates/${contestUpdateId}/send_sample`
  }

  actuallyDelete() {
    window.location.href = `/contest_updates/${contestUpdateId}/delete`
  }
}
