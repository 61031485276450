import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useGlobalState } from 'shared/state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw"
import MDEditor from '@uiw/react-md-editor'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import PublicEntryComments from 'pages/Contests/PublicEntryComments'
import LabelAutocomplete from 'shared/LabelAutocomplete'
import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  PencilAltIcon,
  EyeIcon,
  ExclamationIcon,
  ArrowCircleUpIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
  LinkIcon
} from '@heroicons/react/solid'
import AudioPlayer from 'shared/AudioPlayer'
import AudioPlayerPreview from 'shared/AudioPlayerPreview'

const EntryForm = ({initialEntry, setInitialEntry, closeModal}) => {
  const [, setToast] = useGlobalState('toast')
  const [entry, setEntry] = useState(initialEntry)
  const [note, setNote] = useState(entry.note)
  const { putpostRequest } = useQuery()

  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus, reset } = useForm({
   defaultValues: {
      name: entry.name,
      note: entry.note,
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    setValue('note', note, { shouldDirty: true, shouldValidate: true })
  }, [note])


  useEffect(() => {
    register('note')
    return () => {
      unregister('note')
    }
  }, [register])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/entries/'
    let action = 'POST'

    if (entry.id) {
      url = `/api/v1/entries/${entry.id}`
      action = 'PATCH'
    }

    const formData = { ...data, }

    putpostRequest(url, action, { entry: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setInitialEntry({ ...jsonData.entry })
      closeModal()
      setToast(randomToastSuccess())
    })
  })

  return <div className='mt-6'>
    <form>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Your Entry </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Buford's Entry" />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-4 sm:col-span-4">
          <div className='mt-4'>
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Note for the entry review team
            </label>
            <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> This is a private note. Only you and the entry team can see it. You can use *common* **markdown** as well.</p>
            <div className="mt-1 shadow-sm w-full min-w-full">
              <MDEditor value={note} preview='edit' onChange={setNote} height={100} hideToolbar={true} />
            </div>
          </div>
        </div>
        <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />


      </div>
    </form>
  </div>
}

const MyEntryRow = ({ initialEntry, teamUsers }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [entry, setEntry] = useState(initialEntry)
  const [showComments, setShowComments] = useState(false)
  const { putpostRequest } = useQuery()

  const handleChange = (e) => {
    const formData = { assigned_user_id: e.target.value }
    putpostRequest(`/api/v1/entries/${entry.id}`, 'PATCH', { entry: formData }, (err, jsonData) => {
      if (err) { // 422 code
        return
      }
    })
  }

  const deleteEntry = (e) => {
    putpostRequest(`/api/v1/entries/${entry.id}/delete`, 'POST', { }, (err, jsonData) => {
      if (err) { /* hooks */ return }

      closeModal()
      setDeleted(true)
    })
  }

  if (deleted) { return null }

  return <>
    <li className="bg-gray-100 dark:bg-gray-700 px-4 py-6 shadow sm:p-6 sm:rounded-lg">
      <div>
        <div className="sm:flex sm:justify-between sm:items-center">
          <div className="flex-shrink-0">
            <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
          </div>
          <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
            <div className='flex flex-col truncate'>
              <span className="flex space-x-2">
                <time className='uppercase text-xs dark:text-gray-300 text-gray-500 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
              </span>
              <span className="text-sm font-medium text-gray-900 dark:text-gray-200 flex space-x-1">
                <a href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>{entry.name}</a>
                <span>for</span> <a href={`/contests/${entry.contestSlug}`}>{entry.contestName}</a>
              </span>
              <div className='flex justify-start items-center space-x-3'>
                <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName} - <span className='capitalize'>{entry.kind} entry</span></span>
              </div>
            </div>
          </div>
        </div>
        { entry.note && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><MDEditor.Markdown source={entry.note} /></div> }

        { entry.status === 'completed' && <>
          <div className='mt-2 flex justify-center items-center w-full'>
            { entry.kind === 'audio' && <div className='w-full p-1'>
              <AudioPlayerPreview mediaUrl={entry.remoteUrl} />
            </div> }

            { entry.kind === 'pdf' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
            <iframe className='w-full' src={entry.remoteUrl} />
          </div> }

            { entry.kind === 'image' && <div className='w-full hover:opacity-80 cursor-pointer' onClick={openModal}>
              <div className='w-full'>
                <img className='w-full' src={entry.remoteUrl} alt={entry.name} />
              </div>
            </div> }
            { entry.kind === 'text' && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><ReactMarkdown children={entry.textBody} rehypePlugins={[rehypeRaw]} /></div> }
            { entry.kind === 'video' && <div className='flex flex-col w-full justify-center'>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <iframe src={`https://iframe.videodelivery.net/${entry.remoteUrl}?poster=https://videodelivery.net/${entry.remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
              </div>
            </div> }
          </div>
        </> }
      </div>

      <div className='flex justify-between items-center space-x-3 my-2 text-gray-800'>
        { entry.status === 'completed' && <>
          <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200' onClick={openModal}>
            <PencilAltIcon className='w-6 h-6' />
            <span>Edit Entry</span>
          </div>
          <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200' onClick={() => setShowComments(!showComments)}>
            <ChatAltIcon className='w-6 h-6' />
            <span>{entry.commentsCount || 0} comments</span>
          </div>
          <a className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer text-gray-800 hover:bg-gray-200' href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>
            <LinkIcon className='w-6 h-6' />
            <span>Public Link</span>
          </a>
        </> }
        { entry.status !== 'completed' && <a href={`/entries/${entry.id}/edit`}><b>Complete Entry</b></a> }
      </div>
      { showComments && <div className='mt-2'><PublicEntryComments entryId={entry.id} /></div> }
      <Modal full isOpen={isOpen} closeModal={closeModal} >
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> {entry.name} </h3>
        <div className='max-w-5xl mx-auto'>
          <div className="sm:flex sm:justify-between sm:items-center">
            <div className="flex-shrink-0">
              <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
            </div>
            <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
              <div className='flex flex-col truncate'>
                <span className="flex space-x-2">
                  <span aria-expanded="false">
                    <div><span className="font-medium" href={`/users/${entry.userId}`}>{entry.userName}</span></div>
                  </span>
                  <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
                </span>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">{entry.name}</span>
                <div className='flex justify-start items-center space-x-3'>
                  <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName} - <span className='capitalize'>{entry.kind} entry</span></span>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-center'>
            { entry.kind === 'audio' && <div className='w-full p-1'>
              <AudioPlayer mediaUrl={entry.remoteUrl} />
            </div> }

            { entry.kind === 'pdf' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
            <iframe className='w-full' src={entry.remoteUrl} />
          </div> }

            { entry.kind === 'image' && <div className='w-full'>
              <img className='w-full' src={entry.remoteUrl} alt={entry.name} />
            </div> }
            { entry.kind === 'text' && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><ReactMarkdown children={entry.textBody} rehypePlugins={[rehypeRaw]} /></div> }
            { entry.kind === 'video' && <div className='flex flex-col w-full justify-center'>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <iframe src={`https://iframe.videodelivery.net/${entry.remoteUrl}?poster=https://videodelivery.net/${entry.remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
              </div>
            </div> }
          </div>
          <div className='mt-4' />
          <Card title='Entry Details'>
            <EntryForm initialEntry={entry} setInitialEntry={setEntry} closeModal={closeModal} />
            <div className='mt-10 flex justify-between'>
              { !deleteConfirm && <div onClick={() => setDeleteConfirm(true)} aria-label='Delete Account' className='text-red-500 cursor-pointer'>Delete Entry</div> }
              { deleteConfirm && <>
                <div className="rounded-md bg-red-50 p-4 mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">Take me to the Danger Zone</h3>
                      <div className="mt-2 text-sm text-red-700"> This <b>cannot be undone</b>. You must be absolutely sure before you go off and push this button. I'm serious, no takebacks.</div>
                      <div onClick={deleteEntry} aria-label='Confirm Delete Entry' className='text-red-500 cursor-pointer'>Yes, really delete this entry</div>
                    </div>
                  </div>
                </div>
              </> }
            </div>
          </Card>
          <div className='mt-4' />
          <PublicEntryComments entryId={entry.id} />
        </div>
      </Modal>
    </li>
  </>
}

export default MyEntryRow

MyEntryRow.propTypes = {
  initialEntry: PropTypes.object.isRequired
}
