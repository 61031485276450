import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import ContestCategoryForm from 'pages/Contests/ContestCategoryForm'
import Card from 'shared/Card'
//import { randomToastSuccess } from 'shared/Toast'

//import { useGlobalState } from 'state'
import useQuery from 'hooks/useQuery'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const ContestCategoryFormIndex = ({ contestId, contestCats, contest }) => {
  const [cats, setCats] = useState(contestCats)

  const appendCat = (cat) => {
    let data = cats.slice();
    data.push(cat);
    setCats(data)
  }

  if (!contestId) return null
  

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Contest Categories</h3>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">You can segment contest entries with categories. For example:</p>
        <ul>
          <li>Best Comedy Perfomance</li>
          <li>Best Drama Performance</li>
        </ul>
      </div>
      <Card title="Categories" footer={
          <ContestCategoryForm
            initialCat={{}}
            contestId={contestId}
            appendCat={appendCat}
            contest={contest}
          />
        } >
        <div className="md:col-span-2">
          {cats.map((cat, idx) => (
            <ContestCategoryForm key={`contcat${cat.id}`} initialCat={cat} contestId={contestId} contest={contest} />
          ))}
          
        </div>
      </Card>
    </div>
  </>
}

export default ContestCategoryFormIndex

ContestCategoryFormIndex.propTypes = {
  contestId: PropTypes.number.isRequired,
  contestCats: PropTypes.array.isRequired
}
