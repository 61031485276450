import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import Loading from 'shared/Loading'
import { EyeIcon, ExclamationIcon } from '@heroicons/react/solid'
import useQuery from 'hooks/useQuery'
import CommentRow from 'pages/Contests/PublicEntryComment'
import CommentForm from 'pages/Contests/PublicEntryCommentForm'

const PublicEntryComments = ({entryId}) => {
  const { getRequest } = useQuery()
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getRequest(`/api/v1/entries/${entryId}/comments`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ }
      setComments(jsonData.comments)
    })
  },[])

  const addChildtoParent = (childComment) => {
    setComments([childComment, ...comments])
  }

  return <div className="border-t-4 border-neonpurple p-1 sm:p-10">
    <h3 className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-gray-200">Comments</h3>
    <CommentForm commentableId={entryId} commentableType='Entry' addChildtoParent={addChildtoParent} />
    <div className="space-y-3 mt-3">
      {comments.map((com, idx) => (
        <CommentRow key={`com${com.id}`} initialComment={com} />
      ))}
    </div>
    { loading && <Loading /> }
  </div>
}

export default PublicEntryComments
