import React, { useState, useEffect, useRef, useReducer } from 'react'
import { formattedTime } from 'utils/date'
import WaveSurfer from 'wavesurfer.js'

import { VolumeUpIcon, ChevronDownIcon, ChevronUpIcon, ArrowsExpandIcon } from '@heroicons/react/outline'
import { PlayIcon, PauseIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'

// http://wavesurfer-js.org/projects/
// Truly awesome stuff that could be done with audio editing right from the browser.
// Possible to record, cut, mix, annotate, straight from browser
// Possible to play file immediately, before even uplaoding it too, Look in imageuploadform URL.createObjectURL
//
// Possible to preload next song in the queue: https://wavesurfer-js.org/docs/methods.html

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: '#eee',
  progressColor: '#f7784e',
  cursorColor: '#f7784e',
  //xhr: { mode: 'no-cors', method: 'GET'},
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 60,
  normalize: true, // If true, normalize by the maximum peak instead of 1.0.
  partialRender: true // Use the PeakCache to improve rendering speed of large waveforms.
})

const nullState = {
  objectName: null,
  userDisplayName: null,
  userUrl: null,
  imageUrl: null,
  objectUrl: null
}

const AudioPlayer = ({mediaUrl, autoPlay}) => {
  const { putpostRequest } = useQuery()
  const [playing, setPlaying] = useState(false)

  const waveformRef = useRef(null)
  const wavesurfer = useRef(null)
  const [duration, setDuration] = useState(0.0)
  const [currentPosition, setCurrentPosition] = useState(0.0)

  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), nullState
    )
  const { objectName, userUrl, objectUrl, userDisplayName, imageUrl } = state

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {

    const options = formWaveSurferOptions(waveformRef.current)
    wavesurfer.current = WaveSurfer.create(options)

    if (mediaUrl) {
      wavesurfer.current.load(mediaUrl)
    }

    wavesurfer.current.on('ready', function() {
      // https://wavesurfer-js.org/docs/methods.html
      // make sure object stillavailable when file loaded
      if (wavesurfer.current) {
        //wavesurfer.current.play()
        //setPlaying(true)
        wavesurfer.current.setVolume(1)
        setDuration(formattedTime(wavesurfer.current.getDuration()))
        if (autoPlay) { handlePlayPause() }
      }
    })

    wavesurfer.current.on('audioprocess', function() {
      if (wavesurfer.current) {
        const time = wavesurfer.current.getCurrentTime()
        const fTime = formattedTime(time)
        if (fTime !== currentPosition) {
          setCurrentPosition(fTime)
        }
      }
    })

    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy()
  }, [])

  useEffect(() => {
    if (!wavesurfer.current) { return }

    if (playing && wavesurfer.current.isPlaying()) { return }
    if (!playing && !wavesurfer.current.isPlaying()) { return }

    wavesurfer.current.playPause()
  }, [playing])

  const handlePlayPause = () => {
    setPlaying(!playing)
    wavesurfer.current.playPause()
  }

  // always need to have the wavesurfer id in the dom otherwise madness
  return <>
    <div className={`bg-neonblue text-white h-16 w-full rounded`}>
      <div className="w-full mx-auto px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="w-full flex-1 flex items-center justify-center">
            { imageUrl && <img className="hidden md:block h-8 w-8 rounded-full flex-none" src={imageUrl} alt={imageUrl} /> }
            <div className='flex-1 flex items-center justify-center'>
              { mediaUrl && <>
                { !playing && <div onClick={handlePlayPause} className="flex items-center">
                  <PlayIcon className='h-16 w-16 cursor-pointer' />
                </div> }
                { playing && <div onClick={handlePlayPause} className="flex items-center">
                  <PauseIcon className='h-16 w-16 cursor-pointer' />
                </div> }
              </> }
              <div className='flex-grow hidden sm:block ml-3 cursor-pointer'>
                <div id="waveform" className='' ref={waveformRef} />
              </div>
            </div>

            { mediaUrl && <>
              <div className="flex ml-0 sm:ml-6 cursor-pointer gap-x-3">
                <div className='flex flex-col w-20'>
                  <span>{currentPosition}</span>
                  <span>{duration}</span>
                </div>
              </div>
            </> }
          </div>
        </div>
      </div>
    </div>
  </>
}

export default AudioPlayer
