import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { RadioGroup } from '@headlessui/react'
import { CreditCardIcon, ExclamationIcon, EmojiHappyIcon } from '@heroicons/react/solid'
import WhatsIncluded from 'pages/Subscriptions/WhatsIncluded'
import Downgrade from 'pages/Subscriptions/Downgrade'
import { Toast } from 'shared/Toast'

import {PrimaryButton, DefaultButton} from 'shared/Buttons'
import useQuery from 'hooks/useQuery'
import classNames from 'utils/classNamesLocal'
import { useGlobalState } from 'shared/state'

import Loading from 'shared/Loading'

const options = {
  hidePostalCode: true,
  style: {
    base: {
      'fontSize': '16px',
      'color': '#424770',
      'letterSpacing': '0.025em',
      'fontFamily': 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

const PlanSelector = (props) => {
  const { existingSubscription } = props
  const [paymentMethods, setPaymentMethods] = useState(props.paymentMethods)
  const [newPaymentMethod, setNewPaymentMethod] = useState(true)
  const [, setToast] = useGlobalState('toast')
  const pollRef = useRef(0)

  const [errorMessage, setErrorMessage] = useState(props.errorMessage)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const [stripeReady, setStripeReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const { putpostRequest, getRequest } = useQuery()

  const stripe = useStripe()
  const elements = useElements()

  const assignDefaultPaymentMethod = () => {
    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.defaultPaymentMethod)
    if (defaultPaymentMethod) { setSelectedPaymentMethodId(defaultPaymentMethod.id) }
  }

  const selectedPaymentMethodDetails = () => {
    const paymentMethodDetails = paymentMethods.find(paymentMethod => paymentMethod.id === selectedPaymentMethodId)
    return paymentMethodDetails
  }

  useEffect(() => {
    if (paymentMethods.length > 0) { setNewPaymentMethod(false) }
    assignDefaultPaymentMethod()
  }, [paymentMethods])


  useEffect(() => {
    if (newPaymentMethod === true) {
      setSelectedPaymentMethodId(null)
    } else {
      assignDefaultPaymentMethod()
    }
  }, [newPaymentMethod])

  const onNewPaymentMethodChange = (e) => {
    if (e.error) {
      setErrorMessage('This appears to be an invalid card number.')
    } else {
      setErrorMessage(null)
      if (e.complete) {
        setStripeReady(true)
      } else {
        setStripeReady(false)
      }
    }
  }

  useEffect(() => {
    setStripeReady(Boolean(selectedPaymentMethodId))
  }, [selectedPaymentMethodId])

  const createStripePaymentMethod = async () => {
    setLoading(true)
    const stripePayload = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
    setLoading(false)

    if (stripePayload.error || !stripePayload.paymentMethod || !stripePayload.paymentMethod.id) {
      // TODO: spam slack channel with stripePayload.error.message, current_user.id
      setErrorMessage(stripePayload.error.message)
      return
    }
    const newCard = {
      ...stripePayload.paymentMethod.card,
      id: stripePayload.paymentMethod.id,
      ccExpYear: stripePayload.paymentMethod.card.exp_year,
      ccExpMonth: stripePayload.paymentMethod.card.exp_month
    }
    setPaymentMethods([...paymentMethods, newCard])
    return stripePayload.paymentMethod.id
  }

  const purchase = async () => {
    setLoading(true)
    setErrorMessage(null)
    const paymentMethodId = selectedPaymentMethodId || await createStripePaymentMethod()
    setLoading(false)
    if (paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethodId)
      if (existingSubscription) {
        setPreviewing(true)
      } else {
        createSubscription(paymentMethodId)
      }
    }
    /* The createStripePaymentMethod method will handle error messaging if it fails to create a payment method */
  }

  const pollForPlanChange = () => {
    setLoading(true)
    pollRef.current += 1
    getRequest(`/api/v1/users/${currentUser.id}/plan`, {}, (err, jsonData) => {
      if (err) { /* hook */ return }
      if (pollRef.current > 20) {
        setErrorMessage('Code 523: Could not verify card in time')
        return
      }
      if (jsonData.user.plan === 'neon') {
        setLoading(false)
        successfulPurchase()
      } else {
        setTimeout(pollForPlanChange, 2000)
      }
    })
  }

  const handlePaymentThatRequiresCustomerAction = async ({ paymentMethodId, paymentIntentClientSecret }) => {
    if (paymentIntentClientSecret) {
      setLoading(true)
      const confirmCardResult = await stripe.confirmCardPayment(paymentIntentClientSecret, { payment_method: paymentMethodId })
      setLoading(false)
      if (confirmCardResult.error) {
        // The card was declined (i.e. insufficient funds, card has expired, etc). Try another payment method
        console.log(`handlePaymentThatRequiresCustomerAction - throwing ${confirmCardResult.error.message}`)
        setErrorMessage(confirmCardResult.error.message)
      } else {
        if (confirmCardResult.paymentIntent.status === 'succeeded') {
          // handled invoice.payment_succeeded webhook.
          // There's a risk of the customer closing the window before callback
          pollForPlanChange()
          console.log('handlePaymentThatRequiresCustomerAction - confirmCardPayment PI succeeded')
        } else {
          setErrorMessage('Code 943: We were unable to charge your card at this time. Please try another card.')
        }
      }
    }
  }

  const successfulPurchase = () => {
    setToast(<>
      <div className="flex-shrink-0">
        <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-green-800">The love is real.</p>
        <p className="mt-1 text-sm text-green-500">It's great to have you here. We'll send you an email confirmation as well</p>
      </div>
    </>)

    setTimeout(redirectDashboard, 1000)
  }

  const redirectDashboard = () => { window.location.href = '/dashboard?welcome=yes' }

  const createSubscription = async (paymentMethodId) => {
    setLoading(true)
    const data = { payment_method_id: paymentMethodId }

    putpostRequest('/api/v1/stripe/subscriptions', 'POST', { subscriptions: data }, async (err, response) => {
      console.log(response)
      setLoading(false)
      if (err) { setErrorMessage(err) }
      if (response && !response.requiresAction) { successfulPurchase() }
      if (response && response.requiresAction) {
        await handlePaymentThatRequiresCustomerAction({
          paymentIntentClientSecret: response.paymentIntentClientSecret,
          paymentMethodId: selectedPaymentMethodId
        })
      }
    })
  }

  return <>
    <div className="my-12 max-w-xl mx-auto p-6 rounded-md bg-white">
      <div className=''>
        { existingSubscription?.downgradeTo && <Downgrade existingSubscription={existingSubscription} /> }
        <div className="flex items-center p-6">
          <h4 className="flex-shrink-0 mr-3 text-sm tracking-wider font-semibold uppercase text-neonblue">
            Total Today
          </h4>
          <div className="flex-1 border-t-2 border-gray-200"></div>
          <div className='ml-5 text-2xl dark:text-gray-100 font-semibold'> $99 USD </div>
        </div>
        <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
          Credit or Debit Card
        </label>
        <div className='mt-1'>
          { !newPaymentMethod && <>
            { paymentMethods.length > 0 && <>
              <ul role="list" className="flex flex-col">
                { paymentMethods.map((pm, idx) => (
                  <li key={pm.id} onClick={() => setSelectedPaymentMethodId(pm.id)} className="relative cursor-pointer">
                    <dl className="mt-3 p-3 bg-gray-50 hover:bg-gray-100 dark:bg-gray-750 dark:hover:bg-gray-800 text-gray-600 dark:text-gray-300 border-2 rounded-md">
                      <dd className="flex flex-col sm:flex-row sm:items-center text-sm justify-start sm:justify-between font-medium sm:mr-6">
                        <div className='flex justify-start'>
                          <CreditCardIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <span className='whitespace-nowrap text-sm capitalize'>{pm.brand} ending in <strong>{pm.last4}</strong></span>
                          <span className="text-sm font-medium ml-3">{pm.ccExpMonth}/{pm.ccExpYear}</span>
                        </div>
                        { (selectedPaymentMethodId === pm.id) && <span className="inline-flex items-center px-3 rounded-full text-sm font-medium bg-green-100 text-green-800">Selected</span> }
                      </dd>
                    </dl>
                  </li>
                ))}
              </ul>
              <div className='cursor-pointer text-neonpink hover:text-neonpink-light dark:hover:text-neonpink-light text-sm py-3' onClick={() => setNewPaymentMethod(true)}>Add New Payment Method</div>
            </> }
          </> }
          { newPaymentMethod && <>
            <div className='bg-gray-50 p-2 rounded-md'>
              <CardElement id='card-element' options={options} onChange={onNewPaymentMethodChange} />
            </div>
            { paymentMethods.length > 0 && <>
              <div className='cursor-pointer text-neonpink hover:text-neonpink-light text-sm py-3' onClick={() => setNewPaymentMethod(false)}>Use A Saved Card</div>
            </> }
          </>}
          { errorMessage && <div className='mt-2 text-sm text-red-600'>{errorMessage}</div> }
        </div>

        <div className="flex justify-end items-center mt-2">
          <PrimaryButton onClick={purchase} loading={loading} disabled={!stripeReady} text={'Subscribe'} />
        </div>
        <span className='text-xs text-gray-400 dark:text-gray-400'>We use Stripe to securely process transactions</span>

        <div className='text-xs text-gray-400 dark:text-gray-400 pt-5'>
          With your purchase today, your subscription will automatically renew every 12 months. You will be charged $99 USD on each renewal date unless you cancel in your Subscription settings. If you cancel, previous charges will not be refunded, but you may continue to use the service until the end of the prepaid term. By proceeding, you are agreeing to our Terms of Service.
        </div>
      </div>
    </div>
    <Toast />
  </>
}

export default PlanSelector

PlanSelector.propTypes = {
  existingSubscription: PropTypes.object,
  setUpdateExisting: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  legacyNotice: PropTypes.string
}
