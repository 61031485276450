import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-cropper'
import "cropperjs/dist/cropper.css"
import { CheckCircleIcon, CloudUploadIcon } from '@heroicons/react/solid'

import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import Loading from 'shared/Loading'

const ImageUploadForm = (props) => {
  const { objectType, objectId, url, success, setUrl, didUpload, aspect } = props
  const aspectRatio = aspect === 'video' ? 16 / 9 : 1 /1
  const style = aspect === 'video' ? { minHeight: 450, minWidth: '100%' } : { minHeight: 400, minWidth: 400 }
  const coverBox = aspect === 'video' ? 'h-54 w-96 sm:w-960px sm:h-540px sm:w-960' : 'h-96 w-96'
  const [, setToast] = useGlobalState('toast')
  const [s3, setS3] = useState({})
  const [action, setAction] = useState(null)
  const [thisPreview, setThisPreview] = useState(url)
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  const { getRequest, putpostRequest } = useQuery()
  const cropperRef = useRef(null)
  const isComponentMounted = useRef(false)

  const [editing, setEditing] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [file, setFile] = useState(null)
  const [imageUpload, setImageUpload] = useState({})

  useEffect(() => {
    isComponentMounted.current = true
    getRequest('/api/v1/uploads/amazon_hash', {}, (err, jsonData) => {
      if (err) { return }
      setS3(jsonData)
    })
    return () => { isComponentMounted.current = false }
  }, [])

  useEffect(() => {
    if (!file) { return }

    setThisPreview(URL.createObjectURL(file))
  }, [file])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
        <p className="mt-1 text-sm dark:text-red-300 text-red-400">You can only upload jpg, png, or gif files. File can't be over 8MB.</p>
      </div>)
    }

    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*', maxFiles: 1, maxSize: 8388608 })

  const submitForm = (action) => {
    if (!file) { return }
    setUploading(true)
    setAction(action)

    const data = {
      upload: {
        object_type: objectType,
        object_file_type: 'image',
        object_id: objectId,
      }
    }
    putpostRequest('/api/v1/uploads', 'POST', data, (err, jsonData) => {
      if (err) { console.log('toast errors'); return }

      setImageUpload(jsonData.upload)
    })
  }
  useEffect(() => {
    if (!file) { return }
    save()
  }, [imageUpload])

  const onCrop = () => { }

  const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(',')[1])
    var array = []
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' })
  }

  const save = () => {
    var data = new FormData()
    var key = `image_uploads/${imageUpload.id}/${imageUpload.id}${file.name.replace(/[^\w\d_\-.]+/ig, '')}`
    var urlbase = `https://${s3.bucket}.s3.amazonaws.com`

    data.append('key', key)
    data.append('AWSAccessKeyId', s3.access_key)
    data.append('acl', 'public-read')
    data.append('policy', s3.policy)
    data.append('signature', s3.signature)
    data.append('Content-Type', file.type)
    data.append('Content-Encoding', 'base64')

    if (action === 'edited') {
      const blob = dataURItoBlob(cropperRef.current.cropper.getCroppedCanvas().toDataURL())
      data.append('file', new File([blob], `image-${imageUpload.id}/${file.name.replace(/[^\w\d_\-.]+/ig, '')}`))
    } else {
      data.append('file', file)
    }

    window.fetch(urlbase, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-TOKEN': csrfToken
      },
      body: data
    }).then((response) => {
      setUploading(false)
      setUploaded(true)
      didUpload(true)
      setEditing(false)
      setRemoteURL(urlbase + '/' + key)
      setTimeout(poll, 3000)
    })
  }

  const setRemoteURL = (url) => {
    setThisPreview(url)
    setUrl(url)
    putpostRequest(`/api/v1/uploads/${imageUpload.id}`, 'PATCH', { upload: { s3_url: url } }, (err, jsonData) => {
      if (err) { /* handled in hook */ }
    })
  }

  const poll = () => {
    getRequest(`/api/v1/uploads/${imageUpload.id}`, {}, (err, jsonData) => {
      if (err) { return }
      if (jsonData.upload && jsonData.upload.error) {
        setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
          <p className="mt-1 text-sm dark:text-red-300 text-red-400">{jsonData.upload.error}</p>
        </div>)
      } else if (jsonData.upload) {
        success()
        didUpload(false)
        if (isComponentMounted.current) {
          setUploaded(false)
          setFile(null)
        }
      } else {
        setTimeout(poll, 3000)
      }
    })
  }

  const toggleEditing = () => { setEditing(!editing) }

  /*
  if (progress > 0) {
    return (<span>
      <h4 className='uk-text-center uk-margin'>Uploading...</h4>
    </span>
    )
  }
  */

  if (uploaded) {
    return (
      <div className="flex justify-center flex-col">
        <div className="rounded-md bg-green-50 p-4 mb-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">We are currently processing your image file so that it fits well on all browsers and phones. Thumbnails 'n stuff. This usually takes about a minute.</p>
            </div>
          </div>
        </div>
        <Loading noMessage />
        <p className='text-xs mt-10'>You can safely close this modal.</p>
      </div>
    )
  }

  if (!editing) {
    return <div>

      <div className={`${coverBox} relative block border-2 border-gray-300 flex flex-col justify-center cursor-pointer border-dashed rounded-lg p-12 text-center hover:border-gray-400`} {...getRootProps()} >
        <input {...getInputProps()} />

        { thisPreview && aspect === 'EntryImage' && <div className='w-full'>
          <img className='w-full' src={thisPreview} />
        </div> }

        { thisPreview && aspect === 'square' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
          <img className='object-cover w-full' src={thisPreview} />
        </div> }

        { thisPreview && aspect === 'video' && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
          <img className='object-cover w-full' src={thisPreview} />
        </div> }

        { !thisPreview && <>
          <CloudUploadIcon className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">Click or drop file to upload </span>
        </> }
      </div>
      { file && <div className='text-xs text-gray-500 dark:text-gray-400'>{file.path} - {file.size} bytes</div> }

      { file && <div className='flex justify-end mt-5'>
        <PrimaryButton loading={uploading} onClick={submitForm} text="Save" />
        <DefaultButton className='ml-5' onClick={toggleEditing} text="Edit Image" />
      </div> }
    </div>
  }

  if (editing) {
    return (
      <div>
        <div className='flex justify-center flex-col'>
          <Cropper
            ref={cropperRef}
            src={thisPreview}
            style={style}
            movable={false}
            zoomable={false}
            rotatable={false}
            scalable={false}
            initialAspectRatio={aspectRatio}
            aspectRatio={aspectRatio}
            guides={true}
            crop={onCrop}
          />
        </div>
        <div className='flex justify-end mt-5'>
          <PrimaryButton loading={uploading} onClick={() => submitForm('edited')} text="Save" />
          <DefaultButton className='ml-5' onClick={toggleEditing} text="Cancel" />
        </div>
      </div>
    )
  }
}

export default ImageUploadForm
