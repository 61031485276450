import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useGlobalState } from 'shared/state'
import { Toast } from 'shared/Toast'
import { randomToastSuccess } from 'shared/Toast'
import Card from 'shared/Card'
import MDEditor from '@uiw/react-md-editor'
import useQuery from 'hooks/useQuery'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const EntryText = ({ contest, entry }) => {
  const entryId = entry.id
  const [, setToast] = useGlobalState('toast')
  const { getRequest } = useQuery()
  const [thisEntry, setThisEntry] = useState(entry || {})
  const [textBody, setTextBody] = useState(entry.textBody)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      text_body: thisEntry.textBody
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('text_body')
    return () => {
      unregister('text_body')
    }
  }, [register])

  useEffect(() => {
    setValue('text_body', textBody, { shouldDirty: true, shouldValidate: true })
  }, [textBody])

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const url = `/api/v1/entries/${entry.id}`
    const action = 'PATCH'

    const formData = { ...data }

    putpostRequest(url, action, { entry: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setThisEntry({ ...jsonData.entry })
      setToast(randomToastSuccess())
    })
  })

  return <div className='mt-6'>
    <Card title="Text Entry"
        footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col sm:flex-row items-end space-x-2'>
            { thisEntry.textBody?.length > 0 && <a href={`/entries/${entry.id}/thank_you`} className='mt-6 w-full flex items-center justify-center bg-gradient-to-r from-neonblue to-neonpurple bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-neonpurple whitespace-nowrap'>Submit Entry</a> }
          </div>
        </div>
        } >
      <div className="col-span-4 sm:col-span-4">
        <div className='mt-4'>
          <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
            Text
          </label>
          <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. Any other links or notes. This will only be shown to the contest judges. You can use *common* **markdown** as well.</p>
          <div className="mt-1 shadow-sm w-full min-w-full">
            <MDEditor value={textBody} preview='edit' onChange={setTextBody} hideToolbar={true} />
          </div>
        </div>
        <div className='flex items-center justify-center mt-2'>
          <PrimaryButton className='w-lg' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
        </div>
      </div>
    </Card>
  </div>
}


export default EntryText
