import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import { Toast } from 'shared/Toast'
import { randomToastSuccess } from 'shared/Toast'
import { Modal, useModal } from 'shared/Modal'
import Loading from 'shared/Loading'
import ImageUploadForm from 'shared/ImageUploadForm'

import Card from 'shared/Card'
import { PrimaryButton } from 'shared/Buttons'
import MDEditor from '@uiw/react-md-editor'
//import OmniAuthSettings from 'pages/Settings/OmniAuthSettings'

const SettingsGeneral = ({user, needspassword}) => {
  const [, setToast] = useGlobalState('toast')
  const [timezones, setTimezones] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: user.name,
      timezone: user.timezone,
      email: user.email
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    getRequest('/api/v1/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', user.timezone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/users/${user.id}`, 'PATCH', { user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setToast(randomToastSuccess())
    })
  })

  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              The basics of the basics. Only your name will be public.
            </p>
          </div>
        </div>
        <Card footer={
          <div className='flex justify-end space-x-2'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />

          </div>
          }>
          <form>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Name <span className='text-xs'>(required)</span></span>
                </label>
                <div className="mt-1 flex flex-col rounded-md shadow-sm">
                  <div className='relative'>
                    <input type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } defaultValue={user.name} placeholder="McBuford Tatortot" />
                    { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
              <div className="col-span-3 sm:col-span-1">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Email <span className='text-xs'>(required)</span></span>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input {...register('email', { required: true }) } className={errors.email ? 'errors' : ''} type="text" placeholder="Hedgehog" defaultValue={user.email}/>
                    { errors.email && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.email && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
              </div>
              { needspassword && (
                <div className="md:col-span-2 col-span-3">
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                    <span>Set a Password (min 8 characters)</span>
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                    <div className='relative'>
                      <input type='password' {...register('password', { required: true }) } className={errors.password ? 'errors' : ''} />
                      { errors.password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                      </div> }
                    </div>
                  </div>
                  { errors.password && <div className='mt-2 text-sm text-red-600'>This field is required. Minimum 8 characters</div> }
                </div>
              ) }
              <div className='col-span-2'>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                    Preferred Timezone
                  </label>
                  <select
                    {...register('timezone')}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                  >
                    { timezones.map((tz) => (
                      <option key={tz} value={tz}>{tz.replace(/_/g, ' ')}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

          </form>
        </Card>
      </div>
    </div>
  )
}

const SettingsAbout = ({user}) => {
  const [, setToast] = useGlobalState('toast')
  const [timezones, setTimezones] = useState([])
  const { putpostRequest, getRequest } = useQuery()
  const [about, setAbout] = useState(user.about)
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      about: user.about
    }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('about')
    return () => {
      unregister('about')
    }
  }, [register])

  const onSubmit = debounce(300, data => {
    setLoading(true)

    putpostRequest(`/api/v1/users/${user.id}`, 'PATCH', { user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setToast(randomToastSuccess())
    })
  })

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  useEffect(() => {
    setValue('about', about, { shouldDirty: true, shouldValidate: true })
  }, [about])

  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">About</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Allow yourself to introduce...yourself. This will be public.
            </p>
          </div>
        </div>
        <Card footer={
          <div className='flex justify-end space-x-2'>
          <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />

          </div>
          }>
          <form>
            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  About You. 
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. You can use *common* **markdown** as well.</p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <MDEditor value={about} preview='edit' onChange={setAbout} />
                </div>
              </div>
            </div>
          </form>
        </Card>
      </div>
    </div>
  )
}


const SettingsPassword = () => {
  const [, setToast] = useGlobalState('toast')
  const [customError, setCustomError] = useState(null)
  const { putpostRequest, getRequest } = useQuery()
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: { }
  })

  const { isDirty, errors } = formState
  const [loading, setLoading] = useState(false)

  const onSubmit = debounce(300, data => {
    setLoading(true)
    setError(null)

    putpostRequest(`/api/v1/users/change_password`, 'POST', { user: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof(err) === 'string') { setCustomError(err) }

        Object.entries(err).forEach(([key, value]) => {
          setError(key, value)
        })
        return
      }

      setToast(randomToastSuccess())
    })
  })


  return (
    <div className='mt-10'>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Password</h3>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
              Feeling like your security is a little lax? Change your password here.
            </p>
          </div>
        </div>
        <Card footer={<PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text="Save" />}>
          <form>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1 col-span-3">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>Old Password</span>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input type='password' {...register('old_password', { required: true }) } className={errors.old_password ? 'errors' : ''} />
                    { errors.old_password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.old_password && <div className='mt-2 text-sm text-red-600'>This field is required. Minimum 8 characters</div> }
              </div>
              <div className="md:col-span-1 col-span-3">
                <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
                  <span>New Password</span>
                </label>
                <div className="mt-1 flex rounded-md shadow-sm flex flex-col">
                  <div className='relative'>
                    <input type='password' {...register('password', { required: true }) } className={errors.password ? 'errors' : ''} />
                    { errors.password && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div> }
                  </div>
                </div>
                { errors.password && <div className='mt-2 text-sm text-red-600'>This field is required. Minimum 8 characters</div> }
              </div>
            </div>
            { customError && <div className='mt-2 text-sm text-red-600'>{customError}</div> }

          </form>
        </Card>
      </div>
    </div>
  )
}

const UserImage = ({ user }) => {
  const [url, setUrl] = useState(user.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/users/${user.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Avatar Image</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          This will be public. Optional
        </p>
      </div>
      <Card title='Avatar Image'>
        <div className="md:col-span-2">
          <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
            { !uploaded && <span>
                <img className='h-40 w-40 rounded-sm' src={url} onClick={openModal} alt={user.displayName} />
              </span>
              }
            { uploaded && <div className='relative h-40 w-40 rounded-sm flex items-center justify-center dark:bg-gray-700'>
              <img className='h-40 w-40 rounded-sm' src={url} onClick={openModal} alt={user.displayName} />
              <div className='absolute h-40 w-40 top-8'>
                <Loading noMessage noLoadingMessage />
              </div>
            </div> }
          </div>
        </div>
      </Card>
    </div>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            objectType='User'
            objectId={user.id}
            aspect='square'
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const Settings = ({user, omitPassword, needspassword, showAbout}) => {
  return <>
    <SettingsGeneral user={user.user} needspassword={needspassword} />
    { showAbout && <SettingsAbout user={user.user} /> }
    { omitPassword !== true && <SettingsPassword /> }
    <UserImage user={user.user} />
    <Toast />
  </>
}

export default Settings
