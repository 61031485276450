import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import { Modal, useModal } from 'shared/Modal'
import ImageUploadForm from 'shared/ImageUploadForm'
import { formatInTimezone } from 'utils/date'
import ContestCategoryForm from 'pages/Contests/ContestCategoryForm'
import Card from 'shared/Card'
import Loading from 'shared/Loading'
//import { randomToastSuccess } from 'shared/Toast'

//import { useGlobalState } from 'state'
import useQuery from 'hooks/useQuery'
import FroalaWrapper from 'shared/FroalaWrapper'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const times = [ '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30' ]

const TheForm = ({ contestUpdate, contestId }) => {
  const { putpostRequest } = useQuery()
  const initDate = contestUpdate?.id ? new Date(contestUpdate.sendAtDate) : new Date()
  const [sendAtDate, setSendAtDate] = useState(initDate)
  const [formattedVideoUrl, setFormattedVideoUrl] = useState(contestUpdate.videoUrl)
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus, reset } = useForm({
   defaultValues: {
      title: contestUpdate.title,
      send_at_date: contestUpdate.sendAtDate,
      send_at_time: contestUpdate.sendAtTime,
      video_url: contestUpdate.videoUrl,
      send_at_ampm: contestUpdate.sendAtAmpm,
      body: contestUpdate.body
    }
  })

  const body = getValues().body
  const videoUrl = getValues().video_url

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('title')
  }, [setFocus])

  useEffect(() => {
    register('send_at_date', { required: true })
    register('body')
    return () => {
      unregister('body')
      unregister('send_at_date')
    }
  }, [register])

  useEffect(() => {
    setValue('send_at_date', format(sendAtDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [sendAtDate])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }
  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/contest_updates/'
    let action = 'POST'
    const formData = {
      ...data,
      contest_id: contestId
    }

    if (contestUpdate.id) {
      url = `/api/v1/contest_updates/${contestUpdate.id}`
      action = 'PATCH'
    }

    putpostRequest(url, action, { contest_update: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setFormattedVideoUrl(jsonData.contestUpdate.videoUrl)

      if (action === 'POST') {
        window.location.href = `/contest_updates/${jsonData.contestUpdate.id}/edit`
      }
    })
  })

  const cancel = () => { window.location.href = `/contests/${contestId}/updates` }

  return <>
    <Card>
    <form className='bg-gray-50 p-2'>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-3">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Update </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input onKeyDown={keyPress} type="text" className={errors.title ? 'errors' : ''} {...register('title', { required: true }) } placeholder="Announcing New Prizes!" />
              { errors.title && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.title && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-4"> </div>

        <div className="col-span-4 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>Send Date</span>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <DatePicker
                selected={sendAtDate}
                onChange={(date) => setSendAtDate(date)}
                selectsStart
                startDate={sendAtDate}
                endDate={new Date().setMonth(sendAtDate.getMonth() + 1)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
              { errors.sendAtDateUtc && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.sendAt && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-4 sm:col-span-1">
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Send Time</span>
            </label>
            <select
              {...register('send_at_time', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { times.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-span-4 sm:col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="ampm" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>AM/PM</span>
            </label>
            <select
              {...register('send_at_ampm', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>

        <div className="col-span-4"> </div>
        <div className="col-span-4 sm:col-span-3">
          <label htmlFor="remote_url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Optional Video URL (Loom or YouTube) </label>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Make sure you get the <b>embed</b> link. e.g. (https://www.youtube.com/embed/DiYTaQ-Mgck)
</p>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input onKeyDown={keyPress} type="text" className={errors.video_url ? 'errors' : ''} {...register('video_url') } placeholder="https://www.loom.com/embed/...." />
              { errors.video_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          <div className="min-w-0 flex-1 mt-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
            {formattedVideoUrl && formattedVideoUrl.includes('loom.com') && <>
              <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }} className='w-full'>
                <iframe src={`${formattedVideoUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                  frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                  style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                ></iframe>
              </div>
            </>}
            {formattedVideoUrl && formattedVideoUrl.includes('youtube.com') && <>
              <iframe width="560" height="315" src={formattedVideoUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            </>}
          </div>
        </div>
        <div className="col-span-4"> </div>

        <div className="col-span-4 sm:col-span-4">
          <div className='mt-4'>
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Body of Update
            </label>
            <div className="mt-1 shadow-sm w-full min-w-full">
              <FroalaWrapper
                model={body}
                keyPress={keyPress}
                updateModel={(val) => handleUpdateFroala('body', val)}
              />
            </div>
          </div>
        </div>
        <div className="col-span-4" />
        <div className="col-span-4 sm:col-start-4 sm:col-span-1 flex justify-end">
          <div className='flex items-end w-min ml-2 space-x-2'>
            <DefaultButton className='w-full' onClick={cancel} text={'Back'} />
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text={<div className='flex flex-col'>
              Save
            </div>} />
          </div>
        </div>
      </div>

    </form>
    </Card>
  </>
}

const ContestUpdateImage = ({ contestUpdate }) => {
  const [url, setUrl] = useState(contestUpdate.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/contest_updates/${contestUpdate.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <>
    <div className="flex flex-col mt-6">
      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Optional Image</h3>
      <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
        { !uploaded && url && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
            <img className='object-cover w-full' src={url} onClick={openModal} alt={contestUpdate.title} />
          </div>
          }
        { !uploaded && !url && <div onClick={openModal} className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
          <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> Add Image</span>
        </div> }
        { uploaded && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
          <img className='object-cover w-full' src={url} onClick={openModal} alt={contestUpdate.title} />
          <div className='absolute h-20 w-20 top-8'>
            <Loading noMessage noLoadingMessage />
          </div>
        </div> }
      </div>
    </div>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            objectType='ContestUpdate'
            aspect='video'
            objectId={contestUpdate.id}
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}

const ContestUpdateForm = ({ contestId, contestUpdateId }) => {

  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        contestUpdate: {},
        loading: true
      }
    )
  const { contestUpdate, loading } = state

  useEffect(() => {
    if(!contestUpdateId) {
      setState({loading: false})
      return
    }

    getRequest(`/api/v1/contest_updates/${contestUpdateId}/edit`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( {
        contestUpdate: jsonData.contestUpdate,
        loading: false
      })
    })
  },[contestUpdateId])

  if (loading) return null

  return <>
      <TheForm
        contestUpdate={contestUpdate}
        contestId={contestId}
      />

    { contestUpdateId && <ContestUpdateImage contestUpdate={contestUpdate} /> }
  </>
}

export default ContestUpdateForm

ContestUpdateForm.propTypes = {
  contestId: PropTypes.number.isRequired,
  contestUpdateId: PropTypes.number
}
