import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import ImageUploadForm from 'shared/ImageUploadForm'
import { randomToastSuccess } from 'shared/Toast'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'

const EntryImage = ({ contest, entry }) => {
  const entryId = entry.id
  const [url, setUrl] = useState(entry.remoteUrl || 'https://ccc-emails.s3.amazonaws.com/avatars/CCC_Cover_UNSURE.svg')
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/entries/${entryId}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <div className='mt-6'>
    <Card title="Image Entry">
      <div className="md:col-span-2">
        <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
          { !uploaded && <div className='w-full'>
            <img className='w-full' src={url} onClick={openModal} alt={contest.name} />
          </div>
          }
          { uploaded && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
            <img className='object-cover w-full' src={url} onClick={openModal} alt={contest.name} />
            <div className='absolute h-20 w-20 top-8'>
              <Loading noMessage noLoadingMessage />
            </div>
          </div> }
        </div>
      </div>
      <Modal full isOpen={isOpen} closeModal={closeModal} >
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
        <div className='flex justify-center'>
          <div className="mt-2">
            <ImageUploadForm
              objectType='Entry'
              objectId={entryId}
              aspect='EntryImage'
              url={url}
              setUrl={setUrl}
              success={reloadPublicImageURL}
              didUpload={setUploaded}
            />
          </div>
        </div>
      </Modal>
    </Card>
  </div>
}


export default EntryImage
