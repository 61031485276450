import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import { Toast } from 'shared/Toast'
import Loading from 'shared/Loading'
import { EyeIcon, ExclamationIcon } from '@heroicons/react/solid'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import { useGlobalState } from 'shared/state'
import PublicEntryRow from 'pages/Contests/PublicEntryRow'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No Entries found</span>
      </div>
    </span>
  )
}

const PublicEntriesIndex = ({contestId, currentUser}) => {
  const [, setCurrentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const [order, setOrder] = useState('updated_at')
  const [cat, setCat] = useState('all')
  const [round, setRound] = useState('all')
  const [status, setStatus] = useState('all')
  const [assignedUserId, setAssignedUserId] = useState('all')
  const scrollRef = useRef(null)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        entries: [],
        rounds: [],
        ratings: [],
        cats: [],
        meta: {},
        loading: true,
        loadingRatings: true,
      }
    )
  const { entries, ratings, cats, rounds, meta, loading, loadingRatings } = state

  useDebouncedEffect(() => {
    setState({loading: true})
    fetchData(true)
  }, 200, [contains, order, status, cat, round])

  useEffect(() => { fetchData() }, [page])
  useEffect(() => { 
    setCurrentUser(currentUser)
    getRequest(`/api/v1/contests/${contestId}/ratings`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( { ratings: jsonData.ratings, loadingRatings: false })
    })
  }, [])

  const fetchData = (newPage) => {
    const data = {
      contains: contains,
      order_by: order,
      contest_category_id: cat,
      contest_round_id: round,
      page: newPage ? 1 : page
    }
    getRequest(`/api/v1/contests/${contestId}/public_entries`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setState( {
        entries: jsonData.entries,
        rounds: jsonData.rounds,
        cats: jsonData.cats,
        meta: jsonData.meta,
        loading: false
      })

      //if (counts.length === 0) { setStatuses(jsonData.statuses) }
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') { setOrder(e.target.value) }
    if (e.target.name === 'contest_category_id') { setCat(e.target.value) }
    if (e.target.name === 'contest_round_id') { setRound(e.target.value) }
    if (e.target.name === 'contains') { setContains(e.target.value) }
  }

  return <>
    <div className="mt-2 text-base text-gray-500  dark:bg-gray-800 bg-white rounded-3xl p-2 sm:p-5 xl:p-6 mt-3">
      <>
        <div className='pb-5' ref={scrollRef}>
          <div className='flex flex-col sm:flex-row mb-2'>
            <input name='contains' type="text" onChange={handleChange}
              className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by entry name ex: Goku"
            />

            <select name='order' onChange={handleChange}
              className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option value="updated_at">Latest Activity</option>
              <option value="created_at">Created At</option>
            </select>
            <select name='contest_category_id' onChange={handleChange}
              className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option value="all">All Categories</option>
              { cats.map((cat) => (
                <option key={`catentr${cat.id}`} value={cat.id}>{cat.name} ${cat.entryFee}</option>
              ))}
            </select>
            <select name='contest_round_id' onChange={handleChange}
              className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option value="all">All Rounds</option>
              { rounds.map((round) => (
                <option key={`roundentr${round.id}`} value={round.id}>{round.name} ({round.status})</option>
              ))}
            </select>
          </div>
          { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        { (loading || loadingRatings) && <Loading /> }
        { !(loading || loadingRatings) && <ul className="space-y-3">
          {entries.map((entry) => (
            <PublicEntryRow key={`entry${entry.id}`} entry={entry} ratings={ratings} />
          ))}
        </ul> }
        { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        { entries.length === 0 && !loading && <NoResults /> }
      </>
    </div>
    <Toast />
  </>
}

export default PublicEntriesIndex
