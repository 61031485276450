import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { useGlobalState } from 'shared/state'
import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import ContestRoundForm from 'pages/Contests/ContestRoundForm'
import Card from 'shared/Card'
import Loading from 'shared/Loading'
//import { randomToastSuccess } from 'shared/Toast'

//import { useGlobalState } from 'state'
import useQuery from 'hooks/useQuery'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const ContestRoundFormIndex = ({ contestId, timezone }) => {
  const [currentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        rounds: [],
        loading: true
      }
    )
  const { rounds, loading } = state

  useEffect(() => { fetchData() }, [])

  const fetchData = () => {
    const data = { }
    getRequest(`/api/v1/contests/${contestId}/rounds`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( {
        rounds: jsonData.rounds,
        loading: false
      })
    })
  }

  const appendRound = (round) => {
    let data = rounds.slice();
    data.push(round);
    setState({rounds: data})
  }

  if (!contestId) return null

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Contest Rounds</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          Sequence your contest through rounds. Example: 
        </p>
        <ul>
          <li>Round 1 - Singing Sad Songs Theme</li>
          <li>Round 2 - Singing 90s Rock Theme</li>
        </ul>
      </div>
      <Card title="Rounds" footer={
        <>
          { !currentUser.canCreateRounds && <div className='text-sm text-gray-800'>Want to create a multi-round contest? Please <a href='/subscriptions'>upgrade your account</a>.</div> }
          { !currentUser.canCreateRounds && <ContestRoundForm
              initialRound={{}}
              otherRounds={rounds}
              contestId={contestId}
              appendRound={appendRound}
              timezone={timezone}
            /> }
        </>
        } >
        <div className="md:col-span-2">
          { loading && <Loading /> }
          {rounds.map((round, idx) => (
            <ContestRoundForm
              key={`contround${round.id}`}
              otherRounds={rounds}
              initialRound={round}
              contestId={contestId}
              showNewRoundButton={currentUser.canCreateRounds}
              timezone={timezone}
            />
          ))}
          
        </div>
      </Card>
    </div>
  </>
}

export default ContestRoundFormIndex

ContestRoundFormIndex.propTypes = {
  contestId: PropTypes.number.isRequired,
  contestRounds: PropTypes.array.isRequired
}
