import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'
import Autosuggest from 'react-autosuggest'
import { DefaultButton } from 'shared/Buttons'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'

import useQuery from 'hooks/useQuery'

const LabelAutocomplete = ({ addLabel, placeholder, entryId, contestId, searchOnly }) => {
  const { getRequest } = useQuery()
  const [labels, setLabels] = useState([])
  const [defaultLabels, setDefaultLabels] = useState([])
  const [searchText, setSearchText] = useState('')

  const onKeyDown = (event) => {
    const { keyCode } = event
    if (keyCode === 13 || keyCode === 188) {
      if (searchText.length > 0 && labels.length === 0) {
        addLabel(searchText)
        setSearchText('')
      }
    }
  }

  const addLabelWithButton = () => {
    addLabel(searchText)
    setSearchText('')
  }

  useEffect(() => {
    if (searchText === ',') { setSearchText('') } // hack to stop commas from being by themselves
  }, [searchText])

  const fetchData = (newValue) => {
    // no need to hit the server repeatedly when the query is blank
    if (newValue === '' && defaultLabels.length > 0) {
      setLabels(defaultLabels)
      return
    }
    debounce(300, getRequest(`/api/v1/contests/${contestId}/labels`, { q: newValue }, (err, jsonData) => {
      if (err) { /* hook */ return }
      setLabels(jsonData.labels)
      if (newValue === '') {
        setDefaultLabels(jsonData.labels)
      }
    }))
  }

  const handleAutocomplete = (event, { newValue, method }) => {
    if (method === 'up' || method === 'down') return

    setSearchText(newValue)

    if (method === 'enter' || method === 'click') {
      addLabel(newValue)
      setSearchText('')
      return
    }

    fetchData(newValue)
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion ? suggestion.name : ''

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = AutosuggestHighlightMatch(suggestion.name, query)
    const parts = AutosuggestHighlightParse(suggestion.name, matches)
    return (
      <div className={`${isHighlighted ? 'bg-neonblue bg-opacity-20 text-gray-900' : 'bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-200'} w-full h-full p-2 cursor-pointer capitalize`}>
        {parts.map((part, index) => {
          const className = part.highlight ? 'text-neonpink font-semibold' : null

          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          )
        })}
      </div>
    )
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => { /* setFranchises(value) */ }
  const onSuggestionsClearRequested = () => { setLabels([]) }
  const shouldRenderSuggestions = (value, reason) => {
    return value.trim().length >= 0
  }

  const onFocus = (event) => {
    if (labels.length === 0) {
      fetchData('')
    }
  }

  const inputProps = {
    placeholder: placeholder,
    value: searchText, // must be a string
    type: 'search',
    onKeyDown: onKeyDown,
    onFocus: onFocus,
    onChange: handleAutocomplete
  }

  if (searchOnly) {
    return <Autosuggest
      className=''
      suggestions={labels}
      highlightFirstSuggestion={true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      shouldRenderSuggestions={shouldRenderSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  }

  return <div className='flex space-x-2'>
    <Autosuggest
      className=''
      suggestions={labels}
      highlightFirstSuggestion={true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      shouldRenderSuggestions={shouldRenderSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
    <DefaultButton onClick={addLabelWithButton} text={<span className='whitespace-nowrap'>Add Label</span>} />
  </div>
}

export default LabelAutocomplete

LabelAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  addLabel: PropTypes.func.isRequired
}
