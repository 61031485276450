import React, { useState, useEffect, useRef, useReducer } from 'react'

import { PlayIcon, PauseIcon } from '@heroicons/react/solid'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import AudioPlayer from 'shared/AudioPlayer'

const AudioPlayerPreview = ({mediaUrl}) => {
  const { putpostRequest } = useQuery()
  const [loaded, setLoaded] = useState(false)

  // always need to have the wavesurfer id in the dom otherwise madness
  return <>
    <div onClick={() => setLoaded(true)} className={`bg-neonblue text-white h-16 w-full rounded`}>
      <div className="w-full mx-auto px-3 sm:px-6 lg:px-8">
        { !loaded && <div className="flex items-center justify-start">
          <PlayIcon className='h-16 w-16 cursor-pointer' />
        </div> }
        <div className="flex items-center justify-between">
          <div className="w-full flex-1 flex items-center justify-center">
            <div className='flex-1 flex items-center justify-center'>
              { loaded && <>
                <AudioPlayer mediaUrl={mediaUrl} autoPlay={true} />
              </> }
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default AudioPlayerPreview
