import React from 'react'
import PropTypes from 'prop-types'
import Tribute from 'tributejs'
import 'froala-editor/js/plugins.pkgd.min.js'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditor from 'react-froala-wysiwyg'

export default class FroalaWrapper extends React.Component {
  handleModelChange = (model) => {
    this.props.updateModel(model)
  }

  render() {
    const { hideButtons, focusOnLoad, showCharacterCounter, model, linkStyles, paragraphFormat, paragraphStyles, keyPress, placeholderText, heightMin } = this.props
    const startingHeight = heightMin || 50
    let toolbarButtons = ['bold', 'italic', 'align', 'quote', 'insertLink', 'formatOL', 'formatUL', 'image', 'textColor', 'fontSize', 'fontSize', 'insertImage', 'clearFormatting', 'html']

    const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content

    if (hideButtons) { toolbarButtons = [] }

    const transformHtml = (html) => {
      // google docs uses inline css, convert to blocks
      let transformedHtml = html
      const boldRegex = /<span style="[^"]*font-weight:700[^"]*">([^<]+)<\/span>/g
      const italicRegex = /<span style="[^"]*font-style:italic[^"]*">([^<]+)<\/span>/g
      // convert content links from notion shorthand of "//" prefix
      const contentLinkRegex = /href="\/\/([^"]+)"/g
      // relative img url's are not supported, use placeholder
      const relativeImgRegex = /<img.+?(?=src)src="((?!http)[^"]+)"/g
      const CMS_PLACEHOLDER_IMG_URL = 'https://s3.amazonaws.com/ccc-emails/logo.svg'

      if (transformedHtml.match(boldRegex)) {
        transformedHtml = transformedHtml.replace(boldRegex, '<b>$1</b>')
      }
      if (transformedHtml.match(italicRegex)) {
        transformedHtml = transformedHtml.replace(italicRegex, '<em>$1</em>')
      }
      if (transformedHtml.match(contentLinkRegex)) {
        transformedHtml = transformedHtml.replace(contentLinkRegex, 'href="/m/$1"')
      }
      //if (transformedHtml.match(relativeImgRegex)) {
        //transformedHtml = transformedHtml.replace(relativeImgRegex, `<img src="${CMS_PLACEHOLDER_IMG_URL}"`)
      //}

      return transformedHtml
    }

    const cleanupHtml = (html) => {
      // froala inserts some undesired blocks at times, remove them
      const undesirables = [
        '<br>',
        '<br />',
        '<p>&nbsp;</p>',
        '<p><br></p>',
        '<p><strong>&nbsp;</strong></p>',
        '<p><strong><u>&nbsp;</u></strong></p>',
        '<p><span>&nbsp;</span></p>',
        '<p><strong><span>&nbsp;</span></strong></p>'
      ]
      const re = new RegExp(undesirables.join('|'), 'g')
      return html.replace(re, '')
    }

    const tribute = new Tribute({
      trigger: '@',
      allowSpaces: true,
      values: function (query, callback) {
        window.fetch(`/api/v3/users/mention_search?contains=${query}`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }).then((response) => {
          response.json().then((data) => {
            if (data.users) {
              callback(data.users)
            }
          })
        })
      },
      lookup: 'username',
      fillAttr: 'username',
      containerClass: 'tribute-container',
      menuItemTemplate: function (item) {
        return `<span class='flex'><img class='h-6 w-6 mr-1' src="${item.original.imageUrl}" />${item.string}</span>`
      },
      noMatchTemplate: function (item) {
        return '<span>@ mention <a target="_blank" href="/followers">your followers</a></span>'
      },
      menuItemLimit: 10,
      selectClass: 'highlight',
      selectTemplate: function (item) {
        return `<span class="fr-deletable fr-tribute" contenteditable="false"><a data-mention="mention" data-id="${item.original.id}" data-to="${item.original.username}" href="#" >@${item.original.username}</a></span>`
      }
    })

    const config = {
      toolbarButtons: toolbarButtons,
      attribution: false,
      key: window.froala_key,
      charCounterCount: showCharacterCounter,
      maxCharacters: showCharacterCounter ? 200 : 0,
      imageAllowedtypes: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg+xml', 'svg'],
      quickInsertTags: [''],
      placeholderText: placeholderText || 'Enter text here',
      linkAlwaysBlank: true,
      heightMin: startingHeight,
      width: '100%',
      imageDefaultWidth: '100%',
      linkStyles: linkStyles,
      paragraphFormat: paragraphFormat,
      paragraphStyles: paragraphStyles || {},
      paragraphMultipleStyles: false,
      htmlUntouched: true,
      pasteDeniedAttrs: ['class', 'id'],
      wordPasteModal: false,
      wordDeniedAttrs: ['class', 'id', 'style'],
      imageUploadToS3: {
        bucket: window.froala_hash.bucket,
        region: 's3',
        keyStart: window.froala_hash.key_start,
        params: {
          acl: window.froala_hash.acl,
          AWSAccessKeyId: window.froala_hash.access_key,
          policy: window.froala_hash.policy,
          signature: window.froala_hash.signature
        }
      },
      events: {
        'initialized': async function () {
          // Froala fails to initialize in time even in its own initalize function
          // https://github.com/froala/react-froala-wysiwyg/issues/189
          // https://codesandbox.io/s/lively-breeze-l9z82?file=/src/index.js:1270-1322
          await new Promise(resolve => setTimeout(resolve, 1))
          const editor = this

          tribute.attach(editor.el)

          editor.events.on('keydown', function (e) {
            if (e.which === 13 && tribute.isActive) { return false }
            if (e.which === 13 && !tribute.isActive && typeof (keyPress) === 'function') {
              keyPress(e)
              return false
            }
          }, true)

          if (typeof (focusOnLoad) !== 'undefined') {
            editor.events.focus(true)
            editor.events && editor.events.focus(true)
          }
        },
        'paste.beforeCleanup': (clipboardHtml) => {
          return transformHtml(clipboardHtml)
        },
        'paste.afterCleanup': (clipboardHtml) => {
          return cleanupHtml(clipboardHtml)
        },
        'focus': (e, editor) => {
          // needs to reference `this` here
          if (this.props.onClick) {
            this.props.onClick()
          }
        }
      }
    }

    return <div className="froala-wrapper prose min-w-full">
        <FroalaEditor
          tag="textarea"
          config={config}
          model={model}
          onModelChange={this.handleModelChange}
        />
      </div>
  }
}

FroalaWrapper.propTypes = {
  model: PropTypes.string,
  updateModel: PropTypes.func.isRequired,
  keyPress: PropTypes.func,
  hideButtons: PropTypes.bool,
  showCharacterCounter: PropTypes.bool,
  linkStyles: PropTypes.object,
  paragraphFormat: PropTypes.object,
  paragraphStyles: PropTypes.object,
  placeholderText: PropTypes.string,
  heightMin: PropTypes.number,
  focusOnLoad: PropTypes.bool,
  onClick: PropTypes.func
}

FroalaWrapper.defaultProps = {
  className: '',
  heightMin: 50,
  linkStyles: {},
  paragraphFormat: {
    N: 'Normal',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6'
  },
  customButtons: [],
  customMentions: []
}
