import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import { Toast } from 'shared/Toast'
import Loading from 'shared/Loading'
import { EyeIcon, ExclamationIcon } from '@heroicons/react/solid'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import { useGlobalState } from 'shared/state'
import PublicEntryRow from 'pages/Contests/PublicEntryRow'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No Entries found</span>
      </div>
    </span>
  )
}

const PublicEntriesIndex = ({contestId, currentUser}) => {
  const [, setCurrentUser] = useGlobalState('currentUser')
  const { getRequest } = useQuery()
  const [page, setPage] = useState(1)
  const scrollRef = useRef(null)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        entries: [],
        meta: {},
        loading: true,
      }
    )
  const { entries, ratings, meta, loading } = state

  useEffect(() => { fetchData() }, [page])
  useEffect(() => { setCurrentUser(currentUser) }, [])

  const fetchData = (newPage) => {
    const data = {
      page: newPage ? 1 : page
    }
    getRequest(`/api/v1/entries/dashboard_entries`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setState( {
        entries: jsonData.entries,
        meta: jsonData.meta,
        ratings: jsonData.ratings,
        loading: false
      })
    })
  }

  return <>
    <div className="mt-2 text-base text-gray-500  dark:bg-gray-800 bg-white rounded-3xl p-2 sm:p-5 xl:p-6 mt-3">
      <>
        <div className='pb-5' ref={scrollRef}>
          { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        { (loading) && <Loading /> }
        { !(loading) && <ul className="space-y-3">
          {entries.map((entry, idx) => (
            <PublicEntryRow key={`entry${entry.id}`} entry={entry} ratings={ratings} />
          ))}
        </ul> }
        { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        { entries.length === 0 && !loading && <NoResults /> }
      </>
    </div>
    <Toast />
  </>
}

export default PublicEntriesIndex
