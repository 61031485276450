import { Controller } from "@hotwired/stimulus"

function toggleActive(target) {
  let node = target.firstElementChild
  if (target.classList.contains('border-indigo-500')) {
    target.classList.remove('border-indigo-500', 'text-indigo-600')
    target.classList.add('border-transparent', 'text-gray-500', 'hover:text-gray-700', 'hover:border-gray-200')
    if (node) {
      node.classList.remove('bg-indigo-100', 'text-indigo-600')
      node.classList.add('bg-gray-100', 'text-gray-900')
    }
  } else {
    target.classList.add('border-indigo-500', 'text-indigo-600')
    target.classList.remove('border-transparent', 'text-gray-500', 'hover:text-gray-700', 'hover:border-gray-200')
    if (node) {
      node.classList.add('bg-indigo-100', 'text-indigo-600')
      node.classList.remove('bg-gray-100', 'text-gray-900')
    }
  }
}

function toggleModalOpen(target) {
  if (target.classList.contains('hidden')) {
    target.classList.remove('hidden')
  } else {
    target.classList.add('hidden')
  }
}

export default class extends Controller {
  static targets = [
    'categoryModal',
    'contestmenu',
  ];
  //static classes = [ 'selected' ];
  static values = { activetab: String, default: 'general' }

  //connect() { }
  //disconnect() { }
  initialize() {
    //console.log(this.activetab, this.activetabValue, 'hi')
    if (this.activetabValue !== '') {
      let el = document.getElementById('tabs')
      if (el) {
        el.scrollIntoView()
      }
    }
  }

  contestmenuToggle(e){
    if (this.contestmenuTarget.classList.contains('hidden')) {
      this.contestmenuTarget.classList.remove('hidden')
    } else {
      this.contestmenuTarget.classList.add('hidden')
    }
  }
  //
  categoryModalToggle(e) {
    toggleModalOpen(this.categoryModalTarget)
  }
}
