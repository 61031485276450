import React, { useState, useEffect, useRef } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { CreditCardIcon, CheckCircleIcon, ExclamationIcon, EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid'
import WhatsIncluded from 'pages/Subscriptions/WhatsIncluded'
import Downgrade from 'pages/Subscriptions/Downgrade'
import { Toast } from 'shared/Toast'

import {PrimaryButton, DefaultButton} from 'shared/Buttons'
import useQuery from 'hooks/useQuery'
import { useGlobalState } from 'shared/state'

import Loading from 'shared/Loading'

export const PLANS = [
  { name: 'Free', plan: 'free', priceYearly: 0 },
  { name: 'Neon', plan: 'neon', priceYearly: 99 },
]

const initialPlan = (existingSubscription) => {
  if (!Boolean(existingSubscription)) {
    return PLANS[1]
  }

  let thePlan = PLANS.find(plan => plan.plan === existingSubscription.plan)
  if (!thePlan) { thePlan = PLANS[1] }

  return thePlan
}


const ExistingPlan = (props) => {
  const { existingSubscription, setUpdateExisting } = props
  const [, setToast] = useGlobalState('toast')
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const { putpostRequest, getRequest } = useQuery()
  const [canceling, setCanceling] = useState(false)
  const [loading, setLoading] = useState(false)
  const planDetails = initialPlan(existingSubscription)
  const [downgradeTo, setDowngradeTo] = useState(existingSubscription.downgradeTo)

  const toggleCanceling = () => { setCanceling(!canceling) }
  const revertCancel = () => {
    setLoading(true)
    putpostRequest(`/api/v1/stripe/subscriptions/revert_cancel`, 'POST', {}, async (err, jsonData) => {
      if (err) { setErrorMessage(err) }
      setLoading(false)
      setDowngradeTo(null)
      toggleCanceling()
    })
  }
  const cancel = () => {
    setLoading(true)
    putpostRequest(`/api/v1/stripe/subscriptions/cancel`, 'POST', {}, async (err, jsonData) => {
      if (err) { setErrorMessage(err) }
      setLoading(false)
      setDowngradeTo('cancel')
      toggleCanceling()
    })
  }

  if (loading) { return <Loading /> }

  if (canceling) {
    return <div  className="my-12 max-w-xl bg-white mx-auto p-6 rounded-md">
      <div className='mb-6'>
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Turn off auto-renew</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>Are you sure you want to turn off auto-renew?  If you do, previous charges will not be refunded, but you may continue to use the service until the end of the 12-month term on {existingSubscription.expiresAt}.</p>
            </div>
          </div>
        </div>
      </div>
      <DefaultButton onClick={toggleCanceling} text="Back" />
      <DefaultButton className='ml-5' onClick={cancel} text="Yep, go ahead and turn off auto-renew" />
    </div>
  }

  return <>
    <div className="my-12 max-w-xl bg-white mx-auto p-6 rounded-md">
      <div>
        <div className='pt-5'>
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">My Current Plan</h3>
        </div>
        { downgradeTo === 'cancel' && <Downgrade existingSubscription={existingSubscription} /> }
        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Plan Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{planDetails.name}</dd>
            </div>
            { downgradeTo === 'cancel' && <>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">12-month Subscription Cycle <b className='text-red-500'>Expires</b></dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{existingSubscription.expiresAt}</dd>
              </div>
            </> }
            { downgradeTo !== 'cancel' && <>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">Amount Charged On Next Billing Cycle</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200"> $99 USD </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-300">12-month Subscription Cycle Renews</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 dark:text-gray-200">{existingSubscription.expiresAt}</dd>
              </div>
            </> }
          </dl>
        </div>
        <div className='flex items-center justify-start'>
          {downgradeTo !== 'cancel' && <DefaultButton className='mr-5' onClick={toggleCanceling} text="Turn off auto-renew" /> }
          {downgradeTo === 'cancel' && <PrimaryButton className='mr-5' onClick={revertCancel} text="I Changed My Mind, I Want To Keep My Subscription" /> }
        </div>
      </div>
    </div>
    <Toast />
  </>
}
export default ExistingPlan
