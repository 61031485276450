import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CreditCardIcon, CheckCircleIcon, ExclamationIcon, EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid'

import {DefaultButton, PrimaryButton} from 'shared/Buttons'
import useQuery from 'hooks/useQuery'
import { useGlobalState } from 'shared/state'

import Loading from 'shared/Loading'

const options = {
  hidePostalCode: true,
  style: {
    base: {
      'fontSize': '16px',
      'color': '#424770',
      'letterSpacing': '0.025em',
      'fontFamily': 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
}

function EntryFeeCheckout ({entryId, feeAmount}) {
  const stripePromise = window.stripeKey ? loadStripe(window.stripeKey) : undefined
  const [errorMessage, setErrorMessage] = useState(null)
  const [updateExisting, setUpdateExisting] = useState(false)
  const [oneTimeCheckout, setOneTimeCheckout] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        paymentMethods: [], // TODO: this would be passed along to CheckoutForm as a prop, but not doing it now
        loadingPaymentMethods: true,
        existingSubscription: null// TODO: this would be passed along to CheckoutForm as a prop, but not doing it now
      }
    )
  const { paymentMethods, existingSubscription, loadingPaymentMethods } = state

  return (
    <div className='max-w-xl mx-auto bg-white mt-10 p-6'>
      <Elements stripe={stripePromise}>
        <CheckoutForm entryId={entryId} feeAmount={feeAmount} />
      </Elements>
    </div>
  )
}

const CheckoutForm = ({entryId, feeAmount}) => {
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [ paymentMethods, setPaymentMethods ] = useState([])
  const [newPaymentMethod, setNewPaymentMethod] = useState(true)
  const pollRef = useRef(20)
  const [, setToast] = useGlobalState('toast')
  const { putpostRequest, getRequest } = useQuery()
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const [stripeReady, setStripeReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const stripe = useStripe()
  const elements = useElements()

  const assignDefaultPaymentMethod = () => {
    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.defaultPaymentMethod)
    if (defaultPaymentMethod) { setSelectedPaymentMethodId(defaultPaymentMethod.id) }
  }

  useEffect(() => {
    if (paymentMethods.length > 0) { setNewPaymentMethod(false) }
    assignDefaultPaymentMethod()
  }, [paymentMethods])

  useEffect(() => {
    if (newPaymentMethod === true) {
      setSelectedPaymentMethodId(null)
    } else {
      assignDefaultPaymentMethod()
    }
  }, [newPaymentMethod])

  useEffect(() => {
    setStripeReady(Boolean(selectedPaymentMethodId)) 
  }, [selectedPaymentMethodId])


  const onNewPaymentMethodChange = (e) => {
    if (e.error) {
      setErrorMessage('This appears to be an invalid card number.')
    } else {
      setErrorMessage(null)
      if (e.complete) {
        setStripeReady(true)
      } else {
        setStripeReady(false)
      }
    }
  }

  const createStripePaymentMethod = async () => {
    setLoading(true)
    const stripePayload = await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
    setLoading(false)

    if (stripePayload.error || !stripePayload.paymentMethod || !stripePayload.paymentMethod.id) {
      // spam slack channel with stripePayload.error.message, current_user.id
      setErrorMessage(stripePayload.error.message)
      return
    }
    const newCard = {
      ...stripePayload.paymentMethod.card,
      id: stripePayload.paymentMethod.id,
      ccExpYear: stripePayload.paymentMethod.card.exp_year,
      ccExpMonth: stripePayload.paymentMethod.card.exp_month
    }
    setPaymentMethods([...paymentMethods, newCard ])
    return stripePayload.paymentMethod.id
  }

  const purchase = async () => {
    setLoading(true)
    setErrorMessage(null)
    const paymentMethodId = selectedPaymentMethodId || await createStripePaymentMethod()
    setLoading(false)
    if (paymentMethodId) {
      setSelectedPaymentMethodId(paymentMethodId)
      createOneTimePurchase(paymentMethodId)
    }
    /* The createStripePaymentMethod method will handle error messaging if it fails to create a payment method */
  }

  const createOneTimePurchase = async (paymentMethodId) => {
    setLoading(true)
    const data = {
      payment_method_id: paymentMethodId,
      entry_id: entryId,
    }

    putpostRequest('/api/v1/stripe/checkout_fee', 'POST', { checkout: data }, async (err, jsonData) => {
      if (err) { setErrorMessage(err) }
      if (jsonData && !jsonData.requiresAction) {
        successfulPurchase()
      }
      if (jsonData && jsonData.requiresAction) {
        await handlePaymentThatRequiresCustomerAction({
          paymentIntentClientSecret: jsonData.paymentIntentClientSecret,
          paymentMethodId: paymentMethodId
        })
      }
    })
  }

  const pollForAccess = () => {
    setLoading(true)
    pollRef.current -= 1
    getRequest(`/api/v1/users/${currentUser.id}`, {}, (err, jsonData) => {
      if (pollRef.current <= 0) {
        setErrorMessage('Code 987: Could not verify card in time')
        pollRef.current = 20
        return
      }
      if (jsonData.user.access === 'course-only') {
        setLoading(false)
        successfulPurchase()
        return
      } else {
        setTimeout(pollForAccess, 2000)
      }
    })
  }

  const successfulPurchase = () => {
    window.location.href = `/entries/${entryId}/thank_you`
  }

  const handlePaymentThatRequiresCustomerAction = async ({ paymentIntentClientSecret, paymentMethodId }) => {
    setLoading(true)
    const confirmCardResult = await stripe.confirmCardPayment(paymentIntentClientSecret, { payment_method: paymentMethodId })
    setLoading(false)
    if (confirmCardResult.error) {
      // The card was declined (i.e. insufficient funds, card has expired, etc). Try another payment method
      console.log(`handlePaymentThatRequiresCustomerAction - throwing ${confirmCardResult.error.message}`)
      setErrorMessage(confirmCardResult.error.message)
    } else {
      if (confirmCardResult.paymentIntent.status === 'succeeded') {
        // handled invoice.payment_succeeded webhook.
        // There's a risk of the customer closing the window before callback
        // execution.
        //
        //
        // TODO: when polling, we need to look at entryId.status change
        // TODO: when polling, we need to look at entryId.status change
        // TODO: when polling, we need to look at entryId.status change
        // TODO: when polling, we need to look at entryId.status change
        // TODO: when polling, we need to look at entryId.status change
        // TODO: when polling, we need to look at entryId.status change
        pollForAccess()
        console.log('handlePaymentThatRequiresCustomerAction - confirmCardPayment PI succeeded')
      } else {
        setErrorMessage('Code 1943: We were unable to charge your card at this time. Please try another card.')
      }
    }
  }

  return <div className='p-6 rounded-md'>
    <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
      Credit or Debit Card
    </label>
    <div className='mt-1'>
      { !newPaymentMethod && !loading && <>
        { paymentMethods.length > 0 && <>
          <ul role="list" className="flex flex-col">
            { paymentMethods.map((pm, idx) => (
              <li key={pm.id} onClick={() => setSelectedPaymentMethodId(pm.id)} className="relative cursor-pointer">
                <dl className="mt-6 p-3 bg-gray-50 hover:bg-gray-100 dark:bg-gray-750 dark:hover:bg-gray-800 text-gray-600 dark:text-gray-300 border-2 rounded-md">
                  { selectedPaymentMethodId === pm.id && <>
                    <span className="sr-only" hidden>Card Selected</span>
                    <dd className="flex items-center text-sm">
                      <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                      Selected
                    </dd>
                  </> }
                  <dd className="flex flex-col sm:flex-row sm:items-center text-sm justify-start sm:justify-between font-medium sm:mr-6">
                    <div className='flex justify-start w-32'>
                      <CreditCardIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className='whitespace-nowrap text-sm'>Last 4: xxxxxxxxxx<strong>{pm.last4}</strong></span>
                    </div>
                    <span className="text-sm font-medium">Exp: {pm.ccExpMonth} / {pm.ccExpYear}</span>
                    <span className="text-sm font-medium w-24 capitalize">{pm.brand}</span>
                  </dd>
                </dl>
              </li>
            ))}
          </ul>
          <div className='cursor-pointer text-clcnavy hover:text-clcnavy-light dark:hover:text-clcnavy-light text-sm py-3' onClick={() => setNewPaymentMethod(true)}>Add New Payment Method</div>
        </> }
      </> }
      { newPaymentMethod && <>
        <div className='bg-gray-50 p-2 rounded-md'>
          <CardElement id='card-element' options={options} onChange={onNewPaymentMethodChange} />
        </div>
        { paymentMethods.length > 0 && <>
          <div className='cursor-pointer text-clcnavy hover:text-clcnavy-light text-sm py-3' onClick={() => setNewPaymentMethod(false)}>Use A Saved Card</div>
        </> }
      </>}
      { errorMessage && <div className='mt-2 text-sm text-red-600'>{errorMessage}</div> }
    </div>

    <div className="flex flex-col items-center mt-5">
      <PrimaryButton onClick={purchase} loading={loading} disabled={!stripeReady} text={`Pay ${feeAmount} entry fee`} />
      <span className='mt-1 text-xs text-gray-400 dark:text-gray-400'>We use Stripe to securely process transactions</span>
    </div>
  </div>
}

export default EntryFeeCheckout
