import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import { Toast } from 'shared/Toast'
import Loading from 'shared/Loading'
import { EyeIcon, XIcon } from '@heroicons/react/solid'
import { randomToastSuccess } from 'shared/Toast'
import { useGlobalState } from 'shared/state'
import { PrimaryButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import ChosenEntryRow from 'pages/Contests/ChosenEntryRow'
import Tabs from 'pages/Contests/EntryTabs'
import LabelAutocomplete from 'shared/LabelAutocomplete'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No Chosen Entries found</span>
      </div>
    </span>
  )
}


const UnsentRounds = ({contestId, currentUser}) => {
  const { getRequest, putpostRequest } = useQuery()
  const [, setCurrentUser] = useGlobalState('currentUser')
  const [, setToast] = useGlobalState('toast')
  const [unsentRounds, setUnsentRounds] = useState([])
  const [selectedRoundId, setSelectedRoundId] = useState(null)
  const [selectedRound, setSelectedRound] = useState(null)
  const [sent, setSent] = useState(false)
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const scrollRef = useRef(null)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        entries: [],
        rounds: [],
        meta: {},
        loading: true
      }
    )
  const { entries, rounds, meta, loading } = state

  useEffect(() => {
    setCurrentUser(currentUser)
    getRequest(`/api/v1/contests/${contestId}/unsent_rounds`, {}, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setUnsentRounds(jsonData.completedRounds)
    })
  }, [])

  useDebouncedEffect(() => {
    setState({loading: true})
    fetchData(true)
  }, 200, [contains])

  useEffect(() => {
    if (selectedRoundId !== null) {
      const theRound = unsentRounds.filter(r => r.id === parseInt(selectedRoundId, 10))
      if (theRound.length === 1) {
        setSelectedRound(theRound[0])
      }
    }
    fetchData()
  }, [page, selectedRoundId])

  const sendAll = () => {
    putpostRequest(`/api/v1/contest_rounds/${selectedRoundId}/send_invite_to_round`, 'POST', {}, (err, jsonData) => {
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setSent(true)
      setToast(randomToastSuccess())
    })
  }

  const fetchData = (newPage) => {

    if (selectedRoundId === null) {
      setState({entries: [], loading: false})
      return
    }

    const data = {
      contains: contains,
      contest_category_id: 'all',
      review_status: 'chosen',
      assigned_user_id: 'all',
      contest_round_id: selectedRoundId,
      page: newPage ? 1 : page
    }
    getRequest(`/api/v1/contests/${contestId}/entries`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( {
        entries: jsonData.entries,
        rounds: jsonData.rounds,
        meta: jsonData.meta,
        loading: false
      })
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'contains') { setContains(e.target.value) }
    if (e.target.name === 'contest_round_id') { setSelectedRoundId(e.target.value) }
  }

  const changeTab = (tab) => { setStatus(tab) }

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Completed Rounds</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          If you have a future invite-only round that depends on the entries from a previous round being chosen, this is the place. You can send an email to invite the chosen entrants to an invite-only round. If you need to add more chosen entrants to a round, please do so in the Entries tab.
        </p>
        <div className='relative' style={{padding:'56.25% 0 0 0'}}><iframe src="https://www.loom.com/embed/8593ffa3c69c468394afc39b0cbe92a3" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='absolute top-0 left-0 w-full h-full'  allowFullScreen title='Video Entry'></iframe></div>
      </div>
      <Card light title='Invite Chosen Entrants To Next Round'>
        <>
          <div className='flex flex-col w-full'>
            <label htmlFor="contest_round_id" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Choose a round </label>
            <select name='contest_round_id' onChange={handleChange}
              className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option value="">--- Choose a Round ---</option>
              { unsentRounds.map((round) => (
                <option key={`coroundentr${round.id}`} value={round.id}>Entries from {round.name}</option>
              ))}
              { unsentRounds.length === 0 && <option value="">No rounds have finished yet.</option> }
            </select>
          </div>
          <div className='w-30'><PrimaryButton onClick={sendAll} text='Send invite email' /></div>
          { selectedRound?.chosenEntriesSentAt && <div className='text-green-800 text-center w-full'>Sent on {selectedRound.chosenEntriesSentAt} </div> }
          { selectedRound?.onlyEntriesFromRoundName && !selectedRound?.chosenEntriesSentAt && entries.length > 0 && <div className='flex flex-col justify-center items-center'>
            <div className='block w-full text-center font-medium text-gray-700 dark:text-gray-300 mt-2'>The following entrants will be sent an invite to {selectedRound.onlyEntriesFromRoundName}</div>
            <div className='w-30'><PrimaryButton onClick={sendAll} text='Send invite email' /></div>
            {sent && <div className='text-green-800'>Successfully sent </div> }
          </div>}
          <div className='pb-5' ref={scrollRef}>
            { entries.length > 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
          </div>
          <ul className="space-y-3">
            {entries.map((entry) => (
              <ChosenEntryRow key={`chosentry${entry.id}`} initialEntry={entry} />
            ))}
          </ul>
          { loading && <Loading /> }
          { entries.length > 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
          { selectedRound !== null && entries.length === 0 && !loading && <NoResults /> }
        </>
      </Card>
    </div>
    <Toast />
  </>
}

export default UnsentRounds
