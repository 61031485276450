import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import { Toast } from 'shared/Toast'
import Loading from 'shared/Loading'
import { EyeIcon, ExclamationIcon } from '@heroicons/react/solid'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import ManageCommentRow from 'pages/Contests/ManageEntryComment'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No Comments found</span>
      </div>
    </span>
  )
}

const CommentsIndex = ({contestId, currentUser}) => {
  const { getRequest } = useQuery()
  const [page, setPage] = useState(1)
  const [contains, setContains] = useState(null)
  const [order, setOrder] = useState('updated_at')
  const scrollRef = useRef(null)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        comments: [],
        meta: {},
        loading: true
      }
    )
  const { comments, counts, cats, rounds, meta, teamUsers, loading } = state

  useDebouncedEffect(() => {
    setState({loading: true})
    fetchData(true)
  }, 200, [contains, order])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    const data = {
      contains: contains,
      page: newPage ? 1 : page
    }
    getRequest(`/api/v1/contests/${contestId}/comments`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( {
        comments: jsonData.comments,
        meta: jsonData.meta,
        loading: false
      })

    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') { setOrder(e.target.value) }
    if (e.target.name === 'contains') { setContains(e.target.value) }
  }

  return <>
    <div />
    <Card light title='Comments'>
      <>
        <div className='pb-5' ref={scrollRef}>
          <div className='flex flex-col sm:flex-row mb-2'>
            <input name='contains' type="text" onChange={handleChange}
              className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by entry name ex: Goku"
            />

            <select name='order' onChange={handleChange}
              className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option value="updated_at">Latest Activity</option>
              <option value="created_at">Created At</option>
            </select>
          </div>
          { comments.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        { loading && <Loading /> }
        <ul className="space-y-3">
          {comments.map((comment) => (
            <ManageCommentRow key={`mancomm${comment.id}`} initialComment={comment} />
          ))}
        </ul>
        { comments.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        { comments.length === 0 && !loading && <NoResults /> }
      </>
    </Card>
    <Toast />
  </>
}

export default CommentsIndex
