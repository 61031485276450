import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useGlobalState } from 'shared/state'
import { useForm } from 'react-hook-form'
import { PrimaryButton} from 'shared/Buttons'
import MDEditor from '@uiw/react-md-editor'
import useQuery from 'hooks/useQuery'
import { debounce } from 'throttle-debounce'

const CommentForm = ({ existingComment, commentableId, commentableType, setParentComment, addChildtoParent }) => {
  const [currentPage] = useGlobalState('currentPage')
  const { putpostRequest } = useQuery()
  const [body, setBody] = useState(existingComment?.body)
  const { formState, reset, register, unregister, handleSubmit, setValue, getValues, setError } = useForm({
   defaultValues: {
      body: existingComment?.body || ''
    }
  })

  const { isDirty } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register('body', { required: true })
    return () => {
      unregister('body')
    }
  }, [register])

  useEffect(() => {
    setValue('body', body, { shouldDirty: true, shouldValidate: true })
  }, [body])

  const onSubmit = debounce(300, data => {
    setLoading(true)

    let action = 'POST'
    let url = '/api/v1/comments'

    if (existingComment?.id) {
      action = 'PATCH'
      url = `/api/v1/comments/${existingComment.id}`
    }
    const formData = {
      body: data.body,
      commentable_type: commentableType,
      commentable_id: commentableId
    }

    putpostRequest(url, action, { comment: formData }, (err, jsonData) => {
      setLoading(false)

      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
          return
        } else {
          if (err === 'Please login first') { window.location.href = '/signup' }
          return
        }
      }

      if (action === 'POST') {
        addChildtoParent(jsonData)
      } else {
        setParentComment({ ...existingComment, body: jsonData.body })
      }

      setBody(null)
    })
  })

  const buttonText = existingComment?.id ? 'Save' : 'Add Comment'

  return <>
    <div className="mt-1">
      <MDEditor height={100} value={body} preview='edit' onChange={setBody} hideToolbar={true} />
      <div className='mt-2' />
      <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} disabled={!isDirty} text={buttonText} />
    </div>
  </>
}

export default CommentForm

CommentForm.propTypes = {
  existingComment: PropTypes.object,
  commentableId: PropTypes.number,
  commentableType: PropTypes.string,
  setParentComment: PropTypes.func,
  setHide: PropTypes.func,
  addChildtoParent: PropTypes.func
}
