import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import Pagination from 'shared/Pagination'
import { Toast } from 'shared/Toast'
import Loading from 'shared/Loading'
import { EyeIcon, XIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import MyEntryRow from 'pages/Entries/MyEntryRow'
import Tabs from 'pages/Entries/EntryTabs'
import LabelAutocomplete from 'shared/LabelAutocomplete'

const NoResults = () => {
  return (
    <span>
      <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cccblue" >
        <EyeIcon className="mx-auto h-12 w-12 text-gray-400" />
        <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300"> No Results</span>
      </div>
    </span>
  )
}

const MyEntries = ({contestId, currentUser}) => {
  const { getRequest } = useQuery()
  const [, setCurrentUser] = useGlobalState('currentUser')
  const [page, setPage] = useState(1)
  const [filterLabels, setFilterLabels] = useState([])
  const [contains, setContains] = useState(null)
  const [order, setOrder] = useState('updated_at')
  const [cat, setCat] = useState('all')
  const [round, setRound] = useState('all')
  const [status, setStatus] = useState('all')
  const [assignedUserId, setAssignedUserId] = useState('all')
  const scrollRef = useRef(null)

  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        entries: [],
        rounds: [],
        cats: [],
        teamUsers: [],
        counts: {},
        meta: {},
        loading: true
      }
    )
  const { entries, counts, cats, rounds, meta, teamUsers, loading } = state

  useEffect(() => {
    setCurrentUser(currentUser)
  }, [])

  useDebouncedEffect(() => {
    setState({loading: true})
    fetchData(true)
  }, 200, [contains, order, assignedUserId, status, cat, round, filterLabels])

  useEffect(() => { fetchData() }, [page])

  const fetchData = (newPage) => {
    const data = {
      contains: contains,
      order_by: order,
      status: status,
      page: newPage ? 1 : page
    }
    getRequest(`/api/v1/entries`, data, (err, jsonData) => {
      if (err) { /* hooks */ return }

      setState( {
        counts: jsonData.counts,
        entries: jsonData.entries,
        meta: jsonData.meta,
        loading: false
      })

      //if (counts.length === 0) { setStatuses(jsonData.statuses) }
    })
  }
  const handleChange = (e) => {
    if (e.target.name === 'order') { setOrder(e.target.value) }
    if (e.target.name === 'contains') { setContains(e.target.value) }
  }

  const changeTab = (tab) => { setStatus(tab) }

  const addLabel = (label) => { setFilterLabels([...filterLabels, ...[label]]) }

  const removeFilterLabel = (labelName) => {
    const newLabels = filterLabels.filter(label => label !== labelName)
    setFilterLabels(newLabels)
  }

  return <>
    <div />
    <Card light title='Entries'>
      <>
        <div className='pb-5' ref={scrollRef}>
          <Tabs counts={counts} status={status} changeTab={changeTab} />
          <div className='flex flex-col sm:flex-row sm:space-x-2 mt-1'>
            <div className='flex flex-col w-full'>
              <label htmlFor="contains" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Filter by Entry Name </label>
              <input name='contains' type="text" onChange={handleChange}
                className="shadow-sm focus:ring-cccpurple dark:bg-gray-900 dark:text-white focus:border-cccpurple block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Search by entry name ex: Goku"
              />
            </div>

            <div className='flex flex-col w-full'>
              <label htmlFor="order" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Sort by </label>
              <select name='order' onChange={handleChange}
                className="block w-full border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option value="updated_at">Latest Activity</option>
                <option value="created_at">Created At</option>
              </select>
            </div>
          </div>

          { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        </div>
        { loading && <Loading /> }
        <ul className="space-y-3 mx-auto max-w-2xl">
          {entries.map((entry) => (
            <MyEntryRow key={`entry${entry.id}`} initialEntry={entry} teamUsers={teamUsers} />
          ))}
        </ul>
        { entries.length >= 0 && <Pagination meta={meta} callback={setPage} scrollRef={scrollRef} /> }
        { entries.length === 0 && !loading && <NoResults /> }
      </>
    </Card>
    <Toast />
  </>
}

export default MyEntries
