import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { ChatAltIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/solid'
import CommentForm from 'pages/Contests/PublicEntryCommentForm'
import useQuery from 'hooks/useQuery'
import { useGlobalState } from 'shared/state'

const DeletedCommentRow = ({ comment }) => {
  const children = comment.comments || []
  if (children.length === 0) { return null }

  return <>
    <div className={`relative pl-7 py-2`}>
      <span className={`absolute top-5 left-10 -ml-px h-full w-0.5 bg-opacity-20 bg-cccorange dark:bg-cccpurple`} aria-hidden="true" />
      <div className="relative flex items-start space-x-3">
        <div className="relative">
          <ChatAltIcon className="h-6 w-6 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" aria-hidden="true" />
        </div>
        <div className="flex-1 md:py-0 pb-2">
          <div className="mt-2 text-sm text-gray-700 dark:text-gray-300 space-y-4">[deleted]</div>
        </div>
      </div>
      { children.length > 0 && <ul className="space-y-3 mt-2">
        {children.map((com, idx) => (
          <ManageCommentRow key={`mancom${com.id}`} initialComment={com} />
        ))}
      </ul> }
    </div>
  </>
}

const ManageCommentRow = ({ initialComment }) => {
  const { putpostRequest } = useQuery()
  const [currentUser] = useGlobalState('currentUser')
  const [comment, setComment] = useState(initialComment)
  if (!comment?.id) { return null }

  useEffect(() => { setEditing(false) }, [comment])

  const [children, setChildren] = useState(comment.comments || [])
  const [editing, setEditing] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [showReplyForm, setShowReplyForm] = useState(false)
  if (comment.body === '[deleted]' || deleted) {
    return <DeletedCommentRow comment={comment} />
  }

  const addChildtoParent = (childComment) => {
    const newChildren = Object.assign([], children)
    newChildren.push(childComment)
    setEditing(false)
    setShowReplyForm(false)
    setChildren(newChildren)
  }

  const deleteComment = () => {
    putpostRequest(`/api/v1/comments/${comment.id}`, 'delete', {}, (err, jsonData) => {
      if (err) { /* hooks */ return }
      setDeleted(true)
    })
  }

  return <>
    <div className='relative pl-7 py-2'>
      <span className='absolute top-5 left-10 -ml-px h-full w-0.5 bg-opacity-20 bg-cccorange dark:bg-cccpurple' aria-hidden="true" />
      <div className="relative flex items-start space-x-3">
        <div className="relative">
          <img className="h-6 w-6 rounded-sm bg-gray-200 flex items-center justify-center ring-4 ring-white dark:ring-gray-800" src={comment.imageUrl} alt={comment.name} />

          <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px dark:bg-gray-800">
            <ChatAltIcon className="h-2 w-2 text-gray-600 dark:text-gray-400" aria-hidden="true" />
          </span>
        </div>
        <div className="min-w-0 flex-1">
          { editing && <CommentForm
            existingComment={comment}
            commentableId={comment.commentableId}
            commentableType={comment.commentableType}
            setParentComment={setComment}
            setHide={setEditing}
          /> }
          { !editing && <div className="flex-1 md:py-0 pb-2">
            <div className="flex justify-between">
              <div className="flex justify-start items-center">
                <span aria-expanded="false">
                  <div><a className="font-medium" href={`/m/${comment.userSlug}`}>{comment.name}</a></div>
                </span>
                <span className="uppercase text-xs dark:text-gray-300 text-gray-500 ml-2">
                  <time dateTime={comment.createdAt}>{comment.createdAt}</time>
                </span>
              </div>
            </div>
            <div className="text-sm text-gray-700 dark:text-gray-300 space-y-2" dangerouslySetInnerHTML={{ __html: comment.body }} />
            <div className="mt-2 flex justify-between space-x-8">
              <div className="flex space-x-6">
                <button onClick={() => setShowReplyForm(true)} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                  <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="text-xs">Reply</span>
                </button>
                {comment.userId === currentUser?.id && <>
                  <button onClick={() => setEditing(true)} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                    <PencilAltIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="text-xs">Edit</span>
                  </button>
                </> }
                <button onClick={deleteComment} type="button" className="flex space-x-1 text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 items-center">
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="text-xs">Delete</span>
                </button>
              </div>
            </div>
            { showReplyForm && <CommentForm
              commentableId={comment.id}
              commentableType='Comment'
              addChildtoParent={addChildtoParent}
              setHide={setShowReplyForm}
            /> }
          </div> }
        </div>
      </div>
      { children.length > 0 && <ul className="space-y-3 mt-2">
        {children.map((com, idx) => (
          <ManageCommentRow key={`mancom${com.id}`} initialComment={com} />
        ))}
      </ul> }
    </div>
  </>
}

export default ManageCommentRow

ManageCommentRow.propTypes = {
  initialComment: PropTypes.object,
  hideImage: PropTypes.bool
}

DeletedCommentRow.propTypes = {
  comment: PropTypes.object
}
