import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import classNames from 'utils/classNamesLocal'

const styles = {
  desktop: {
    selectedTab: 'border-neonblue text-neonblue dark:text-white dark:bg-neonblue',
    unselectedTab: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-white',
    defaultTab: 'whitespace-nowrap py-2 px-2 border-b-2 font-medium text-sm rounded-t-md cursor-pointer'
  },
  mobile: {
    selectedTab: 'border-neonblue text-neonblue dark:text-white dark:bg-neonblue',
    unselectedTab: 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 dark:text-white',
    defaultTab: 'block rounded-md pt-2 px-3 text-base font-medium cursor-pointer'
  }
}

const TabCount = ({ number, selected }) => {
  return <>
    <span className={classNames(
      selected ? 'bg-neonblue text-white dark:bg-white dark:text-neonblue' : 'bg-gray-200 text-gray-900',
      'ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
    )} >
      {number}
    </span>
  </>
}

const Tab = ({ value, name, view, count, status, changeTab }) => {
  const selected = status === value

  return (
    <div onClick={() => changeTab(value)} className={classNames(
        selected ? styles[view].selectedTab : styles[view].unselectedTab, styles[view].defaultTab
      )} aria-current={selected ? 'page' : undefined} aria-label={name} >
      {name}
      { count && count > 0 && <TabCount number={count} selected={selected} /> }
    </div>
  )
}

export default function EntryTabs({ counts, status, changeTab }) {

  return (
    <Disclosure as="header" className=''>
      {({ open }) => (
        <>
          <div className="sm:divide-y sm:divide-gray-200">
            <div className="border-b border-gray-200">
              <nav className="hidden lg:py-2 lg:flex lg:space-x-4" aria-label="Global">
              <Tab name='All Entries' changeTab={changeTab} status={status} value='all' view='desktop' count={counts.all} />
              <Tab name='Unfinished' changeTab={changeTab} status={status} value='waiting' view='desktop' count={counts.waiting} />
              <Tab name='Uploaded' changeTab={changeTab} status={status} value='uploaded' view='desktop' count={counts.uploaded} />
              <Tab name='Payment Pending' changeTab={changeTab} status={status} value='payment_pending' view='desktop' count={counts.payment_pending} />
              <Tab name='Completed' changeTab={changeTab} status={status} value='completed' view='desktop' count={counts.completed} />
              </nav>
            </div>
            <div className="relative flex items-center lg:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                <span className="sr-only" hidden>Open menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <><MenuIcon className="h-6 w-6 mr-2" aria-hidden="true" /> Filter Submissions</>
                )}
              </Disclosure.Button>
            </div>
          </div>

          <Disclosure.Panel as="nav" className="sm:hidden" aria-label="Global">
            <div className="pt-2 pb-3 px-2 space-y-1">
              <Tab name='All Entries' changeTab={changeTab} status={status} value='all' view='mobile' count={counts.all} />
              <Tab name='Unfinished' changeTab={changeTab} status={status} value='waiting' view='mobile' count={counts.waiting} />
              <Tab name='Uploaded' changeTab={changeTab} status={status} value='uploaded' view='mobile' count={counts.uploaded} />
              <Tab name='Payment Pending' changeTab={changeTab} status={status} value='payment_pending' view='mobile' count={counts.payment_pending} />
              <Tab name='Completed' changeTab={changeTab} status={status} value='completed' view='mobile' count={counts.completed} />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  count: PropTypes.string,
  value: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired
}
EntryTabs.propTypes = {
  status: PropTypes.string.isRequired,
  counts: PropTypes.object.isRequired,
  changeTab: PropTypes.func.isRequired
}
TabCount.propTypes = {
  number: PropTypes.number.isRequired,
  selected: PropTypes.bool
}
