import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import ImageUploadForm from 'shared/ImageUploadForm'
import { Toast } from 'shared/Toast'
import { randomToastSuccess } from 'shared/Toast'
import Loading from 'shared/Loading'

import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import MDEditor from '@uiw/react-md-editor'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

import EntryImage from 'pages/Entries/EntryImage'
import EntryAudio from 'pages/Entries/EntryAudio'
import EntryVideo from 'pages/Entries/EntryVideo'
import EntryText from 'pages/Entries/EntryText'
import EntryPdf from 'pages/Entries/EntryPdf'

const EntryGeneral = ({catId, entry, setEntry, kinds}) => {
  const [, setToast] = useGlobalState('toast')
  const { getRequest } = useQuery()
  const [thisEntry, setThisEntry] = useState(entry || {})
  const [note, setNote] = useState(entry.note)
  const { putpostRequest } = useQuery()
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: thisEntry.name,
      kind: thisEntry.kind,
      discount_code: thisEntry.discountCode,
      note: thisEntry.note
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    register('note')
    return () => {
      unregister('note')
    }
  }, [register])

  useEffect(() => {
    setValue('note', note, { shouldDirty: true, shouldValidate: true })
  }, [note])

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }
  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/entries/'
    let action = 'POST'

    if (entry.id) {
      url = `/api/v1/entries/${entry.id}`
      action = 'PATCH'
    }

    const formData = {
      ...data,
      contest_category_id: catId
    }

    putpostRequest(url, action, { entry: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      if (action === 'POST') {
        window.history.pushState({}, '', `/entries/${jsonData.entry.id}/edit`)
      }
      setEntry({ ...jsonData.entry })
      setToast(randomToastSuccess())
    })
  })

  return <div className='mt-6'>
      <Card title="Entry Information"
        footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Next' />
          </div>
        </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Your Entry </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Buford's Entry" />
                  { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">This will be shown to the public</p>
              { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-4"> </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Note
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. Any other links or notes. This will only be shown to the contest judges. You can use *common* **markdown** as well.</p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <MDEditor value={note} preview='edit' onChange={setNote} hideToolbar={true} />
                </div>
              </div>
            </div>
            <div className="col-span-4"> </div>

            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Your entry type </label>
              <select name='status' {...register('kind', { required: true })}
                className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                {kinds.map((kind, idx) => (
                  <option>{kind}</option>
                ))}
              </select>
            </div>
            { errors.kind && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            <div className="col-span-4"> </div>

            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="discount_code" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Have a Promo Code? </label>
              <span className='text-xs'>Promo codes are CaSe SenSItiVe</span>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.discount_code ? 'errors' : ''} {...register('discount_code') } />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Card>
  </div>
}


const EntryUpload = ({ catId, initEntry }) => {
  const { getRequest } = useQuery()
  const [entry, setEntry] = useState(initEntry)
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        cat: {},
        contest: {},
        loading: true
      }
    )
  const { contest, cat, loading } = state

  useEffect(() => {
    getRequest(`/api/v1/contest_categories/${catId}`, {}, (err, jsonData) => {
      if (err) { return }
      setState( {
        contest: jsonData.contest,
        cat: jsonData.cat,
        loading: false
      })
    })
  }, [])

  if (loading) return null

  return <div className='max-w-xl mx-auto'>
    <EntryGeneral catId={catId} entry={entry} setEntry={setEntry} kinds={cat.kinds} />
    { entry?.id && entry.kind === 'image' && <EntryImage contest={contest} entry={entry} /> }
    { entry?.id && entry.kind === 'audio' && <EntryAudio contest={contest} entry={entry} /> }
    { entry?.id && entry.kind === 'video' && <EntryVideo contest={contest} entry={entry} /> }
    { entry?.id && entry.kind === 'text' && <EntryText contest={contest} entry={entry} /> }
    { entry?.id && entry.kind === 'pdf' && <EntryPdf contest={contest} entry={entry} /> }
    { entry?.id && entry.kind != null && entry.kind !== 'text' && <a href={`/entries/${entry.id}/thank_you`} className='mt-6 w-full flex items-center justify-center bg-gradient-to-r from-neonblue to-neonpurple bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-neonpurple'>Submit Entry</a> }

    <Toast />
  </div>
}

export default EntryUpload

EntryUpload.propTypes = {
  catId: PropTypes.number.isRequired
}
