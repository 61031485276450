import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ExclamationIcon, ArrowDownIcon } from '@heroicons/react/solid'
import { useGlobalState } from 'shared/state'

import useQuery from 'hooks/useQuery'
import PlanSelector from 'pages/Subscriptions/PlanSelector'
import ExistingPlan from 'pages/Subscriptions/ExistingPlan'
import Loading from 'shared/Loading'

const SubscriptionIndex = ({currentUser}) => {
  const stripePromise = window.stripeKey ? loadStripe(window.stripeKey) : undefined
  const [errorMessage, setErrorMessage] = useState(null)
  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      { ...state, ...newState }), {
        paymentMethods: [],
        legacyNotice: null,
        loadingSub: true,
        loadingPaymentMethods: true,
        existingSubscription: null
      }
    )
  const { paymentMethods, existingSubscription, loadingPaymentMethods, loadingSub } = state

  useEffect(() => {
    fetchPaymentMethods()
    fetchActiveSubscription()
  }, [])

  const fetchActiveSubscription = () => {
    getRequest('/api/v1/stripe/subscriptions/active', {}, (err, jsonData) => {
      if (err) {
        setState({ loadingSub: false })
      } else {
        if (jsonData.activeSubscription) {
          setState({ existingSubscription: jsonData.activeSubscription, loadingSub: false })
        } else {
          setState({ loadingSub: false })
        }
      }
    })
  }

  const fetchPaymentMethods = () => {
    getRequest('/api/v1/stripe/payment_methods', {}, (err, jsonData) => {
      if (err) {
        setErrorMessage(err.errors)
        setState({ loadingPaymentMethods: false })
      } else {
        setErrorMessage(null)
        setState({ paymentMethods: jsonData.paymentMethods, loadingPaymentMethods: false })
      }
    })
  }

  if (loadingPaymentMethods || loadingSub) {
    return <Loading />
  }

  if (existingSubscription) {
    return <ExistingPlan existingSubscription={existingSubscription} />
  }

  return <>
    <Elements stripe={stripePromise}>
      <PlanSelector
        currentUser={currentUser}
        paymentMethods={paymentMethods}
        errorMessage={errorMessage}
        existingSubscription={existingSubscription}
      />
    </Elements>
  </>
}

export default SubscriptionIndex
