import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'

import { CheckCircleIcon, CloudUploadIcon, EmojiHappyIcon } from '@heroicons/react/solid'

import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'

const ProgressBar = ({ progressPercentage }) => {
  let color = 'bg-cccpurple-alt'
  if (progressPercentage > 30 && progressPercentage <= 50) {
    color = 'bg-gradient-to-r from-neonblue to-neonblue-alt'
  } else if (progressPercentage > 50 && progressPercentage <= 70) {
    color = 'bg-gradient-to-r from-neonblue-alt via-neonpurple to-neonpurple-alt'
  } else if (progressPercentage > 70) {
    color = 'bg-gradient-to-r from-neonpurple-alt via-rose-900 to-rose-500'
  }
  return (
    <div className='h-2 w-full bg-gray-300'>
      <div style={{ width: `${progressPercentage}%` }} className={`h-full ${color}`}> </div>
    </div>
  )
}

const PdfUploadForm = (props) => {
  const { kind, objectId, onProcessingStarted, onSuccess } = props
  const [, setToast] = useGlobalState('toast')
  const [s3, setS3] = useState({})
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content
  const { getRequest, putpostRequest } = useQuery()
  const isComponentMounted = useRef(false)

  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState(0.0)
  const [file, setFile] = useState(null)
  const [pdfUpload, setPdfUpload] = useState({})

  useEffect(() => {
    isComponentMounted.current = true
    getRequest('/api/v1/uploads/amazon_hash_pdf', {}, (err, jsonData) => {
      if (err) { return }
      setS3(jsonData)
    })
    return () => { isComponentMounted.current = false }
  }, [])

  useEffect(() => {
    if (!file) { return }
    submitForm()
  }, [file])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
        <p className="mt-1 text-sm dark:text-red-300 text-red-400">Accepted formats are pdf. Max file size is 25MB.</p>
      </div>)
    }

    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0])
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'application/pdf', maxFiles: 1, maxSize: 26214400 })

  const submitForm = () => {
    if (!file) { return }

    setUploading(true)
    const data = {
      upload: {
        object_type: kind,
        object_file_type: 'pdf',
        object_id: objectId,
      }
    }
    putpostRequest('/api/v1/uploads', 'POST', data, (err, jsonData) => {
      if (err) { /* hook */ return }

      setPdfUpload(jsonData.upload)
    })
  }
  useEffect(() => {
    if (!file) { return }
    save()
  }, [pdfUpload])

  const axiosConfig = {
    onUploadProgress: progressEvent => setProgressPercentage((progressEvent.loaded / progressEvent.total) * 100),
    headers: { 'X-CSRF-TOKEN': csrfToken }
  }

  const save = () => {
    var data = new FormData()
    var key = `pdf_uploads/${pdfUpload.id}/${pdfUpload.id}${file.name.replace(/[^\w\d_\-.]+/ig, '')}`
    var urlbase = `https://${s3.bucket}.s3.amazonaws.com`

    data.append('key', key)
    data.append('AWSAccessKeyId', s3.access_key)
    data.append('acl', 'public-read')
    data.append('policy', s3.policy)
    data.append('signature', s3.signature)
    data.append('Content-Type', file.type)
    data.append('Content-Encoding', 'base64')
    data.append('file', file)

    axios.post(urlbase, data, axiosConfig).then((response) => {
      updateUploadWithRemoteURL(urlbase + '/' + key)
    })
  }

  const showToast = () => {
    setToast(<>
      <div className="flex-shrink-0">
        <EmojiHappyIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium dark:text-white text-gray-800">Uploaded!</p>
        <p className="mt-1 text-sm dark:text-gray-100 text-gray-500">Impressive uploading skills, my friend.</p>
      </div>
    </>)
  }

  const updateUploadWithRemoteURL = (url) => {
    onProcessingStarted({
      url: url,
      pdfFileName: file.name
    })
    const data = { s3_url: url }
    putpostRequest(`/api/v1/uploads/${pdfUpload.id}`, 'PATCH', { upload: data }, (err, jsonData) => {
      if (err) { /* handled in hook */ return }
      setUploading(false)
      setProgressPercentage(0.0)
      setUploaded(true)
      setTimeout(poll, 3000)
    })
  }

  const poll = () => {
    getRequest(`/api/v1/uploads/${pdfUpload.id}`, {}, (err, jsonData) => {
      if (err) { return }
      if (jsonData.upload && jsonData.upload.processed) {
        if (jsonData.upload?.error) {
          setToast(<div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium dark:text-red-100 text-red-600">Error</p>
            <p className="mt-1 text-sm dark:text-red-300 text-red-400">{jsonData.upload.error}</p>
          </div>)
          setUploaded(false)
          setFile(null)
        } else {
          if (isComponentMounted.current) {
            showToast()
            onSuccess()
            setUploaded(false)
            setFile(null)
          }
        }
      } else {
        setTimeout(poll, 3000)
      }
    })
  }

  if (uploaded) {
    return (
      <div className="flex justify-center flex-col bg-gray-50 dark:bg-gray-750 w-96 rounded-lg p-2">
        <div className="rounded-md bg-green-50 p-4 mb-5">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">I'm currently processing your file. CDNs, cloud storage, 'n stuff. This usually takes about a minute.</p>
            </div>
          </div>
        </div>
        <Loading noMessage />
        <p className='text-xs mt-10'>You can safely click away. It won't break anything. I think.</p>
      </div>
    )
  }

  return <div className='bg-gray-50 dark:bg-gray-750 w-96'>
    <div className="relative block h-48 w-full border-2 border-gray-300 flex flex-col justify-center cursor-pointer border-dashed rounded-lg p-12 text-center hover:border-gray-400" {...getRootProps()} >
      <input {...getInputProps()} />
      <CloudUploadIcon className="mx-auto h-12 w-12 text-gray-900" />
      <span className="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
        Click or drop a <b>PDF</b> file to upload.
      </span>
    </div>
    { file && <div className='w-full border-b-2 border-l-2 border-r-2 border-dashed rounded-b-lg border-gray-300 p-2'>
      <div className='mt-2 text-sm text-gray-500 dark:text-gray-400 break-words'>{file.path} - {(file.size / (1024*1024)).toFixed(2)} MB</div>
      { uploading && <span className='m-2'><Loading noMessage noLoadingMessage /></span> }
      { uploading && <ProgressBar progressPercentage={progressPercentage} /> }
    </div> }
  </div>
}

export default PdfUploadForm

PdfUploadForm.propTypes = {
  kind: PropTypes.string.isRequired,
  objectId: PropTypes.number.isRequired,
  onProcessingStarted: PropTypes.func,
  onSuccess: PropTypes.func
}

ProgressBar.propTypes = {
  progressPercentage: PropTypes.number.isRequired
}
