import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useGlobalState } from 'shared/state'
import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess, successfullySavedToast } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import MDEditor from '@uiw/react-md-editor'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw"
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import PublicEntryComments from 'pages/Contests/PublicEntryComments'
import GradeModal from 'pages/Contests/GradeModal'
import LabelAutocomplete from 'shared/LabelAutocomplete'
import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  EyeIcon,
  MinusCircleIcon,
  ExclamationIcon,
  PlusCircleIcon,
  PencilAltIcon,
  ArrowCircleUpIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
  LinkIcon
} from '@heroicons/react/solid'
import AudioPlayer from 'shared/AudioPlayer'
import AudioPlayerPreview from 'shared/AudioPlayerPreview'

const statusPillStyle = {
  unreviewed: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-800 capitalize',
  dismissed: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize',
  chosen: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize',
  undecided: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 capitalize'
}

const StatusPill = ({ status }) => {
  return <span className={statusPillStyle[status]}>{status} </span>
}

const EntryForm = ({initialEntry, setInitialEntry, closeModal}) => {
  const [, setToast] = useGlobalState('toast')
  const [entry, setEntry] = useState(initialEntry)
  const [reviewNotes, setReviewNotes] = useState(entry.reviewNotes)
  const { putpostRequest } = useQuery()

  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus, reset } = useForm({
   defaultValues: {
      name: entry.name
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/entries/'
    let action = 'POST'

    if (entry.id) {
      url = `/api/v1/entries/${entry.id}`
      action = 'PATCH'
    }

    const formData = { ...data, }

    putpostRequest(url, action, { entry: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setInitialEntry({ ...jsonData.entry })
      closeModal()
      setToast(randomToastSuccess())
    })
  })

  return <div className='mt-6'>
    <form>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-2">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Their Entry </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Buford's Entry" />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">This will be shown to the public. You are able to make edits for moderation purposes.</p>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>
      </div>
      <PrimaryButton onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
    </form>
  </div>
}

const EntryRow = ({ initialEntry, teamUsers }) => {
  const { openModal, closeModal, isOpen } = useModal()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [, setToast] = useGlobalState('toast')
  const [entry, setEntry] = useState(initialEntry)
  const [reviewNotes, setReviewNotes] = useState(entry.reviewNotes)
  const [labels, setLabels] = useState(initialEntry.labels)
  const [showComments, setShowComments] = useState(false)
  const [minimized, setMinimized] = useState(false)
  const { putpostRequest } = useQuery()

  const handleChange = (e) => {
    const formData = { assigned_user_id: e.target.value }
    putpostRequest(`/api/v1/entries/${entry.id}`, 'PATCH', { entry: formData }, (err, jsonData) => {
      if (err) { // 422 code
        return
      }
      setToast(successfullySavedToast())
    })
  }

  const handleReviewStatusChange = (e) => {
    const formData = { review_status: e.target.value }
    putpostRequest(`/api/v1/entries/${entry.id}`, 'PATCH', { entry: formData }, (err, jsonData) => {
      if (err) { // 422 code
        return
      }
      setToast(successfullySavedToast())
    })
  }

  const saveReviewNotes = (e) => {
    const formData = { review_notes: reviewNotes }
    putpostRequest(`/api/v1/entries/${entry.id}`, 'PATCH', { entry: formData }, (err, jsonData) => {
      if (err) { // 422 code
        return
      }
      setToast(successfullySavedToast())
    })
  }

  const addLabel = (label) => {
    const data = {
      entry_id: entry.id,
      name: label,
      contest_id: entry.contestId
    }
    putpostRequest('/api/v1/labels', 'POST', { label: data }, (err, jsonData) => {
      if (err) { /* hook */ return }

      setLabels([...labels, ...[jsonData.label]])
    })
  }

  const removeLabel = (labelId) => {
    const newLabels = labels.filter(label => label.id !== labelId)
    setLabels(newLabels)

    putpostRequest(`/api/v1/labels/${labelId}`, 'DELETE', {}, (err, jsonData) => {
      if (err) { /* hook */ }
    })
  }

  const deleteEntry = (e) => {
    putpostRequest(`/api/v1/entries/${entry.id}/delete`, 'POST', { }, (err, jsonData) => {
      if (err) { /* hooks */ return }

      closeModal()
      setDeleted(true)
    })
  }


  if (minimized) {
    return <li className="bg-gray-100 dark:bg-gray-700 px-4 py-6 shadow sm:p-6 sm:rounded-lg">
      <div className="sm:flex sm:justify-between sm:items-top">
        <a href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>{entry.name}</a>
        <PlusCircleIcon onClick={() => setMinimized(false)} className='h-4 w-4 text-gray-700 cursor-pointer' />
      </div>
    </li>
  }

  if (deleted) { return null }

  return <>
    <li className="bg-gray-100 dark:bg-gray-700 px-4 py-6 shadow sm:p-6 sm:rounded-lg">
      <div>
        <div className="sm:flex sm:justify-between sm:items-top">
          <div className="flex-shrink-0">
            <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
          </div>
          <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
            <div className='flex flex-col truncate'>
              <span className="flex space-x-2">
                <time className='uppercase text-xs dark:text-gray-300 text-gray-500 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
                <StatusPill status={entry.reviewStatus} />

                { entry.weightedRating > 0 && <div className='flex items-center'>
                  <span className='text-xs text-gray-500'> {entry.weightedRating}</span>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="w-4 text-yellow-500 mr-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                  </svg>
                </div> }
              </span>
              <a href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>{entry.name}</a>
              <div className='flex justify-start items-center space-x-3'>
                <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName} - <span className='capitalize'>{entry.kind} entry</span></span>
              </div>
              <div className="min-w-0 mt-1 flex-1 flex sm:items-center  flex-wrap">
                {labels.map((label, idx) => (
                  <span key={`laform${label.id}`} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-neonblue dark:bg-neonblue text-xs font-medium rounded-full flex capitalize">{label.name}</span>
                ))}
              </div>
            </div>
          </div>
          <div className='flex space-x-2'>
            <MinusCircleIcon onClick={() => setMinimized(true)} className='h-4 w-4 text-gray-700 cursor-pointer' />
          </div>
        </div>
      </div>
      { entry.note && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'>Notes from entry: <ReactMarkdown children={entry.note} rehypePlugins={[rehypeRaw]} /></div> }
      { reviewNotes && <div className='mt-2 text-sm bg-yellow-100 text-yellow-800 p-2 rounded-sm'>Private Notes: <ReactMarkdown children={reviewNotes} rehypePlugins={[rehypeRaw]} /></div> }
      { entry.status === 'completed' && <>
        <div className='mt-2 flex justify-center items-center w-full'>

          { entry.kind === 'audio' && <div className='w-full p-1'>
            <AudioPlayerPreview mediaUrl={entry.remoteUrl} />

          </div> }

          { entry.kind === 'pdf' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
            <iframe className='w-full' src={entry.remoteUrl} />
          </div> }
          { entry.kind === 'text' && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><ReactMarkdown children={entry.textBody} rehypePlugins={[rehypeRaw]} /></div> }

          { entry.kind === 'image' && <div className='w-full hover:opacity-80 cursor-pointer' onClick={openModal}>
            <div className='w-full'>
              <img className='object-cover w-full' src={entry.remoteUrl} alt={entry.name} />
            </div>
          </div> }
          { entry.kind === 'video' && <div className='flex flex-col w-full justify-center'>
            <div className="aspect-w-16 aspect-h-9 w-full">
              <iframe src={`https://iframe.videodelivery.net/${entry.remoteUrl}?poster=https://videodelivery.net/${entry.remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
            </div>
          </div> }
        </div>
      </> }
      <div className='flex justify-between items-center space-x-3 my-2 text-gray-800'>
        { entry.status === 'completed' && <>
          <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200' onClick={openModal}>
            <PencilAltIcon className='w-6 h-6' />
            <span>Edit Entry</span>
          </div>
          <div className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer hover:bg-gray-200' onClick={() => setShowComments(!showComments)}>
            <ChatAltIcon className='w-6 h-6' />
            <span>{entry.commentsCount || 0} comments</span>
          </div>
          <a className='whitespace-nowrap flex justify-end items-center p-2 space-x-1 cursor-pointer text-gray-800 hover:bg-gray-200' href={`/contests/${entry.contestSlug}/entry/${entry.id}`}>
            <LinkIcon className='w-6 h-6' />
            <span>Public Link</span>
          </a>
        </> }
      </div>
      { showComments && <div className='mt-2'><PublicEntryComments entryId={entry.id} /></div> }
      <Card title='Review Entry'>
        <p className='text-xs text-gray-500'>Anything you do here will only be viewable by your team.</p>
        <span className='font-semibold'>Labels</span>
        <LabelAutocomplete entryId={entry.id} contestId={entry.contestId} addLabel={addLabel} placeholder='High Quality, Performance, Shortlist' />
        <div className="min-w-0 mt-3 flex-1 flex sm:items-center  flex-wrap">
          {labels.map((label, idx) => (
            <span key={`laform${label.id}`} onClick={(id) => removeLabel(label.id)} className="flex-shrink-0 inline-block px-2 mr-1 mb-1 py-0.5 text-white bg-neonblue dark:bg-neonblue text-xs font-medium rounded-full flex cursor-pointer capitalize">{label.name} <XIcon className='w-4 h-4' /></span>
          ))}
        </div>
        <div className='flex justify-start space-x-3 items-top'>
          <div className='flex flex-col'>
            <span className='font-semibold'>Decision</span>
            <select defaultValue={entry.reviewStatus} onChange={handleReviewStatusChange}
              className="mb-3 rounded-lg" >
              <option>unreviewed</option>
              <option>chosen</option>
              <option>undecided</option>
              <option>dismissed</option>
            </select>
          </div>
          <div className='flex flex-col'>
            <span className='font-semibold'>Assigned To</span>
            <select defaultValue={entry.assignedUserId} onChange={handleChange}
              className="mb-3 rounded-lg"
            >
              { teamUsers.map((user) => (
                <option key={`userentr${user.id}`} value={user.id}>{user.name}</option>
              ))}
            </select>
          </div>
          <GradeModal entry={entry} />
        </div>
        <div className="col-span-4 sm:col-span-4">
          <div className='mt-4'>
            <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
              Private Review Notes
            </label>
            <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> This is a private note. Only you and your team can see it.</p>
            <div className="mt-1 shadow-sm w-full min-w-full">
              <MDEditor value={reviewNotes} height={100} preview='edit' onChange={setReviewNotes} hideToolbar={true} />
            </div>
          </div>
        </div>
        <DefaultButton onClick={saveReviewNotes} text='Save Review Notes' />
      </Card>
      <Modal full isOpen={isOpen} closeModal={closeModal} >
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> {entry.name} </h3>
        <div className='max-w-5xl mx-auto'>
          <div className="sm:flex sm:justify-between sm:items-center">
            <div className="flex-shrink-0">
              <img className='h-16 w-16 rounded-sm' src={entry.userImageUrl} alt={entry.name} />
            </div>
            <div className="flex-1 px-0 md:px-4 md:py-0 pb-2 w-full truncate">
              <div className='flex flex-col truncate'>
                <span className="flex space-x-2">
                  <span aria-expanded="false">
                    <div><span className="font-medium" href={`/users/${entry.userId}`}>{entry.userName}</span></div>
                  </span>
                  <time className='uppercase text-xs dark:text-gray-300 text-gray-500 mr-1 flex items-center' dateTime={entry.createdAt}>{entry.createdAt}</time>
                </span>
                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">{entry.name}</span>
                <div className='flex justify-start items-center space-x-3'>
                  <span className='text-xs text-gray-500 capitalize'>{entry.contestRoundName} - {entry.contestCategoryName} - <span className='capitalize'>{entry.kind} entry</span></span>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-center'>
            { entry.kind === 'audio' && <div className='w-full p-1'>
              <AudioPlayer mediaUrl={entry.remoteUrl} />
            </div> }

            { entry.kind === 'pdf' && <div className='overflow-hidden aspect-w-1 aspect-h-1 w-full'>
              <iframe className='w-full' src={entry.remoteUrl} />
            </div> }

            { entry.kind === 'image' && <div className='w-full'>
              <img className='w-full' src={entry.remoteUrl} alt={entry.name} />
            </div> }
            { entry.kind === 'text' && <div className='mt-2 text-sm bg-gray-200 text-gray-700 p-2 rounded-sm'><ReactMarkdown children={entry.textBody} rehypePlugins={[rehypeRaw]} /></div> }
            { entry.kind === 'video' && <div className='flex flex-col w-full justify-center'>
              <div className="aspect-w-16 aspect-h-9 w-full">
                <iframe src={`https://iframe.videodelivery.net/${entry.remoteUrl}?poster=https://videodelivery.net/${entry.remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
              </div>
            </div> }
          </div>
          <div className='mt-4' />
          <Card title='Entry Details'>
            <EntryForm initialEntry={entry} setInitialEntry={setEntry} closeModal={closeModal} />
            <div className='mt-10 flex justify-between'>
              { !deleteConfirm && <div onClick={() => setDeleteConfirm(true)} aria-label='Delete Account' className='text-red-500 cursor-pointer'>Delete Entry</div> }
              { deleteConfirm && <>
                <div className="rounded-md bg-red-50 p-4 mt-10">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">Take me to the Danger Zone</h3>
                      <div className="mt-2 text-sm text-red-700"> This <b>cannot be undone</b>. You must be absolutely sure before you go off and push this button. I'm serious, no takebacks.</div>
                      <div onClick={deleteEntry} aria-label='Confirm Delete Entry' className='text-red-500 cursor-pointer'>Yes, really delete this entry</div>
                    </div>
                  </div>
                </div>
              </> }
            </div>
          </Card>
        </div>
      </Modal>

    </li>
  </>
}

export default EntryRow

EntryRow.propTypes = {
  initialEntry: PropTypes.object.isRequired
}
