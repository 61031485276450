import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import { useDebouncedEffect } from 'utils/useDebouncedEffect'
import { randomToastSuccess } from 'shared/Toast'
import useQuery from 'hooks/useQuery'
import Loading from 'shared/Loading'
import { useGlobalState } from 'shared/state'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import { Modal, useModal } from 'shared/Modal'
import {
  ChatAltIcon,
  EyeIcon,
  ArrowCircleUpIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  LinkIcon
} from '@heroicons/react/solid'
import CommentRow from 'pages/Contests/PublicEntryComment'
import CommentForm from 'pages/Contests/PublicEntryCommentForm'

const PublicComments = ({ currentUser, currentPage, contestId }) => {
  const [, setCurrentUser] = useGlobalState('currentUser')
  const [, setCurrentPage] = useGlobalState('currentPage')
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const { getRequest } = useQuery()

  useEffect(() => {
    setCurrentUser(currentUser)
    setCurrentPage(currentPage)
    setLoading(true)
    getRequest(`/api/v1/contests/${contestId}/public_comments`, {}, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hook */ }
      setComments(jsonData.comments)
    })
  },[])

  const addChildtoParent = (childComment) => {
    setComments([childComment, ...comments])
  }

  return <div className="border-t-4 border-neonpurple p-1 sm:p-10">
    <CommentForm commentableId={contestId} commentableType='Contest' addChildtoParent={addChildtoParent} />
    <div className="space-y-3 mt-3">
      {comments.map((com, idx) => (
        <CommentRow key={`com${com.id}`} initialComment={com} />
      ))}
    </div>
    { loading && <Loading /> }
  </div>
}

export default PublicComments

PublicComments.propTypes = {
}
