import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import { Modal, useModal } from 'shared/Modal'
import { formatInTimezone } from 'utils/date'
import ContestCategoryForm from 'pages/Contests/ContestCategoryForm'
import Card from 'shared/Card'
//import { randomToastSuccess } from 'shared/Toast'

//import { useGlobalState } from 'state'
import useQuery from 'hooks/useQuery'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const times = [ '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30' ]

const TheForm = ({ round, contestId, setRound, closeModal, appendRound, otherRounds }) => {
  const { getRequest } = useQuery()
  const { putpostRequest } = useQuery()
  const initStart = round?.id ? new Date(round.startsAtDate) : new Date()
  const initEnd = round?.id ? new Date(round.endsAtDate) : new Date()
  const [startsAtDate, setStartsAtDate] = useState(initStart)
  const [endsAtDate, setEndsAtDate] = useState(initEnd)
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus, reset } = useForm({
   defaultValues: {
      name: round.name,
      starts_at_date: round.startsAtDate,
      only_entries_from_round_id: round.onlyEntriesFromRoundId,
      starts_at_time: round.startsAtTime,
      starts_at_ampm: round.startsAtAmpm,
      ends_at_date: round.endsAtDate,
      ends_at_time: round.endsAtTime,
      ends_at_ampm: round.endsAtAmpm,
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)

  //const updateFilePathInfo = ({ audioUrl, audioFileName }) => {
    //setThisDemo({ ...thisDemo, ...{ audioUrl: audioUrl, audioFileName: audioFileName, filePathProcessing: true } })
  //}

  const fetchFilePathInfo = () => {
    getRequest(`/api/v1/contests/${thisDemo.id}`, {}, (err, jsonData) => {
      if (err) { return }
      setThisDemo({ ...thisDemo, ...{ audioUrl: jsonData.demo.audioUrl, audioFileName: jsonData.demo.audioFileName, filePathProcessing: false } })
    })
  }

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    register('starts_at_date', { required: true })
    register('ends_at_date', { required: true })
    register('description')
    return () => {
      unregister('description')
      unregister('ends_at_date')
      unregister('starts_at_date')
    }
  }, [register])

  useEffect(() => {
    setValue('starts_at_date', format(startsAtDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [startsAtDate])

  useEffect(() => {
    setValue('ends_at_date', format(endsAtDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [endsAtDate])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }
  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    let url = '/api/v1/contest_rounds/'
    let action = 'POST'
    const formData = {
      ...data,
      contest_id: contestId
    }

    if (round.id) {
      url = `/api/v1/contest_rounds/${round.id}`
      action = 'PATCH'
    }

    putpostRequest(url, action, { contest_round: formData }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }

      if (action === 'POST') {
        appendRound(jsonData.round)
        reset({ ...getValues(), description: ' ' }) // resetting this without this hack kills froala somehow
      } else {
        setRound({ ...jsonData.round })
      }
      closeModal()
    })
  })

  const cancel = () => { closeModal() }

  return <>
    <form className='bg-gray-100 dark:bg-gray-700 p-2'>
      <div className="grid sm:grid-cols-4 gap-6">
        <div className="col-span-4 sm:col-span-3">
          <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Round </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Round 1: Fight!" />
              { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of your round. Round names will not be shown to the public until they have started (based off the Start Time).</p>
          { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-4"> </div>

        <div className="col-span-4 sm:col-span-3">
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>Only Allow Entries From Another Round:</span>
            </label>
            <select
              {...register('only_entries_from_round_id', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option value={0}>Anyone can enter</option>
              { otherRounds.map((oround) => (
                <>
                  { oround.id !== round?.id && <option key={`oorou${oround.id}`} value={oround.id}>{oround.name}</option> }
                </>
              ))}
            </select>
          </div>
        </div>
        <div className="col-span-4"> </div>

        <div className="col-span-4 sm:col-span-1">
          <label htmlFor="display_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">
            <span>End Date</span>
          </label>
          <div className="mt-1 flex flex-col rounded-md shadow-sm">
            <div className='relative'>
              <DatePicker
                selected={endsAtDate}
                onChange={(date) => setEndsAtDate(date)}
                selectsStart
                startDate={endsAtDate}
                endDate={new Date().setMonth(endsAtDate.getMonth() + 1)}
                minDate={new Date()}
                nextMonthButtonLabel=">"
                previousMonthButtonLabel="<"
              />
              { errors.endsAtDateUtc && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div> }
            </div>
          </div>
          { errors.endsAt && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
        </div>

        <div className="col-span-4 sm:col-span-1">
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>End Time</span>
            </label>
            <select
              {...register('ends_at_time', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              { times.map((time) => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </div>
        </div>

        <div className='col-span-4 sm:col-span-1'>
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="ampm" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
              <span>AM/PM</span>
            </label>
            <select
              {...register('ends_at_ampm', { required: true })}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>
        <div className="col-span-4" />
        <div className="col-span-4 sm:col-start-4 sm:col-span-1 flex justify-end">
          <DefaultButton onClick={cancel} text='Cancel' />
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text={<div className='flex flex-col'>
              Save
            </div>} />
          </div>
        </div>
      </div>

    </form>
  </>
}

const statusPillStyle = {
  scheduled: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-200 text-gray-800 capitalize',
  current: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 capitalize',
  completed: 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize'
}

const StatusPill = ({ status }) => {
  return <span className={statusPillStyle[status]}>{status} </span>
}

const ContestRoundForm = ({ contestId, initialRound, timezone, appendRound, otherRounds, showNewRoundButton }) => {
  const { putpostRequest } = useQuery()
  const [editing, setEditing] = useState(initialRound?.id !== 'undefined')
  const [round, setRound] = useState(initialRound)
  const [deleted, setDeleted] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const [ upgradeModalOpen, setUpgradeModalOpen] = useState(false)

  const { name, status, endsAtUtc } = round

  const softDelete = () => {
    putpostRequest(`/api/v1/contest_rounds/${round.id}/change_status`, 'post', {}, (err, jsonData) => {
      if (err) { // 422 code
        return
      }
      setDeleted(true)
    })
  }

  if (deleted) { return null }

  return <>
    { round?.id && <>
      <div className='bg-white dark:bg-gray-700 py-2 px-1 rounded-sm flex justify-between items-center'>
        <div className={'flex flex-col sm:flex-row sm:items-centerflex-wrap'}>
          <div className="min-w-0 flex-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
            <div className="min-w-0 flex-auto">
              <div className='truncate'>
                <span className="dark:text-gray-300 text-gray-500 mr-5">{name}</span>

                <div className="text-xs text-gray-500 dark:text-gray-300 flex space-x-4">
                  <StatusPill status={status} />
                  <span>Round ends At: <b>{formatInTimezone(endsAtUtc, timezone, 'EEE MMMM d, Y h:mmaaa')}</b></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        { !deleteConfirm && <> 
          <div className='flex'>
            <span className="flex-shrink-0 flex items-start space-x-4">
              <button onClick={openModal} type="button" className="text-neonblue hover:text-neonblue-alt">
                Edit
              </button>
            </span>
            <span className="ml-1 sm:ml-4 flex-shrink-0 flex items-start space-x-4">
              <button onClick={() => setDeleteConfirm(true)} type="button" className="text-red-400 hover:text-neonblue-alt">
                Delete
              </button>
            </span>
          </div>
        </> }
        { deleteConfirm && <> 
          <div className='flex flex-col sm:flex-row ml-2 sm:ml-0'>
            Really delete this? This cannot be undone: 
            <span className="flex-shrink-0 flex items-start space-x-4">
              <button onClick={softDelete} type="button" className="text-red-400 hover:text-neonblue-alt">
                Yes
              </button>
              <button onClick={() => setDeleteConfirm(false)} type="button" className="text-neonblue hover:text-neonblue-alt">
                No
              </button>
            </span>
          </div>
        </> }
      </div>
    </> }
    { showNewRoundButton && !isOpen && typeof(round?.id) === 'undefined' && <DefaultButton onClick={openModal} text='New Round' /> }
    <Modal isOpen={isOpen} closeModal={closeModal} >
      <TheForm
        round={round}
        setRound={setRound}
        appendRound={appendRound}
        otherRounds={otherRounds}
        contestId={contestId}
        closeModal={closeModal}
      />
    </Modal>
  </>
}

export default ContestRoundForm

ContestRoundForm.propTypes = {
  contestId: PropTypes.number.isRequired,
  initialRound: PropTypes.object.isRequired
}
