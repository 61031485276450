import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useGlobalState } from 'shared/state'
import { randomToastSuccess, successfullySavedToast } from 'shared/Toast'
import GradeForm from 'pages/Entries/GradeForm'
import { DefaultButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'
import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'

const EntryGrades = ({entry}) => {
  const { getRequest } = useQuery()
  const [grades, setGrades] = useState([])

  useEffect(() => {
    getRequest(`/api/v1/entries/${entry.id}/grades`, {}, (err, jsonData) => {
      if (err) { return }
      setGrades(jsonData.grades)
    })
  }, [])

  return grades.map((grade) => (
    <GradeForm key={`grade${grade.id}-${entry.id}`} initialGrade={grade} entry={entry} />
  ))
}

const GradeModal = ({ entry }) => {
  const { openModal, closeModal, isOpen } = useModal()
  return <>
    <div className='flex flex-col'>
      <span className='font-semibold' onClick={() => openModal()}>Judging Grades</span>
      <DefaultButton onClick={() => openModal()} text={<span className='whitespace-nowrap'>Open Judging Criteria</span>} />
      <Modal isOpen={isOpen} closeModal={closeModal} >
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> {entry.name} </h3>
        <Card title='Grades'>
          <EntryGrades entry={entry} />
        </Card>
      </Modal>
    </div>
  </>
}

export default GradeModal
