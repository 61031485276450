import React, { useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import ContestCategoryForm from 'pages/Contests/ContestCategoryForm'
import ContestRoundFormIndex from 'pages/Contests/ContestRoundFormIndex'
import ContestCategoryFormIndex from 'pages/Contests/ContestCategoryFormIndex'
import GradesIndex from 'pages/Contests/GradesIndex'
import Card from 'shared/Card'

import { Modal, useModal } from 'shared/Modal'
import ImageUploadForm from 'shared/ImageUploadForm'
import { Toast } from 'shared/Toast'
import { successfullySavedToast } from 'shared/Toast'
import Loading from 'shared/Loading'

import { useGlobalState } from 'shared/state'
import useQuery from 'hooks/useQuery'
import FroalaWrapper from 'shared/FroalaWrapper'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const times = [ '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
  '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30',
  '11:00', '11:30', '12:00', '12:30' ]

const Form = ({contest, contestId}) => {
  const [, setToast] = useGlobalState('toast')
  const { getRequest } = useQuery()
  const [thisContest, setThisContest] = useState(contest || {})
  const { putpostRequest } = useQuery()
  const [timezones, setTimezones] = useState([])
  const [formattedVideoUrl, setFormattedVideoUrl] = useState(thisContest.videoUrl)
  const [startsAtDate, setStartsAtDate] = useState(new Date(thisContest.startsAtDate))
  const [endsAtDate, setEndsAtDate] = useState(new Date(thisContest.endsAtDate))
  const { formState, register, unregister, watch, handleSubmit, setValue, getValues, setError, setFocus } = useForm({
   defaultValues: {
      name: thisContest.name,
      description: thisContest.description,
      thank_you_message: thisContest.thankYouMessage,
      headline: thisContest.headline,
      google_analytics_measurement_id: thisContest.googleAnalyticsMeasurementId,
      status: thisContest.status,
      timezone: thisContest.timezone,
      website: thisContest.website,
      twitter: thisContest.twitter,
      discord: thisContest.discord,
      total_reward_value: thisContest.totalRewardValue,
      starts_at_date: thisContest.startsAtDate || new Date(),
      starts_at_time: thisContest.startsAtTime,
      starts_at_ampm: thisContest.startsAtAmpm,
      video_url: thisContest.videoUrl,
      ends_at_date: thisContest.endsAtDate || new Date(),
      ends_at_time: thisContest.endsAtTime,
      ends_at_ampm: thisContest.endsAtAmpm,
    }
  })

  const { errors } = formState
  const [loading, setLoading] = useState(false)
  const description = getValues().description
  const thankYouMessage = getValues().thank_you_message
  const videoUrl = getValues().video_url

  //const updateFilePathInfo = ({ audioUrl, audioFileName }) => {
    //setThisDemo({ ...thisDemo, ...{ audioUrl: audioUrl, audioFileName: audioFileName, filePathProcessing: true } })
  //}

  const fetchFilePathInfo = () => {
    getRequest(`/api/v1/contests/${thisDemo.id}`, {}, (err, jsonData) => {
      if (err) { return }
      setThisDemo({ ...thisDemo, ...{ audioUrl: jsonData.demo.audioUrl, audioFileName: jsonData.demo.audioFileName, filePathProcessing: false } })
    })
  }

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  useEffect(() => {
    getRequest('/api/v1/static/timezones', {}, (err, jsonData) => {
      if (err) { /* handled in hook */ return }

      if (jsonData.timezones) {
        setTimezones(jsonData.timezones)
        setValue('timezone', thisContest.timezone, { shouldDirty: false, shouldValidate: false })
      }
    })
  }, [])

  useEffect(() => {
    register('starts_at_date', { required: true })
    register('ends_at_date', { required: true })
    register('description')
    register('thank_you_message')
    return () => {
      unregister('description')
      unregister('thank_you_message')
      unregister('ends_at_date')
      unregister('starts_at_date')
    }
  }, [register])

  useEffect(() => {
    setValue('starts_at_date', format(startsAtDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [startsAtDate])

  useEffect(() => {
    setValue('ends_at_date', format(endsAtDate, 'yyyy-MM-dd'), { shouldDirty: true, shouldValidate: true })
  }, [endsAtDate])

  const handleUpdateFroala = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true })
  }

  const keyPress = (e) => {
    if (e.target.name === 'name' && e.keyCode === 13) { e.preventDefault() }
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      if (typeof (e.target.name) === 'undefined') {
        e.target.blur() // froala hack to make it update model so it can save properly
      }
      handleSubmit(onSubmit)()
    }
  }
  const onSubmit = debounce(300, (data) => {
    setLoading(true)

    const URL = `/api/v1/contests/${contestId}`
    action = 'PATCH'

    putpostRequest(URL, action, { contest: data }, (err, jsonData) => {
      setLoading(false)
      if (err) { // 422 code
        if (typeof err !== 'string') { // 500 code on API
          Object.entries(err).forEach(([key, value]) => {
            setError(key, value)
          })
        }
        return
      }
      setFormattedVideoUrl(jsonData.contest.videoUrl)

      //setThisDemo({ ...jsonData.demo })
      // setDemo({ ...jsonData.demo })
      setToast(successfullySavedToast())
    })
  })

  return <>
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">General Info</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          General information about your contest. This can be edited any time, even while your contest is live.
        </p>
        <div className='relative' style={{padding:'56.25% 0 0 0'}}><iframe src="https://www.loom.com/embed/39f29034fc70431480c5666519393603" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='absolute top-0 left-0 w-full h-full'  allowFullScreen title='Video Entry'></iframe></div>
      </div>
      <Card title="General Information"
        header={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
          </div>
        </div>
        } 
        footer={
        <div className='flex flex-rows justify-end items-end'>
          <div className='flex flex-col items-end w-min ml-2'>
            <PrimaryButton className='w-full' onClick={handleSubmit(onSubmit)} loading={loading} text='Save' />
          </div>
        </div>
        } >
        <form>
          <div className="grid sm:grid-cols-4 gap-6">

            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="status" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Status </label>
              <select name='status' {...register('status', { required: true })}
                className="block w-full px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
                <option value="completed">Completed</option>
              </select>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">IMPORTANT: Only your team will be able to view your page if it's draft.</p>
            </div>

            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Name of Contest </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.name ? 'errors' : ''} {...register('name', { required: true }) } placeholder="Best Use of Meme in a Shakespearean Play" />
                  { errors.name && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Name of your contest. This will be shown to the public</p>
              { errors.name && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="total_reward_value" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Total Reward Value in USD </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="number" className={errors.total_reward_value ? 'errors' : ''} {...register('total_reward_value', { required: true }) } placeholder="500" />
                  { errors.total_reward_value && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Leave this as zero if your contest is just for fun</p>
              { errors.total_reward_value && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Headline </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.headline ? 'errors' : ''} {...register('headline', { required: true }) } placeholder="Determining the bestest of the year" />
                  { errors.headline && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">A 1 or 2 sentence headline about your contest</p>
              { errors.headline && <div className='mt-2 text-sm text-red-600'>This field is required.</div> }
            </div>

            <div className="col-span-4"></div>

            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="school_name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Google Analytics Measurement ID </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.googleAnalyticsMeasurementId ? 'errors' : ''} {...register('google_analytics_measurement_id') } placeholder="G-CBB5FTL7NB" />
                </div>
              </div>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1 flex space-x-2">If you'd like to use your own Google Analytics ID to measure your contest. The id usually starts with <pre className='ml-2 bg-white'>G-</pre></p>
            </div>

            <div className="col-span-4"></div>

            <div className="col-span-4 sm:col-span-1">
              <label htmlFor="website" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Website URL </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.website ? 'errors' : ''} {...register('website') } placeholder="https://www.neoniva.com" />
                  { errors.website && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <label htmlFor="twitter" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Twitter URL </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.twitter ? 'errors' : ''} {...register('twitter') } placeholder="https://www.twitter.com/_buf" />
                  { errors.twitter && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
            </div>
            <div className="col-span-4 sm:col-span-1">
              <label htmlFor="discord" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Discord URL </label>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.discord ? 'errors' : ''} {...register('discord') } placeholder="https://www.discord.gg/DkUxzyt" />
                  { errors.discord && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>
            </div>

            <div className='col-span-4 sm:col-span-1'>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="timezone" className="block text-sm font-medium text-gray-700 dark:text-gray-200 flex justify-between">
                  <span>Contest Timezone</span>
                </label>
                <select
                  {...register('timezone', { required: true })}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-cccpurple focus:border-cccpurple sm:text-sm"
                >
                  { timezones.map((tz) => (
                    <option key={tz} value={tz}>{tz.replace(/_/g, ' ')}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-4 sm:col-span-3">
              <label htmlFor="remote_url" className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex justify-between">Video URL (Loom or YouTube) </label>
              <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">Make sure you get the <b>embed</b> link. e.g. (https://www.youtube.com/embed/DiYTaQ-Mgck)
 </p>
              <div className="mt-1 flex flex-col rounded-md shadow-sm">
                <div className='relative'>
                  <input onKeyDown={keyPress} type="text" className={errors.video_url ? 'errors' : ''} {...register('video_url') } placeholder="https://www.loom.com/embed/...." />
                  { errors.video_url && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </div> }
                </div>
              </div>

              <div className="min-w-0 flex-1 mt-1 flex sm:items-center  flex-wrap sm:flex-nowrap">
                {formattedVideoUrl && formattedVideoUrl.includes('loom.com') && <>
                  <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }} className='w-full'>
                    <iframe src={`${formattedVideoUrl}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
                      frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                    ></iframe>
                  </div>
                </>}
                {formattedVideoUrl && formattedVideoUrl.includes('youtube.com') && <>
                  <iframe width="560" height="315" src={formattedVideoUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                </>}
              </div>
            </div>


            <div className="col-span-4"> </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Description
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Any extra info you want to add. Any other links or notes. </p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={description}
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('description', val)}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-4"> </div>

            <div className="col-span-4 sm:col-span-4">
              <div className='mt-4'>
                <label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                  Thank You Message
                </label>
                <p className="text-xs text-gray-500  dark:text-gray-400 mb-1"> Message to be Emailed to the contestant when their entry is successfully entered</p>
                <div className="mt-1 shadow-sm w-full min-w-full">
                  <FroalaWrapper
                    model={thankYouMessage}
                    keyPress={keyPress}
                    updateModel={(val) => handleUpdateFroala('thank_you_message', val)}
                  />
                </div>
              </div>
            </div>


          </div>
        </form>
      </Card>
  </>
}

const ContestImage = ({ contest }) => {
  const [url, setUrl] = useState(contest.imageUrl)
  const [uploaded, setUploaded] = useState(false)
  const { openModal, closeModal, isOpen } = useModal()
  const { getRequest } = useQuery()

  const reloadPublicImageURL = () => {
    getRequest(`/api/v1/contests/${contest.id}/image`, {}, (err, jsonData) => {
      if (err) { return }
      setUrl(jsonData.imageUrl)
    })
  }

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Contest Image</h3>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
          This will be displayed on social media. This will only be displayed on your public contest page if you don't have a contest video.
        </p>
      </div>
      <div className="md:col-span-2">
        <div className="mt-1 flex justify-center p-3 hover:opacity-75 cursor-pointer border-2 border-gray-300 border-dashed rounded-sm relative">
          { !uploaded && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
              <img className='object-cover w-full' src={url} onClick={openModal} alt={contest.name} />
            </div>
            }
          { uploaded && <div className='overflow-hidden aspect-w-16 aspect-h-9 w-full'>
            <img className='object-cover w-full' src={url} onClick={openModal} alt={contest.name} />
            <div className='absolute h-20 w-20 top-8'>
              <Loading noMessage noLoadingMessage />
            </div>
          </div> }
        </div>
      </div>
    </div>
    <Modal full isOpen={isOpen} closeModal={closeModal} >
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white"> Edit Image</h3>
      <div className='flex justify-center'>
        <div className="mt-2">
          <ImageUploadForm
            objectType='Contest'
            aspect='video'
            objectId={contest.id}
            url={url}
            setUrl={setUrl}
            success={reloadPublicImageURL}
            didUpload={setUploaded}
          />
        </div>
      </div>
    </Modal>
  </>
}


const ContestEditForm = ({ contestId }) => {
  const { getRequest } = useQuery()
  const [state, setState] = useReducer(
    (state, newState) => (
      {...state, ...newState}), {
        contest: {},
        rounds: [],
        grades: [],
        cats: [],
        loading: true
      }
    )
  const { contest, rounds, cats, grades, loading } = state

  useEffect(() => {
    getRequest(`/api/v1/contests/${contestId}`, {}, (err, jsonData) => {
      if (err) { return }
      setState( {
        contest: jsonData.contest,
        rounds: jsonData.rounds,
        grades: jsonData.grades,
        cats: jsonData.cats,
        loading: false
      })
    })
  }, [])

  if (loading) return null

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <Form contest={contest} contestId={contestId} />
      <div className="col-span-3" />
    </div>
    <ContestRoundFormIndex contestId={contestId} contestRounds={rounds} contest={contest} />
    <ContestCategoryFormIndex contestId={contestId} contestCats={cats} contest={contest} />
    <GradesIndex contestId={contestId} grades={grades} contest={contest} />
    <ContestImage contest={contest} />
    <Toast />
  </>
}

export default ContestEditForm

ContestEditForm.propTypes = {
  contestId: PropTypes.number.isRequired
}
