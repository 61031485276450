import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import { format } from 'date-fns-tz'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import GradeForm from 'pages/Contests/GradeForm'
import Card from 'shared/Card'
//import { randomToastSuccess } from 'shared/Toast'

//import { useGlobalState } from 'state'
import useQuery from 'hooks/useQuery'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'

const GradesIndex = ({ contestId, grades, contest }) => {
  const [gradeArray, setGradeArray] = useState(grades)

  const appendGrade = (grade) => {
    let data = gradeArray.slice();
    data.push(grade);
    setGradeArray(data)
  }

  if (!contestId) return null
  

  return <>
    <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100">Entry Grading Criteria (optional)</h3>
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">If you prefer, you can create criteria for your judges to use to help narrow down entries. For example:</p>
        <ul>
          <li>Delivery: 10 out of 10</li>
          <li>Performance: 9 out of 10</li>
          <li>Misc: 4 out of 10</li>
        </ul>
      </div>
      <Card title="Grades" footer={
          <GradeForm
            initialGrade={{}}
            contestId={contestId}
            appendGrade={appendGrade}
            contest={contest}
          />
        } >
        <div className="md:col-span-2">
          {gradeArray.map((grade, idx) => (
            <GradeForm key={`grade${grade.id}`} initialGrade={grade} contestId={contestId} contest={contest} />
          ))}
          
        </div>
      </Card>
    </div>
  </>
}

export default GradesIndex

GradesIndex.propTypes = {
  contestId: PropTypes.number.isRequired,
  grades: PropTypes.array.isRequired
}
