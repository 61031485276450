import { Controller } from "@hotwired/stimulus"

let teamUserId = null

function toggleModalOpen(target) {
  if (target.classList.contains('hidden')) {
    target.classList.remove('hidden')
  } else {
    target.classList.add('hidden')
  }
}

export default class extends Controller {
  static targets = ['confirmDeleteModal'];

  confirmDelete(e){
    teamUserId = e.params.id
    toggleModalOpen(this.confirmDeleteModalTarget)
  }

  reset() {
    teamUserId = null
    toggleModalOpen(this.confirmDeleteModalTarget)
  }

  actuallyDelete() {
    window.location.href = `/team_users/${teamUserId}/delete`
  }

  changeRole(e) {
    teamUserId = e.params.roleId.replace('role','')
    let role = document.getElementById(e.params.roleId).value
    window.location.href = `/team_users/${teamUserId}/change_role?role=${role}`
  }
}
