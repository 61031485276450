import React from 'react'
import PropTypes from 'prop-types'

const Card = (props) => {
  const { footer, header, children, title, light, sideBorder } = props
  const bg = light ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-800'
  const border = sideBorder ? 'border-l-2' : 'border-t-2'
  return (
    <div className={`md:mt-0 md:col-span-2 shadow border-neonblue dark:border-rose-500 ${bg} ${border}`}>
      { title && <div className={`font-medium leading-6 ${bg} text-neonblue dark:text-rose-500 mx-2 sm:mx-3 mt-1`}> {title} </div> }
      { header && <div className={`px-2 pt-2 text-right sm:pl-3 sm:pt-3 ${bg}`}> {header} </div> }
      <div className={`p-2 space-y-2 sm:p-3 ${bg}`}>
        { React.Children.map(children, (child) => {
          return (child && <> { child } </>)
        })}
      </div>
      { footer && <div className={`px-2 py-1 ${bg} text-right sm:px-6`}> {footer} </div> }
    </div>
  )
}

export default Card

Card.propTypes = {
  footer: PropTypes.object,
  header: PropTypes.object,
  title: PropTypes.string,
  light: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
    PropTypes.string,
    PropTypes.object
  ])
}
