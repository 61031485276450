import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'

import Card from 'shared/Card'
import { Modal, useModal } from 'shared/Modal'
import VideoUploadForm from 'shared/VideoUploadForm'
import { randomToastSuccess } from 'shared/Toast'
import Loading from 'shared/Loading'
import { PrimaryButton, DefaultButton } from 'shared/Buttons'
import useQuery from 'hooks/useQuery'

import AudioPlayer from 'shared/AudioPlayer'

const EntryVideo = ({ contest, entry }) => {
  const entryId = entry.id
  const [editing, setEditing] = useState(!entry.remoteUrl)
  const [remoteUrl, setRemoteURL] = useState(entry.remoteUrl)
  const [status, setStatus] = useState(entry.status)
  const [errorMessage, setErrorMessage] = useState(null)
  const { getRequest } = useQuery()
  const pollRef = useRef(0)

  const updateFilePathInfo = ({ audioUrl, audioFileName }) => {
    setRemoteURL(null)
  }

  const fetchFilePathInfo = () => {
    getRequest(`/api/v1/entries/${entryId}`, { check_cloudflare: true }, (err, jsonData) => {
      if (err) { return }
      setRemoteURL(jsonData.entry.remoteUrl)
      setStatus(jsonData.entry.status)
      if (jsonData.entry.status === 'processing') { setTimeout(pollForStatus, 5000) }
    })
  }

  const pollForStatus = () => {
    pollRef.current += 1
    console.log( pollRef.current)
    if (pollRef.current > 60) {
      setErrorMessage('There was an unknown error. If this keeps happening, you can email hello@neoniva.for support')
      setEditing(true)
      return
    }

    fetchFilePathInfo()
  }

  useEffect(() => {
    if (remoteUrl) {
      setEditing(false)
    }
  }, [remoteUrl])

  return <div className='mt-6'>
    <Card title="Video Entry">
      <div className="flex justify-center flex-col">
        { errorMessage && <div className='text-red-600'>{errorMessage}</div> }
        { editing && <div className='flex flex-col w-full justify-center mx-auto'>
          <div className='p-1 mx-auto'>
            <VideoUploadForm
              kind='Entry'
              objectId={entryId}
              onProcessingStarted={updateFilePathInfo}
              onSuccess={fetchFilePathInfo}
            />
          </div>
          <div className='w-m p-1 mx-auto'>
            { remoteUrl && <DefaultButton className='mt-5' onClick={() => setEditing(false)} text="Preview Video" /> }
          </div>
        </div> }
        { !editing && <div className='flex flex-col w-full justify-center'>
          { status !== 'processing' && <div className='flex flex-col w-full justify-center'>
            <div className="aspect-w-16 aspect-h-9 w-full">
              <iframe src={`https://iframe.videodelivery.net/${remoteUrl}?poster=https://videodelivery.net/${remoteUrl}/thumbnails/thumbnail.gif?time=2s&height=200`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" className='w-full h-full'  allowFullScreen title='Video Entry'></iframe>
            </div>
          </div> }
          { status === 'processing' && <div className='flex flex-col w-full justify-center relative'>
            <div className="aspect-w-16 aspect-h-9 w-full">
              <div className="absolute inset-0">
                <img className="h-full w-full object-cover" src={`https://ccc-emails.s3.amazonaws.com/avatars/CCC_Cover_UNSURE.svg`} alt="Entry Processing" />
                <div className="absolute inset-0 bg-neonblue mix-blend-multiply"></div>
              </div>
              <div className="relative bg-black p-6 rounded-lg h-full flex flex-col items-center justify-center">
                <div className="block text-white">
                  Still Processing...
                  <Loading noMessage noLoadingMessage player />
                </div>
                <div className="block text-white p-6 text-center">
                  Sometimes we need extra time. We will check again every 5 seconds to see if it's done.
                </div>
              </div>
            </div>
          </div> }
          <div className='w-m p-1 mx-auto'>
            <DefaultButton className='mt-5' onClick={() => setEditing(true)} text="Upload a different file instead" />
          </div>
        </div> }
      </div>
    </Card>
  </div>
}


export default EntryVideo
