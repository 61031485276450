import { createGlobalState } from 'react-hooks-global-state'

// You're thinking: Buford, what the literal ef is this file?
// This global state is a bridge to get us from point A -- which is having states mounted on each component that could be global -- to point B -- which is a robust store provider via redux/react context/whateverthefuck.
//
//
// There are many layers of shared state, something truly global like currentUser, and then local shared states like pages. For example, let's say we have the member directory, you might use a lower level provider or context that would hold all the users, but you wouldn't want to store those users in this global state in this file, because it's truly global.
//
// So, is this throwaway code? Kinda. I'm open to suggestions on state management.
//
// Shown below is setState style, but there is also reducer style if we prefer, and stores: https://www.npmjs.com/package/react-hooks-global-state

const { useGlobalState } = createGlobalState({
  currentUser: {}, // global
  currentPage: null, // global
  toast: null, // global
  errorNotification: null, // global
})

export { useGlobalState }

// PS - the above code could be written like a provider if desired
/*
const DashboardContext = React.createContext([{}, () => {}])

const DashboardStoreProvider = (props) => {
  const [state, setState] = useState({
    currentUser: {}
  })
  return (
    <DashboardContext.Provider value={[state, setState]}>
      {props.children}
    </DashboardContext.Provider>
  )
}
*/
