import { useState, useEffect } from 'react'
//import { useHistory } from 'react-router-dom'
import { useGlobalState } from '../shared/state'

const useQuery = () => {
  //const history = useHistory()
  const [errorNotification, setErrorNotification] = useGlobalState('errorNotification')
  const csrfToken = (document.head.querySelector('[name~=csrf-token]') || {}).content

  const createQueryString = (data) => {
    let queryString = ''
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        queryString += `${key}=${data[key]}&`
      }
    })
    return queryString
  }


  const putpostRequest = (uri, method, data, fetchCallback) => {
    window.fetch(uri, {
      method: method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(data)
    }).then((response) => {
        if(response.status === 204) { // no content, specifically from stripe's payment method call
          fetchCallback(null, response)
          return
        }
        if(response.status >= 500) {
          setErrorNotification('There was an error. I am ashamed of myself and I will fix it as soon as I can.')
          fetchCallback('server error')
          return
        }
        response.json().then((data) => {
        if (response.status >= 200 && response.status < 400) {
            fetchCallback(null, data)
          } else if (response.status === 404) {
            //history.replace(history.location.pathname, { errorStatusCode: 404 })
          } else if (response.status === 401) {
            setErrorNotification(data.errors || 'Unauthorized')
            fetchCallback(data.errors || 'Unauthorized') // want to make sure the promise is called
          } else if (response.status === 403) {
            setErrorNotification(data.errors || 'Forbidden')
          } else {
            if (window.bugsnagClient) {
              window.bugsnagClient.notify(data.errors || 'An undefined error occured. No json')
            } else {
              console.log(data.errors)
            }
            fetchCallback(data.errors || 'An error occured. Please try again')
          }
        })
      })
      .catch((err) => {
        if (window.bugsnagClient) { window.bugsnagClient.notify(err) } else { console.log(err) }
        //history.replace(history.location.pathname, { errorStatusCode: 504 })
      })
  }


  const getRequest = (uri, params, fetchCallback) => {
    const queryString = createQueryString(params)
    window.fetch(`${uri}?${queryString}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then((response) => {
      response.json().then((data) => {
        if (response.status >= 200 && response.status < 400) {
          fetchCallback(null, data)
        } else if (response.status === 404) {
          //history.replace(history.location.pathname, { errorStatusCode: 404 })
        } else if (response.status === 401) {
          setErrorNotification(data.errors || 'Unauthorized')
          fetchCallback({}) // want to make sure the promise is called
        } else if (response.status === 422) {
          setErrorNotification(data.errors || "I can't do that right now.")
        } else if (response.status === 403) {
          setErrorNotification(data.errors || 'Forbidden')
        } else {
          if (window.bugsnagClient) { window.bugsnagClient.notify(data.errors) } else { console.log(data.errors) }
          fetchCallback(data.errors || 'An error occured. Please try again')
        }
      })
    }).catch((err) => {
      if (window.bugsnagClient) { window.bugsnagClient.notify(err) } else { console.log(err) }
      //history.replace(history.location.pathname, { errorStatusCode: 504 })
    })
  }

  return {
    getRequest,
    createQueryString,
    putpostRequest
  }
}

export default useQuery
